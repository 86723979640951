import React from 'react'

import { createStyles, IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { FastForward, FastRewind, SkipNext, SkipPrevious } from '@material-ui/icons'
import { formatDistanceStrict } from 'date-fns'

import { TimelineZoomFn } from '../Timeline'
import { diff } from '../../../shared/utils'

export const calcNewRange = (timestamp: number, diff: number, muliplicator: number) =>
  Math.round(timestamp + diff * muliplicator)

const GO_CONTROLS = [
  { icon: <FastRewind />, muliplicator: -1 },
  { icon: <SkipPrevious />, muliplicator: -0.3333333333 },
  { icon: <SkipNext />, muliplicator: 0.3333333333 },
  { icon: <FastForward />, muliplicator: 1 },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
)

export interface TimelineGoControlsProps {
  baseEndMillis: number
  startMillis: number
  endMillis: number
  onRangeChange: TimelineZoomFn
}

export const TimelineGoControls = ({
  baseEndMillis,
  startMillis,
  endMillis,
  onRangeChange,
}: TimelineGoControlsProps) => {
  const classes = useStyles()

  const diffMillis = diff(startMillis, endMillis)

  return (
    <div className={classes.root}>
      {GO_CONTROLS.map((go) => (
        <Tooltip
          key={go.muliplicator}
          title={`Go ${go.muliplicator < 0 ? 'back' : 'forward'} ${formatDistanceStrict(
            endMillis,
            calcNewRange(endMillis, diffMillis, go.muliplicator)
          )}`}
        >
          <span>
            <IconButton
              onClick={() => {
                onRangeChange(
                  calcNewRange(startMillis, diffMillis, go.muliplicator),
                  calcNewRange(endMillis, diffMillis, go.muliplicator)
                )
              }}
              disabled={calcNewRange(endMillis, diffMillis, go.muliplicator) > baseEndMillis}
              size="small"
            >
              {go.icon}
            </IconButton>
          </span>
        </Tooltip>
      ))}
    </div>
  )
}
