import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import * as React from 'react'
import { Bed, BedId } from '../model'
import { BedCard as BedCardComponent } from '../components/BedCard'
import { useIsHovered, useIsPinned } from '../../screens/overview/selectors'
import { hoverBed, OverviewScreenAction, pinBed, switchToEditInProgressMode } from '../../screens/overview/actions'

interface Props {
  bed: Bed
}

export const BedCard = ({ bed }: Props) => {
  const isHovered = useIsHovered(bed.id)
  const isPinned = useIsPinned(bed.id)
  const dispatch = useCockpitDispatch<OverviewScreenAction>()
  const onHoverBed = (bedId: BedId) => dispatch(hoverBed(bedId))
  const onPinBed = (bedId: BedId) => dispatch(pinBed(bedId))
  const onStartIsolationStatusEdit = (bedId: BedId) => dispatch(switchToEditInProgressMode(bedId))
  return (
    <BedCardComponent
      bed={bed}
      isHovered={isHovered}
      isPinned={isPinned}
      onHoverBed={onHoverBed}
      onPinBed={onPinBed}
      onStartIsolationStatusEdit={onStartIsolationStatusEdit}
    />
  )
}
