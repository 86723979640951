import React from 'react'

import { useTheme } from '@nivo/core'
import { CustomLayerProps, LineProps, Point } from '@nivo/line'
import { Crosshair } from '@nivo/tooltip'
import { least } from 'd3-array'

import { PointItem } from '../shared/PointItem'
import { SlicesLabel } from './SlicesLabel'
import { diff } from '../../../../shared/utils'
import { CursorPosition, CursorPositionNone } from '../../../../screens/timelines/model'

export const getNearestPoint = (points: ReadonlyArray<Point>, x: number, curve: LineProps['curve']): Point | null => {
  const nearestIndex = points.findIndex((point) => point.x > x)

  return points.length > 0
    ? points.length > 1 && nearestIndex !== 0
      ? nearestIndex !== -1
        ? curve && curve === 'stepAfter'
          ? points[nearestIndex - 1]
          : least([points[nearestIndex - 1], points[nearestIndex]], (point) => diff(point.x, x)) ?? null
        : points[points.length - 1]
      : points[0]
    : null
}

export interface CustomSlicesLayerProps extends CustomLayerProps {
  cursorPosition: CursorPosition
}

export const CustomSlicesLayer = ({
  points,
  pointSize,
  pointBorderWidth,
  innerHeight,
  innerWidth,
  cursorPosition,
  curve,
  axisLeft,
  axisBottom,
}: CustomSlicesLayerProps) => {
  const theme = useTheme()

  if (cursorPosition !== CursorPositionNone) {
    const nearestPoint = getNearestPoint(points, cursorPosition, curve)

    if (nearestPoint) {
      const size = (!pointSize || pointSize < 5 ? 5 : pointSize) * 2

      const yLabel = nearestPoint.data.yFormatted.toString()

      return (
        <>
          <Crosshair
            key="crosshair"
            width={innerWidth}
            height={innerHeight}
            x={nearestPoint.x}
            y={nearestPoint.y}
            type="bottom-left"
          />
          <SlicesLabel
            x={nearestPoint.x}
            y={nearestPoint.y}
            label={yLabel.length > 6 ? `${yLabel.slice(0, 5)}…` : yLabel}
            axisOffset={axisLeft?.tickPadding ?? 4}
            bgColor={theme.textColor ?? 'red'}
            fontColor={theme.background}
            fontSize={theme.fontSize}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            maxWidth={56}
            axis="y"
          />
          <PointItem
            x={nearestPoint.x}
            y={nearestPoint.y}
            color={nearestPoint.color}
            borderColor={nearestPoint.borderColor}
            borderWidth={!pointBorderWidth || pointBorderWidth < 2 ? 2 : pointBorderWidth}
            size={size}
          />
          <SlicesLabel
            x={nearestPoint.x}
            y={nearestPoint.y}
            label={nearestPoint.data.xFormatted.toString()}
            axisOffset={axisBottom?.tickPadding ?? 4}
            bgColor={theme.textColor ?? 'red'}
            fontColor={theme.background}
            fontSize={theme.fontSize}
            innerWidth={innerWidth}
            innerHeight={innerHeight}
            maxWidth={160}
            axis="x"
          />
        </>
      )
    }
  }

  return null
}
