import * as React from 'react'
import { ReactNode } from 'react'

import AppBar from '@material-ui/core/AppBar/AppBar'
import Toolbar from '@material-ui/core/Toolbar/Toolbar'
import { Theme } from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline/CssBaseline'
import Typography from '@material-ui/core/Typography/Typography'
import { ThemeSwitch } from '../../themes/containers/ThemeSwitch'
import { makeStyles } from '@material-ui/styles'
import { BirdsEyeNavigation } from '../containers/BirdsEyeNavigation'
import { BedSelector } from '../containers/BedSelector'
import { ToolbarCenterDetail } from '../containers/ToolbarCenterDetail'
import { IsolationTile } from '../../beds/containers/tiles/IsolationTile'
import { VideoBlurSwitch } from '../../video/containers/VideoBlurSwitch'

const pagePadding = 8

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
  },
  appBar: {
    height: 64, // fix vertical toolbar item alignment in safari
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: 'auto auto', // center is position 'absolute'
    paddingLeft: pagePadding,
    paddingRight: pagePadding,
  },
  toolbarLeft: {
    height: '100%',
    marginRight: 'auto', // push to the left
    display: 'flex',
    gridColumnGap: theme.spacing(1),
    alignItems: 'center',
  },
  toolbarCenter: {
    // position centered on page (rather than in flow of toolbar items)
    position: 'absolute',
    width: '100%',
    display: 'grid',
    pointerEvents: 'none',
  },
  title: {
    justifySelf: 'center',
  },
  toolbarRight: {
    height: '100%',
    marginLeft: 'auto', // push to the right
    display: 'grid',
    gridTemplateColumns: 'auto',
    alignItems: 'center',
  },
  content: {
    width: '100%',
    height: '100%',
    paddingTop: 64 + pagePadding + 'px', // account for toolbar height
    paddingLeft: pagePadding,
    paddingRight: pagePadding,
    paddingBottom: pagePadding,
  },
}))

export type ActiveView = 'overview' | 'detail'

interface Props {
  readonly activeView: ActiveView
  readonly containsSharpVideoUrl: boolean
  readonly children?: ReactNode
}

export const CockpitNavigation = ({ activeView, containsSharpVideoUrl, children }: Props) => {
  const classes = useStyles()
  const fullVersion = process.env.REACT_APP_VERSION ?? ''
  const displayVersion = fullVersion.substring(0, fullVersion.lastIndexOf('.'))
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          {activeView === 'overview' ? (
            <>
              <ToolbarLeftOverview containsSharpVideoUrl={containsSharpVideoUrl} />
              <ToolbarCenterOverview
                title={`ICU Cockpit v${displayVersion} – for use in clinical investigation only`}
              />
            </>
          ) : (
            <>
              <ToolbarLeftDetail />
              <ToolbarCenterDetail />
            </>
          )}
          <ToolbarRight />
        </Toolbar>
      </AppBar>
      <div className={classes.content}>{children}</div>
    </div>
  )
}

interface ToolbarLeftOverviewProps {
  readonly containsSharpVideoUrl: boolean
}

const ToolbarLeftOverview = ({ containsSharpVideoUrl }: ToolbarLeftOverviewProps) => {
  const classes = useStyles()
  // eslint-disable-next-line react/jsx-no-undef
  return <div className={classes.toolbarLeft}>{containsSharpVideoUrl && <VideoBlurSwitch />}</div>
}

const ToolbarLeftDetail = () => {
  const classes = useStyles()
  return (
    <div className={classes.toolbarLeft}>
      <BirdsEyeNavigation />
      <BedSelector />
      <IsolationTile />
    </div>
  )
}

export interface ToolbarCenterOverviewProps {
  title: string
}

export const ToolbarCenterOverview = ({ title }: ToolbarCenterOverviewProps) => {
  const classes = useStyles()
  return (
    <div className={classes.toolbarCenter}>
      <Typography className={classes.title} variant="h6">
        {title}
      </Typography>
    </div>
  )
}

const ToolbarRight = () => {
  const classes = useStyles()
  return (
    <div className={classes.toolbarRight}>
      <ThemeSwitch />
    </div>
  )
}
