import { UPDATE_BED_EDIT_MODE } from './actionTypes'
import { BedEditMode, BedInteraction } from './model'
import { Dispatch } from 'redux'
import { CockpitState } from '../../store/cockpit/model'
import { ThunkDispatch } from 'redux-thunk'
import { BedId, BedIdNone } from '../../beds/model'

export type OverviewScreenAction = UpdateBedEditModeAction

export interface UpdateBedEditModeAction {
  type: typeof UPDATE_BED_EDIT_MODE
  bedEditMode: BedEditMode
}

export const switchToReadOnlyMode = (): UpdateBedEditModeAction => ({
  type: UPDATE_BED_EDIT_MODE,
  bedEditMode: {
    type: 'read-only',
    bedInteraction: {
      type: 'none',
    },
  },
})

export const switchToEditInProgressMode = (bedId: BedId): UpdateBedEditModeAction => ({
  type: UPDATE_BED_EDIT_MODE,
  bedEditMode: {
    type: 'edit-in-progress',
    bedId,
  },
})

// TODO: Refactor these actions to be middlewares
const updateBedInteraction =
  (bedInteraction: BedInteraction) => (dispatch: Dispatch<UpdateBedEditModeAction>, getState: () => CockpitState) => {
    const bedEditMode = getState().ui.activeUser.settings.screen.overview.bedEditMode
    if (bedEditMode.type === 'read-only') {
      const action: UpdateBedEditModeAction = {
        type: UPDATE_BED_EDIT_MODE,
        bedEditMode: {
          type: 'read-only',
          bedInteraction,
        },
      }
      dispatch(action)
    }
  }

export const hoverBed =
  (bedId: BedId, force: boolean = false) =>
  (dispatch: ThunkDispatch<CockpitState, {}, UpdateBedEditModeAction>, getState: () => CockpitState) => {
    const bedEditMode = getState().ui.activeUser.settings.screen.overview.bedEditMode
    const isPinningActive = bedEditMode.type === 'read-only' && bedEditMode.bedInteraction.type === 'pinned'
    // ignore hovering while pinning is active (unless forced)
    if (!force && isPinningActive) {
      return
    } else if (bedId === BedIdNone) {
      dispatch(
        updateBedInteraction({
          type: 'none',
        })
      )
    } else {
      dispatch(
        updateBedInteraction({
          type: 'hovered',
          bedId,
        })
      )
    }
  }

export const pinBed =
  (bedId: BedId) =>
  (dispatch: ThunkDispatch<CockpitState, {}, UpdateBedEditModeAction>, getState: () => CockpitState) => {
    // on unpinning, it feels natural to hover the previously pinned bed
    if (bedId === BedIdNone) {
      const bedEditMode = getState().ui.activeUser.settings.screen.overview.bedEditMode
      if (bedEditMode.type === 'read-only' && bedEditMode.bedInteraction.type === 'pinned') {
        const previouslyPinnedBed = bedEditMode.bedInteraction.bedId
        dispatch(hoverBed(previouslyPinnedBed, true))
      }
    } else {
      dispatch(
        updateBedInteraction({
          type: 'pinned',
          bedId,
        })
      )
    }
  }
