import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import { BedSignalConfigDialog } from '../containers/BedSignalConfigDialog'
import { Button, Tooltip } from '@material-ui/core'
import { Bed } from '../model'

interface Props {
  readonly bed: Bed
  readonly disabled: boolean
  readonly onShowSignalConfigDialog: () => void
}

export const BedSignalConfigControl = ({ bed, disabled, onShowSignalConfigDialog }: Props) => {
  return (
    <div style={{ marginLeft: 10 }}>
      <Tooltip title="Add or remove signals to display in the chart below">
        <Button
          variant="outlined"
          endIcon={<SettingsIcon />}
          style={{ height: 40 }}
          onClick={onShowSignalConfigDialog}
          disabled={disabled}
        >
          Select signals
        </Button>
      </Tooltip>
      <BedSignalConfigDialog bed={bed} />
    </div>
  )
}
