import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useMotionConfig } from '@nivo/core'

export interface PointItemProps {
  x: number
  y: number
  size: number
  color: string
  borderColor: string
  borderWidth: number
  onClick?: (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => void
  onMouseEnter?: (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => void
  onMouseLeave?: (e: React.MouseEvent<SVGCircleElement, MouseEvent>) => void
}

export const PointItem = ({
  x,
  y,
  size,
  color,
  borderColor,
  borderWidth,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: PointItemProps) => {
  const { animate, config: springConfig } = useMotionConfig()

  const animatedProps = useSpring({
    transform: `translate(${x}, ${y}), scale(${size / 2})`,
    config: springConfig,
    immediate: !animate,
  })

  return (
    <animated.g transform={animatedProps.transform}>
      <circle
        r={1}
        fill={color}
        stroke={borderColor}
        strokeWidth={borderWidth * (1 / (size / 2))}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      ></circle>
    </animated.g>
  )
}
