import { TimelineScreenId } from '../screens/timelines/model'
import { TimelineSeries } from './components/model'

export interface TimelineStatusUninitialized {
  status: 'uninitialized'
  isLoading: boolean
  error: string | null
}

export interface TimelineStatusInitialized {
  status: 'initialized'
  isLoading: boolean
  error: string | null
}

export type TimelineInitialized = TimelineStatusInitialized & TimelineSeries

enum TimelineIdBrand {}

export type TimelineId = TimelineIdBrand & string

export const TimelineIdNone: TimelineId = '' as TimelineId

export type TimelineStateValue = TimelineStatusUninitialized | TimelineInitialized

export type TimelineState = ReadonlyMap<TimelineScreenId, ReadonlyMap<TimelineId, TimelineStateValue>>
