import React from 'react'
import { createRoot } from 'react-dom/client'
import registerServiceWorker from './registerServiceWorker'
import { Provider } from 'react-redux'
import { App } from './app/containers/App'
import createCockpitStore from './store/cockpit/store'
import { BrowserRouter as Router } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { createLiveDataStore, liveDataContext } from './store/live/store'

/* ·················································································································· */
/*  Redux Store                                                                                                       */
/* ·················································································································· */

const cockpitStore = createCockpitStore()
const liveDataStore = createLiveDataStore()

/* ·················································································································· */
/*  Main Render                                                                                                       */
/* ·················································································································· */

// TODO: Context or combineStore?

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container) // createRoot(container!) if you use TypeScript

root.render(
  <Provider store={cockpitStore}>
    <Provider store={liveDataStore} context={liveDataContext}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <App />
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  </Provider>
)

registerServiceWorker()
