import React, { useCallback, useState } from 'react'
import { Grid, Paper, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Timeline } from '../../../timeline/containers/Timeline'
import { TimelineId } from '../../../timeline/model'
import { Domain, InteractionMode } from 'react-svg-timeline'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Add } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import { TimelineRangeControl } from '../../../timeline/components/timeline-range-control/TimelineRangeControl'
import { Timeline as TimelineType, TimelineScreenId, TimeRangeNone } from '../../timelines/model'
import { TimelinesSelectionDialog } from '../../timelines/components/TimelinesSelectionDialog'
import { TimelineRangeDateTimePicker } from '../../../timeline/components/TimelineRangeDateTimePicker'
import { BedId, CurrentProbabilityInfo } from '../../../beds/model'
import { ProbabilitiesSummaryTile, PROBABILITY_SUMMARY_TILE_SIZE } from './ProbabilitiesSummaryTile'
import { cn } from '../../../shared/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    gridContainer: {
      height: '100%',
    },
    gridEnclosingItem: {
      flexBasis: 'auto',
    },
    gridTimelineItem: { flexBasis: '100%', maxHeight: '150px', minHeight: '76px' },
    fixedHeight: {
      flexBasis: '100%',
      maxHeight: PROBABILITY_SUMMARY_TILE_SIZE,
      minHeight: PROBABILITY_SUMMARY_TILE_SIZE,
    },
    paper: {
      padding: theme.spacing(0.5, 2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    timelinesSection: {
      marginTop: theme.spacing(2),
    },
    timelinePaper: {
      height: '100%',
    },
    empty: {
      padding: theme.spacing(2),
    },
    emptyText: {
      marginBottom: theme.spacing(3),
    },
    title: {
      marginTop: theme.spacing(2),
    },
  })
)

export interface GenericDetailScreenProps {
  readonly bedId: BedId
  readonly detailScreenId: TimelineScreenId
  readonly availableTimelines: ReadonlyArray<TimelineType>
  readonly selectedTimelines: ReadonlyArray<TimelineId>
  readonly predictionLabel: string
  readonly predictionTimeline: TimelineId
  readonly currentProbabilityInfo?: CurrentProbabilityInfo
  readonly timeRange: Domain
  readonly onRangeChange: (range: Domain) => void
  readonly onResetRange: () => void
  readonly onCursorPositionChange: (cursorPosition: number) => void
  readonly onResetCursorPosition: () => void
  readonly animate: boolean
  readonly onAnimateChange: (animate: boolean) => void
  readonly onSelectedTimelinesChange: (selectedSignals: ReadonlyArray<TimelineId>) => void
}

export const GenericDetailScreen = ({
  bedId,
  detailScreenId,
  availableTimelines,
  selectedTimelines,
  predictionLabel,
  predictionTimeline,
  currentProbabilityInfo,
  timeRange,
  onRangeChange,
  onResetRange,
  animate,
  onAnimateChange,
  onSelectedTimelinesChange,
  onCursorPositionChange,
  onResetCursorPosition,
}: GenericDetailScreenProps) => {
  const classes = useStyles()

  const [now] = useState(Date.now())
  const [liveRange, setLiveRange] = useState<number>(1000 * 60 * 60 * 24) // Default 1d

  const minInterval =
    selectedTimelines.length > 0
      ? Math.min(
          ...availableTimelines
            .filter((timeline) => selectedTimelines.find((selectedTimelineId) => selectedTimelineId === timeline.id))
            .map((timeline) => timeline.options.interval.min)
        )
      : null

  // Update [now] every [minInterval] (at most every 60000ms) of selected Signals
  // to keep data up to date
  // TODO: Ensure that we don't update now when user is interacting (in a performant way, just maintaining a state here is too slow)
  // useInterval(() => {
  //   setNow(Date.now())
  // }, Math.max(minInterval ?? 0, 60000))

  const [showTimelineSelectionDialog, setShowTimelineSelectionDialog] = useState<boolean>(false)

  const onZoomRangeChange = useCallback(
    (fromMillis: number, toMillis: number) => onRangeChange([fromMillis, toMillis]),
    [onRangeChange]
  )

  const onLiveRangeChange = useCallback(
    (fromMillis: number, toMillis: number) => {
      if (toMillis === now) {
        onResetRange()
        setLiveRange(toMillis - fromMillis)
      } else {
        onZoomRangeChange(fromMillis, toMillis)
      }
    },
    [onResetRange, setLiveRange, now, onZoomRangeChange]
  )

  const onInteractionModeChange = useCallback(
    (interactionMode: InteractionMode) => {
      if (animate !== (interactionMode.type !== 'panning')) {
        onAnimateChange(interactionMode.type !== 'panning')
      }

      if (interactionMode.type !== 'hover') {
        onResetCursorPosition()
      }
    },
    [onAnimateChange, onResetCursorPosition, animate]
  )

  const zoomRange = timeRange !== TimeRangeNone ? timeRange : [now - liveRange, now]

  const predictionTimelineInfo = availableTimelines.find(
    (availableTimeline) => predictionTimeline === availableTimeline.id
  )

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        justifyContent="flex-start"
        spacing={1}
        className={classes.gridContainer}
      >
        <Grid item xs={12} className={classes.gridEnclosingItem}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={0}>
            <Grid item xs="auto">
              <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
                <Grid item xs="auto">
                  <TimelineRangeControl
                    baseEndMillis={now}
                    startMillis={zoomRange[0]}
                    endMillis={zoomRange[1]}
                    minRange={minInterval ?? 0}
                    onRangeChange={onLiveRangeChange}
                  />
                </Grid>
                <Grid item xs="auto">
                  <TimelineRangeDateTimePicker
                    fromMillis={zoomRange[0]}
                    toMillis={zoomRange[1]}
                    onTimeRangeChange={onZoomRangeChange}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.gridContainer}>
          <Grid container justifyContent="flex-start" spacing={1} className={classes.gridContainer}>
            <Grid item xs>
              <Grid
                container
                direction="column"
                wrap="nowrap"
                justifyContent="flex-start"
                spacing={1}
                className={classes.gridContainer}
              >
                <Typography variant="h5" className={classes.title}>
                  {predictionLabel}
                </Typography>
                {predictionTimeline && predictionTimelineInfo ? (
                  <Grid
                    key={predictionTimelineInfo.id}
                    item
                    xs={12}
                    className={`${classes.gridTimelineItem} ${classes.fixedHeight}`}
                  >
                    <Paper className={`${classes.paper} ${classes.timelinePaper} ${classes.fixedHeight}`}>
                      <AutoSizer key={`${predictionTimelineInfo.id}_${selectedTimelines.length}`}>
                        {({ width, height }) => (
                          <Timeline
                            bedId={bedId}
                            timelineScreenId={detailScreenId}
                            timelineId={predictionTimelineInfo.id}
                            fromMillis={zoomRange[0]}
                            toMillis={zoomRange[1]}
                            curve={predictionTimelineInfo.options.curve}
                            color={predictionTimelineInfo.options.color}
                            onRangeChange={onZoomRangeChange}
                            onCursorMove={onCursorPositionChange}
                            width={width}
                            height={height}
                            animate={animate}
                            onInteractionModeChange={onInteractionModeChange}
                            marker={
                              currentProbabilityInfo &&
                              currentProbabilityInfo.type === 'brain' &&
                              currentProbabilityInfo.static !== undefined
                                ? { value: currentProbabilityInfo.static, label: 'Static' }
                                : undefined
                            }
                          />
                        )}
                      </AutoSizer>
                    </Paper>
                  </Grid>
                ) : (
                  <Grid item xs={12} className={classes.gridEnclosingItem}>
                    <Paper className={cn(classes.paper, classes.fixedHeight)}>
                      <div className={classes.empty}>
                        <Typography variant="body1">
                          The Timeline with Label <strong>{predictionLabel}</strong> is not available. Please make sure
                          that this timeline is part of the available timelines for the currently selected bed.
                        </Typography>
                      </div>
                    </Paper>
                  </Grid>
                )}
                <Grid container className={classes.timelinesSection}>
                  <Grid item xs={10}>
                    <Typography variant="h5" className={classes.title}>
                      Additional Timelines
                    </Typography>
                  </Grid>
                  <Grid container alignItems={'flex-end'} justifyContent={'flex-end'}>
                    <Grid item xs={2} style={{ marginRight: 3 }}>
                      <ShowTimelineSelectionDialogButton
                        isTimelineListEmpty={selectedTimelines.length === 0}
                        onClick={() => setShowTimelineSelectionDialog(true)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                {selectedTimelines
                  .map((selectedTimelineId) =>
                    availableTimelines.find((availableTimeline) => selectedTimelineId === availableTimeline.id)
                  )
                  .map(
                    (selectedTimeline) =>
                      selectedTimeline && (
                        <Grid key={selectedTimeline.id} item xs={12} className={classes.gridTimelineItem}>
                          <Paper className={`${classes.paper} ${classes.timelinePaper}`}>
                            <AutoSizer key={`${selectedTimeline.id}_${selectedTimelines.length}`}>
                              {({ width, height }) => (
                                <Timeline
                                  bedId={bedId}
                                  timelineScreenId={detailScreenId}
                                  timelineId={selectedTimeline.id}
                                  fromMillis={zoomRange[0]}
                                  toMillis={zoomRange[1]}
                                  curve={selectedTimeline.options.curve}
                                  color={selectedTimeline.options.color}
                                  onRangeChange={onZoomRangeChange}
                                  onCursorMove={onCursorPositionChange}
                                  width={width}
                                  height={height}
                                  animate={animate}
                                  onInteractionModeChange={onInteractionModeChange}
                                />
                              )}
                            </AutoSizer>
                          </Paper>
                        </Grid>
                      )
                  )}
                <Grid item xs={12} className={classes.gridEnclosingItem}>
                  <Paper className={classes.paper}>
                    <div className={selectedTimelines.length === 0 ? classes.empty : ''}>
                      {selectedTimelines.length === 0 && (
                        <Typography variant="body1" className={classes.emptyText}>
                          No timelines selected for this bed.
                        </Typography>
                      )}
                      <ShowTimelineSelectionDialogButton
                        isTimelineListEmpty={selectedTimelines.length === 0}
                        onClick={() => setShowTimelineSelectionDialog(true)}
                      />
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
            <ProbabilitiesSummaryTile currentProbabilityInfo={currentProbabilityInfo} />
          </Grid>
        </Grid>
      </Grid>
      <TimelinesSelectionDialog
        key={`${bedId}-${detailScreenId}-dialog`}
        title="Select Timelines"
        isOpen={showTimelineSelectionDialog}
        availableTimelines={availableTimelines}
        selectedTimelines={selectedTimelines}
        onCancel={() => {
          setShowTimelineSelectionDialog(false)
        }}
        onTimelineSelectionChange={(selectedTimelineIds) => {
          onSelectedTimelinesChange(selectedTimelineIds)
          setShowTimelineSelectionDialog(false)
        }}
      />
    </div>
  )
}

interface ShowTimelineSelectionDialogButtonProps {
  readonly isTimelineListEmpty: boolean
  readonly onClick: () => void
}

const ShowTimelineSelectionDialogButton = ({
  isTimelineListEmpty,
  onClick,
}: ShowTimelineSelectionDialogButtonProps) => (
  <Button variant={'text'} color={'inherit'} size="large" endIcon={<Add />} onClick={onClick}>
    {isTimelineListEmpty ? 'Add Timelines' : 'Select Timelines'}
  </Button>
)
