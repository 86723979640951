import { Theme } from '@material-ui/core'
import { BedId } from '../beds/model'
import { ScreensState } from '../screens/model'
import { SignalId, YScale } from '../signals/model'
import { VideoBlurMode } from '../video/model'

enum UserIdBrand {}

export type UserId = UserIdBrand & string

export type ActiveUser = Readonly<{
  id: UserId
  settings: Settings
}>

export type Settings = Readonly<{
  screen: ScreensState
  theme: Theme
  videoBlurMode: VideoBlurMode
  bedSettings: ReadonlyMap<BedId, BedSettings>
}>

export interface BedSettings {
  selectedSignals: 'uninitialized' | ReadonlyArray<SignalId>
  showVideoLayer: boolean
  showSignalsLayer: boolean
  contrastLayerOpacity: number
  dividerPosition: number
  xAxisOffsetCorrection: XAxisOffsetCorrection
  signalConfigDialogState: SignalConfigDialogState
  yAxisConfigDialogState: YAxisConfigDialogState
  signalSettings: ReadonlyMap<SignalId, SignalSettings>
}

export interface SignalSettings {
  yScale?: YScale
}

export type SignalConfigDialogState = Readonly<{ type: 'hidden' }> | Readonly<{ type: 'visible' }>
export type YAxisConfigDialogState = Readonly<{ type: 'hidden' }> | Readonly<{ type: 'visible'; signalId: SignalId }>

enum DelayBrand {
  Uninitialized,
}

export type Delay = DelayBrand & number

export interface XAxisOffsetCorrection {
  enabled: boolean
  currentDelay: Delay
}

export const delayUninitialized: Delay = DelayBrand.Uninitialized
