import {
  ActiveUser,
  BedSettings,
  delayUninitialized,
  Settings,
  SignalConfigDialogState,
  SignalSettings,
  UserId,
  XAxisOffsetCorrection,
  YAxisConfigDialogState,
} from './model'
import { mockUserId } from './mock'
import { BedId } from '../beds/model'
import { defaultScreens } from '../screens/defaults'
import { SignalId } from '../signals/model'
import { defaultTheme } from '../themes/defaults'
import { defaultVideoBlurMode } from '../video/defaults'

export const defaultDialogState: SignalConfigDialogState & YAxisConfigDialogState = {
  type: 'hidden',
}

export const defaultSignalSettings: SignalSettings = {}

export const defaultXAxisOffsetCorrection: XAxisOffsetCorrection = {
  enabled: true,
  currentDelay: delayUninitialized,
}

export const defaultBedSettings: BedSettings = {
  selectedSignals: 'uninitialized',
  showVideoLayer: true,
  showSignalsLayer: true,
  contrastLayerOpacity: 0.5,
  dividerPosition: 0.8,
  xAxisOffsetCorrection: defaultXAxisOffsetCorrection,
  yAxisConfigDialogState: defaultDialogState,
  signalConfigDialogState: defaultDialogState,
  signalSettings: new Map<SignalId, SignalSettings>(),
}

export const defaultBedSettingsMap = new Map<BedId, BedSettings>()

export const defaultSettings: Settings = {
  screen: defaultScreens,
  theme: defaultTheme,
  videoBlurMode: defaultVideoBlurMode,
  bedSettings: defaultBedSettingsMap,
}

export const defaultActiveUserId: UserId = mockUserId

export const defaultActiveUser: ActiveUser = {
  id: defaultActiveUserId,
  settings: defaultSettings,
}
