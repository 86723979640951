import React from 'react'
import { OptionsSelectionDialog } from '../../../shared/components/options/OptionsSelectionDialog'
import { OptionInfo } from '../../../shared/components/options/model'
import { Timeline } from '../model'
import { TimelineId } from '../../../timeline/model'

interface Props {
  readonly title: string
  readonly isOpen: boolean
  readonly availableTimelines: ReadonlyArray<Timeline>
  readonly selectedTimelines: ReadonlyArray<TimelineId>
  readonly onCancel: () => void
  readonly onTimelineSelectionChange: (timelineIds: ReadonlyArray<TimelineId>) => void
}

export const TimelinesSelectionDialog = ({
  title,
  isOpen,
  availableTimelines,
  selectedTimelines,
  onCancel,
  onTimelineSelectionChange,
}: Props) => {
  const availableOptions: ReadonlyArray<OptionInfo> = availableTimelines.map((timeline) => ({
    id: timeline.id,
    label: timeline.label,
  }))

  return (
    <OptionsSelectionDialog
      title={title}
      isOpen={isOpen}
      availableOptions={availableOptions}
      selectedOptions={selectedTimelines}
      onOptionsSelectionChange={(optionsIds) => {
        onTimelineSelectionChange(optionsIds as ReadonlyArray<TimelineId>)
      }}
      onCancel={onCancel}
    />
  )
}
