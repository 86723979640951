import { CustomLayerProps } from '@nivo/line'
import { Domain } from 'react-svg-timeline'
import { TimelineOnCursorMoveFn } from '../../Timeline'
import { InteractionLayer, InteractionModeChangeFn, ZoomFn } from './InteractionLayer'

export const useInteractionLayer = (
  onZoom: ZoomFn,
  onCursorMove: TimelineOnCursorMoveFn,
  onInteractionModeChange: InteractionModeChangeFn,
  domain: Domain,
  maxDomain?: Domain
) => {
  const InteractionLayerComponent = (props: CustomLayerProps) => (
    <InteractionLayer
      {...props}
      onZoom={onZoom}
      onCursorMove={onCursorMove}
      onInteractionModeChange={onInteractionModeChange}
      domain={domain}
      maxDomain={maxDomain}
    />
  )

  return InteractionLayerComponent
}
