import { timeFormatDefaultLocale, timeFormat } from 'd3-time-format'
import { timeYear, timeMonth, timeWeek, timeDay, timeHour, timeMinute, timeSecond } from 'd3-time'
import { scaleTime } from 'd3-scale'
import { TimelineSerie } from './Timeline'
import { diff } from '../../shared/utils'

export const getAxisFormat = (
  fromMillis: number,
  toMillis: number
): { format?: (date: Date) => string; tickValues?: Date[] } => {
  timeFormatDefaultLocale({
    dateTime: '%A, der %e. %B %Y, %X',
    date: '%d.%m.%Y',
    time: '%H:%M:%S',
    periods: ['AM', 'PM'],
    days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    months: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
    shortMonths: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
  })

  // Own implementation of d3-scale.time.tickFormat for better localization
  // https://github.com/d3/d3-scale/issues/70
  // https://github.com/d3/d3-scale/blob/main/src/time.js
  // <>
  const formatMillisecond = timeFormat('.%L'),
    formatSecond = timeFormat(':%S'),
    formatMinute = timeFormat('%H:%M'),
    formatHour = timeFormat('%H:00'),
    formatDay = timeFormat('%a %d'),
    formatWeek = timeFormat('%b %d'),
    formatMonth = timeFormat('%B'),
    formatYear = timeFormat('%Y')

  function tickFormat(date: Date) {
    return (
      timeSecond(date) < date
        ? formatMillisecond
        : timeMinute(date) < date
        ? formatSecond
        : timeHour(date) < date
        ? formatMinute
        : timeDay(date) < date
        ? formatHour
        : timeMonth(date) < date
        ? timeWeek(date) < date
          ? formatDay
          : formatWeek
        : timeYear(date) < date
        ? formatMonth
        : formatYear
    )(date)
  }
  // </>

  const scale = scaleTime().domain([fromMillis, toMillis])

  return {
    format: tickFormat,
    tickValues: scale.ticks(),
  }
}

/**
 *  Calculates the data density in number of data points per pixel.
 */
export const calculateDataDensity = (
  series: TimelineSerie[],
  fromMillis: number,
  toMillis: number,
  innerWidth: number
): number => {
  let pointsPerPixel = 0

  const millisPerPixel = diff(fromMillis, toMillis) / Math.abs(innerWidth)

  series.forEach((serie) => {
    let seriePointsPerPixel =
      millisPerPixel / (serie.data.length >= 2 ? diff(serie.data[0].timestamp, serie.data[1].timestamp) : 1)
    pointsPerPixel = seriePointsPerPixel > pointsPerPixel ? seriePointsPerPixel : pointsPerPixel
  })

  return pointsPerPixel
}
