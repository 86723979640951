import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: ({ width, height }: { width: number; height: number }) => ({
    width,
    height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  }),
}))

export interface TimelineEmptyProps {
  width: number
  height: number
}

export const TimelineEmpty = ({ width, height }: TimelineEmptyProps) => {
  const classes = useStyles({ width, height })

  return (
    <div className={classes.container}>
      <Typography>No data…</Typography>
    </div>
  )
}
