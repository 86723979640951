import React, { useMemo } from 'react'
import { useAnimatedPath } from '@nivo/core'
import { ComputedDatum, CustomLayerProps, Datum } from '@nivo/line'
import { area, curveLinear } from 'd3-shape'
import { animated } from '@react-spring/web'
import { useCallback } from 'react'
import { TimelineDatum } from '../../model'
import { clamp } from '../../../../shared/utils'

export type ComputedTimelineDatum = ComputedDatum & {
  data: Datum & TimelineDatum
}

export type TimelineAreaProps = Pick<CustomLayerProps, 'yScale' | 'innerHeight' | 'pointBorderColor' | 'animate'> & {
  points: ComputedTimelineDatum[]
  color?: string
}

export const TimelineArea = ({ yScale, points, innerHeight, pointBorderColor, color, animate }: TimelineAreaProps) => {
  const areaGenerator = useCallback(
    () =>
      area<ComputedTimelineDatum>()
        .defined(
          (d) => d.data.min !== undefined && d.data.max !== undefined && d.data.min !== null && d.data.max !== null
        )
        .x((d: ComputedTimelineDatum) => d.position.x)
        .y0((d: ComputedTimelineDatum) => clamp(yScale(d.data.min!) as unknown as number, 0, innerHeight))
        .curve(curveLinear)
        .y1((d: ComputedTimelineDatum) => clamp(yScale(d.data.max!) as unknown as number, 0, innerHeight)),
    [innerHeight, yScale]
  )

  const path = useMemo(() => areaGenerator()(points), [areaGenerator, points])

  const animatedPath = useAnimatedPath(path ?? '')

  return (
    <>
      {animate ? (
        <animated.path
          d={animatedPath as any}
          fill={color}
          fillOpacity={0.2}
          stroke={pointBorderColor}
          strokeWidth={2}
        />
      ) : (
        <path d={animatedPath as any} fill={color} fillOpacity={0.2} stroke={pointBorderColor} strokeWidth={2} />
      )}
    </>
  )
}
