import React from 'react'

import { createStyles, IconButton, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { ZoomIn, ZoomOut, YoutubeSearchedFor as ZoomReset } from '@material-ui/icons'
import { Domain } from 'react-svg-timeline'

import { TimelineZoomFn } from '../Timeline'
import { useZoom } from '../layers/interaction/useZoom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
)

export interface TimelineZoomButtonsProps {
  startMillis: number
  endMillis: number
  onRangeChange: TimelineZoomFn
}

export const TimelineZoomButtons = ({ startMillis, endMillis, onRangeChange }: TimelineZoomButtonsProps) => {
  const classes = useStyles()

  const [onZoomIn, onZoomOut, onZoomReset, isZoomInPossible, isZoomOutPossible] = useZoom(
    (domain: Domain) => {
      onRangeChange(domain[0], domain[1])
    },
    [startMillis, endMillis]
  )

  return (
    <div className={classes.root}>
      <Tooltip title="Zoom in">
        <span>
          <IconButton
            onClick={() => {
              onZoomIn()
            }}
            disabled={!isZoomInPossible}
            size="small"
          >
            <ZoomIn />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Zoom out">
        <span>
          <IconButton
            onClick={() => {
              onZoomOut()
            }}
            disabled={!isZoomOutPossible}
            size="small"
          >
            <ZoomOut />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Reset zoom">
        <IconButton
          onClick={() => {
            onZoomReset()
          }}
          size="small"
        >
          <ZoomReset />
        </IconButton>
      </Tooltip>
    </div>
  )
}
