import { BedId, Beds, UninitializedBed } from './model'

export const uszBeds: ReadonlyArray<UninitializedBed> = [
  {
    id: 12 as BedId,
    type: 'uninitialized-bed',
    direction: 'left',
    layoutGridColumn: 1,
    layoutGridRow: 1
  },
  {
    id: 11 as BedId,
    type: 'uninitialized-bed',
    direction: 'right',
    layoutGridColumn: 2,
    layoutGridRow: 1
  },
  {
    id: 10 as BedId,
    type: 'uninitialized-bed',
    direction: 'left',
    layoutGridColumn: 3,
    layoutGridRow: 1
  },
  {
    id: 9 as BedId,
    type: 'uninitialized-bed',
    direction: 'right',
    layoutGridColumn: 4,
    layoutGridRow: 1
  },
  { id: 8 as BedId, type: 'uninitialized-bed', direction: 'left', layoutGridColumn: 5, layoutGridRow: 1 },
  { id: 7 as BedId, type: 'uninitialized-bed', direction: 'right', layoutGridColumn: 6, layoutGridRow: 1 },
  { id: 13 as BedId, type: 'uninitialized-bed', direction: 'left', layoutGridColumn: 1, layoutGridRow: 2 },
  { id: 6 as BedId, type: 'uninitialized-bed', direction: 'right', layoutGridColumn: 6, layoutGridRow: 2 },
  { id: 2 as BedId, type: 'uninitialized-bed', direction: 'left', layoutGridColumn: 1, layoutGridRow: 4 },
  { id: 3 as BedId, type: 'uninitialized-bed', direction: 'right', layoutGridColumn: 2, layoutGridRow: 4 },
  { id: 4 as BedId, type: 'uninitialized-bed', direction: 'left', layoutGridColumn: 3, layoutGridRow: 4 },
  { id: 5 as BedId, type: 'uninitialized-bed', direction: 'right', layoutGridColumn: 4, layoutGridRow: 4 }
]

export const defaultBeds: Beds = {
  type: 'uninitialized-beds',
  allBeds: uszBeds
}
