import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  Theme,
  useTheme,
} from '@material-ui/core'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Bed, BedId, BedIsolation } from '../model'
import { makeStyles } from '@material-ui/core/styles'
import { getBedIsolationDisplay } from './shared'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'grid',
  },
  form: {
    display: 'grid',
    gridRowGap: '20px',
  },
  radio: {
    color: theme.palette.text.primary,
    '&$checked': {
      color: theme.palette.text.primary,
    },
  },
  checked: {
    color: theme.palette.text.primary,
  },
}))

interface Props {
  readonly isOpen: boolean
  readonly bed: Bed
  readonly onCancel: () => void
  readonly onBedIsolationChange: (bedId: BedId, bedIsolation: BedIsolation) => void
}

export const BedIsolationEditDialog = ({ isOpen, bed, onCancel, onBedIsolationChange }: Props) => {
  const classes = useStyles()
  const theme = useTheme()
  const [selectedBedIsolation, setSelectedBedIsolation] = useState<BedIsolation>(bed.isolationState)

  useEffect(() => setSelectedBedIsolation(bed.isolationState), [bed.isolationState])

  const onRadioChange = (bedIsolation: BedIsolation) => () => setSelectedBedIsolation(bedIsolation)
  const onOkClick = () => onBedIsolationChange(bed.id, selectedBedIsolation)

  // prevent any clicks from bubbling up to bed cards
  const onClick = (e: React.MouseEvent) => e.stopPropagation()

  return (
    <Dialog open={isOpen} aria-labelledby="dialog-title" onClose={onCancel} onClick={onClick}>
      <DialogTitle id="dialog-title">Edit {bed.label}</DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.form}>
          {[
            BedIsolation.Vaccinated,
            BedIsolation.TestedResultPending,
            BedIsolation.TestedSARSCoV2Negative,
            BedIsolation.TestedSARSCoV2NegativeIsolated,
            BedIsolation.TestedSARSCoV2Positive,
          ].map((item) => {
            const display = getBedIsolationDisplay(item, theme)
            const labelText =
              display.isolationStatus.trim().length === 0
                ? display.testStatus
                : `${display.testStatus}, ${display.isolationStatus}`

            const labelColor =
              display.borderColor === 'transparent' ? theme.palette.text.secondary : display.borderColor
            const label = <Typography style={{ color: labelColor }}>{labelText}</Typography>

            const control = (
              <Radio
                color="primary"
                classes={{
                  root: classes.radio,
                  checked: classes.checked,
                }}
              />
            )
            return (
              <FormControlLabel
                key={item}
                onChange={onRadioChange(item)}
                checked={item === selectedBedIsolation}
                control={control}
                label={label}
              />
            )
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onOkClick} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}
