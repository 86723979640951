import { grey } from '@material-ui/core/colors'
import { Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'

export const INFO_TILE_ICON_SIZE = 18
export const INFO_TILE_LABEL_FONT_SIZE = 7

export const buttonBackgroundColorStyle = (theme: Theme): CSSProperties => ({
  backgroundColor: theme.palette.type === 'dark' ? grey[700] : grey[200],
  '&:hover': {
    backgroundColor: theme.palette.type === 'dark' ? grey[600] : grey[300],
  },
})
