import {
  UPDATE_CONTRAST_LAYER_OPACITY,
  UPDATE_DIVIDER_POSITION,
  UPDATE_SELECTED_SIGNALS,
  UPDATE_SIGNAL_CONFIG_DIALOG_STATE,
  UPDATE_SIGNAL_SETTINGS_YSCALE,
  UPDATE_SIGNALS_LAYER_VISIBILITY,
  UPDATE_VIDEO_LAYER_VISIBILITY,
  UPDATE_YAXIS_CONFIG_DIALOG_STATE,
  SET_XAXIS_OFFSET_CORRECTION_ENABLED,
  SET_XAXIS_OFFSET_CORRECTION_DELAY,
} from './actionTypes'
import { SignalConfigDialogState, YAxisConfigDialogState } from './model'
import { BedId } from '../beds/model'
import { ScreensAction } from '../screens/actions'
import { SignalId, YScale } from '../signals/model'
import { ThemeAction } from '../themes/actions'
import { UpdateVideoBlurModeAction } from '../video/actions'

export type UserAction =
  | ThemeAction
  | ScreensAction
  | UpdateVideoBlurModeAction
  | UpdateSignalConfigDialogStateAction
  | UpdateYAxisConfigDialogStateAction
  | UpdateSelectedSignalsAction
  | UpdateVideoLayerVisibilityAction
  | UpdateSignalsLayerVisibilityAction
  | UpdateContrastLayerOpacityAction
  | UpdateDividerPositionAction
  | UpdateSignalSettingsYScaleAction
  | SetXAxisOffsetCorrectionEnabledAction
  | SetXAxisOffsetCorrectionDelayAction

export interface UpdateSignalConfigDialogStateAction {
  readonly type: typeof UPDATE_SIGNAL_CONFIG_DIALOG_STATE
  readonly bedId: BedId
  readonly signalConfigDialogState: SignalConfigDialogState
}

export const showSignalConfigDialog = (bedId: BedId): UpdateSignalConfigDialogStateAction => ({
  type: UPDATE_SIGNAL_CONFIG_DIALOG_STATE,
  bedId,
  signalConfigDialogState: {
    type: 'visible',
  },
})

export const hideSignalConfigDialog = (bedId: BedId): UpdateSignalConfigDialogStateAction => ({
  type: UPDATE_SIGNAL_CONFIG_DIALOG_STATE,
  bedId,
  signalConfigDialogState: {
    type: 'hidden',
  },
})

export interface UpdateYAxisConfigDialogStateAction {
  readonly type: typeof UPDATE_YAXIS_CONFIG_DIALOG_STATE
  readonly bedId: BedId
  readonly yAxisConfigDialogState: YAxisConfigDialogState
}

export const showYAxisConfigDialog = (bedId: BedId, signalId: SignalId): UpdateYAxisConfigDialogStateAction => ({
  type: UPDATE_YAXIS_CONFIG_DIALOG_STATE,
  bedId,
  yAxisConfigDialogState: {
    type: 'visible',
    signalId,
  },
})

export const hideYAxisConfigDialog = (bedId: BedId): UpdateYAxisConfigDialogStateAction => ({
  type: UPDATE_YAXIS_CONFIG_DIALOG_STATE,
  bedId,
  yAxisConfigDialogState: {
    type: 'hidden',
  },
})

export interface UpdateSelectedSignalsAction {
  readonly type: typeof UPDATE_SELECTED_SIGNALS
  readonly bedId: BedId
  readonly signalIds: ReadonlyArray<SignalId>
}

export const updateSelectedSignals = (
  bedId: BedId,
  signalIds: ReadonlyArray<SignalId>
): UpdateSelectedSignalsAction => ({
  type: UPDATE_SELECTED_SIGNALS,
  bedId,
  signalIds,
})

export interface UpdateVideoLayerVisibilityAction {
  readonly type: typeof UPDATE_VIDEO_LAYER_VISIBILITY
  readonly bedId: BedId
  readonly showVideoLayer: boolean
}

export const updateVideoLayerVisibility = (
  bedId: BedId,
  showVideoLayer: boolean
): UpdateVideoLayerVisibilityAction => ({
  type: UPDATE_VIDEO_LAYER_VISIBILITY,
  bedId,
  showVideoLayer,
})

export interface UpdateSignalsLayerVisibilityAction {
  readonly type: typeof UPDATE_SIGNALS_LAYER_VISIBILITY
  readonly bedId: BedId
  readonly showSignalsLayer: boolean
}

export const updateSignalsLayerVisibility = (
  bedId: BedId,
  showSignalsLayer: boolean
): UpdateSignalsLayerVisibilityAction => ({
  type: UPDATE_SIGNALS_LAYER_VISIBILITY,
  bedId,
  showSignalsLayer,
})

export interface UpdateContrastLayerOpacityAction {
  readonly type: typeof UPDATE_CONTRAST_LAYER_OPACITY
  readonly bedId: BedId
  readonly opacity: number
}

export const updateContrastLayerOpacity = (bedId: BedId, opacity: number): UpdateContrastLayerOpacityAction => ({
  type: UPDATE_CONTRAST_LAYER_OPACITY,
  bedId,
  opacity,
})

export interface UpdateDividerPositionAction {
  readonly type: typeof UPDATE_DIVIDER_POSITION
  readonly bedId: BedId
  readonly dividerPosition: number
}

export const updateDividerPosition = (bedId: BedId, dividerPosition: number): UpdateDividerPositionAction => ({
  type: UPDATE_DIVIDER_POSITION,
  bedId,
  dividerPosition,
})

export interface UpdateSignalSettingsYScaleAction {
  readonly type: typeof UPDATE_SIGNAL_SETTINGS_YSCALE
  readonly bedId: BedId
  readonly signalId: SignalId
  readonly yScale: YScale
}

export const updateSignalSettingsYScale = (
  bedId: BedId,
  signalId: SignalId,
  yScale: YScale
): UpdateSignalSettingsYScaleAction => ({
  type: UPDATE_SIGNAL_SETTINGS_YSCALE,
  bedId,
  signalId,
  yScale,
})

export interface SetXAxisOffsetCorrectionEnabledAction {
  readonly type: typeof SET_XAXIS_OFFSET_CORRECTION_ENABLED
  readonly bedId: BedId
  readonly enabled: boolean
}

export const setXAxisOffsetCorrectionEnabled = (
  bedId: BedId,
  enabled: boolean
): SetXAxisOffsetCorrectionEnabledAction => ({
  type: SET_XAXIS_OFFSET_CORRECTION_ENABLED,
  bedId,
  enabled,
})

export interface SetXAxisOffsetCorrectionDelayAction {
  readonly type: typeof SET_XAXIS_OFFSET_CORRECTION_DELAY
  readonly bedId: BedId
  readonly currentDelay: number
}

export const setXAxisOffsetCorrectionDelay = (
  bedId: BedId,
  currentDelay: number
): SetXAxisOffsetCorrectionDelayAction => ({
  type: SET_XAXIS_OFFSET_CORRECTION_DELAY,
  bedId,
  currentDelay,
})
