import { DciAction } from './actions'
import {
  ERROR_HEATMAP,
  ERROR_TIMELINE,
  ERROR_WATERFALL,
  FETCH_HEATMAP,
  FETCH_TIMELINE,
  FETCH_WATERFALL,
  INIT_BED,
  RECEIVE_HEATMAP,
  RECEIVE_TIMELINE,
  RECEIVE_WATERFALL,
} from './actionTypes'
import { defaultDciState, defaultDciValue } from './defaults'
import { DciState, DciStateValue } from './model'
import { BedId } from '../beds/model'

const getDciValue = (dciState: Readonly<DciState>, bedId: BedId): DciStateValue => {
  if (dciState.has(bedId)) {
    return dciState.get(bedId)!
  } else {
    throw new Error('Bed was not initialized for DCI Values!')
  }
}

// TODO: Can we add Heatmap & Waterfall without duplicating??? Otherwise just duplicate…

export const dciRootReducer = (state: DciState = defaultDciState(), action: DciAction): DciState => {
  switch (action.type) {
    case INIT_BED:
      return new Map(state).set(action.payload.bedId, defaultDciValue)
    case FETCH_TIMELINE:
      const currentDciValue1 = getDciValue(state, action.payload.bedId)

      return new Map(state).set(action.payload.bedId, {
        ...currentDciValue1,
        timeline: {
          ...currentDciValue1.timeline,
          isLoading: true,
          error: null,
        },
      })
    // Duplicate
    case FETCH_WATERFALL:
      const currentDciValue4 = getDciValue(state, action.payload.bedId)

      return new Map(state).set(action.payload.bedId, {
        ...currentDciValue4,
        waterfall: {
          ...currentDciValue4.waterfall,
          isLoading: true,
          error: null,
        },
      })
    // Duplicate
    case FETCH_HEATMAP:
      const currentDciValue7 = getDciValue(state, action.payload.bedId)

      return new Map(state).set(action.payload.bedId, {
        ...currentDciValue7,
        heatmap: {
          ...currentDciValue7.heatmap,
          isLoading: true,
          error: null,
        },
      })
    case RECEIVE_TIMELINE:
      const currentDciValue2 = getDciValue(state, action.payload.bedId)

      return new Map(state).set(action.payload.bedId, {
        ...currentDciValue2,
        timeline: {
          ...currentDciValue2.timeline,
          status: 'initialized',
          isLoading: false,
          error: null,
          ...action.payload.timeline,
        },
      })
    // Duplicate
    case RECEIVE_WATERFALL:
      const currentDciValue5 = getDciValue(state, action.payload.bedId)

      return new Map(state).set(action.payload.bedId, {
        ...currentDciValue5,
        waterfall: {
          ...currentDciValue5.waterfall,
          status: 'initialized',
          isLoading: false,
          error: null,
          ...action.payload.waterfall,
        },
      })
    // Duplicate
    case RECEIVE_HEATMAP:
      const currentDciValue8 = getDciValue(state, action.payload.bedId)

      return new Map(state).set(action.payload.bedId, {
        ...currentDciValue8,
        heatmap: {
          ...currentDciValue8.heatmap,
          status: 'initialized',
          isLoading: false,
          error: null,
          ...action.payload.heatmap,
        },
      })
    case ERROR_TIMELINE:
      const currentDciValue3 = getDciValue(state, action.payload.bedId)

      return new Map(state).set(action.payload.bedId, {
        ...currentDciValue3,
        timeline: {
          ...currentDciValue3.timeline,
          isLoading: false,
          error: action.payload.error,
        },
      })
    // Duplicate
    case ERROR_WATERFALL:
      const currentDciValue6 = getDciValue(state, action.payload.bedId)

      return new Map(state).set(action.payload.bedId, {
        ...currentDciValue6,
        waterfall: {
          ...currentDciValue6.waterfall,
          isLoading: false,
          error: action.payload.error,
        },
      })
    // Duplicate
    case ERROR_HEATMAP:
      const currentDciValue9 = getDciValue(state, action.payload.bedId)

      return new Map(state).set(action.payload.bedId, {
        ...currentDciValue9,
        heatmap: {
          ...currentDciValue9.heatmap,
          isLoading: false,
          error: action.payload.error,
        },
      })
    default:
      return state
  }
}
