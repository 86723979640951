import { OverviewScreenState } from './model'

export const defaultOverviewScreen: OverviewScreenState = {
  bedEditMode: {
    type: 'read-only',
    bedInteraction: {
      type: 'none',
    },
  },
}
