import React from 'react'
import { LungTile as LungTileComponent } from '../../components/tiles/LungTile'
import { Bed } from '../../model'

interface Props {
  readonly bed: Bed
}

export const LungTile = ({ bed }: Props) => {
  return <LungTileComponent bedId={bed.id} {...bed.infoTiles?.lung} />
}
