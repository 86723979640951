import { SignalConfig, SignalId } from '../signals/model'

enum BedIdBrand {}

export type BedId = BedIdBrand & number

export const BedIdNone: BedId = -1 as BedId

export enum BedIsolation {
  Vacant = 0,
  Vaccinated,
  TestedResultPending,
  TestedSARSCoV2Negative,
  TestedSARSCoV2NegativeIsolated,
  TestedSARSCoV2Positive,
}

export const BedNone: Bed = {
  type: 'initialized-bed',
  id: BedIdNone,
  label: '',
  direction: 'left',
  isolationState: BedIsolation.Vacant,
  layoutGridColumn: 'invisible',
  layoutGridRow: 'invisible',
  camUrl: '',
  number: -1,
  signals: {
    availableSignals: [],
    selectedSignals: [],
  },
}

export type BedDirection = 'left' | 'right'

export interface BedConfig {
  readonly id: BedId
  readonly direction: BedDirection
  // see OverviewScreen grid layout
  readonly layoutGridColumn: 'invisible' | 1 | 2 | 3 | 4 | 5 | 6
  readonly layoutGridRow: 'invisible' | 1 | 2 | 3 | 4
}

export type UninitializedBed = BedConfig &
  Readonly<{
    type: 'uninitialized-bed'
  }>

export interface BedInfo {
  readonly id: BedId
  readonly label: string
}

export type InfoTileType = 'brain' | 'virus' | 'lung'

export interface CurrentProbabilityInfo {
  readonly type: InfoTileType
  readonly probability: number
  readonly static?: number
  readonly dynamic?: number
  readonly showAlarm?: boolean
  readonly tooltip?: string
}

export type Bed = BedConfig &
  BedInfo &
  Readonly<{
    type: 'initialized-bed'
    isolationState: BedIsolation
    isolationTimestamp?: number
    camUrl: string
    camUrlSharp?: string
    updateError?: string
    number: number
    signals: Readonly<{
      availableSignals: ReadonlyArray<SignalConfig>
      selectedSignals: ReadonlyArray<SignalId>
    }>
    showAlarm?: boolean
    infoTiles?: Readonly<{
      brain?: CurrentProbabilityInfo & Readonly<{ type: 'brain' }>
      virus?: CurrentProbabilityInfo & Readonly<{ type: 'virus' }>
      lung?: CurrentProbabilityInfo & Readonly<{ type: 'lung' }>
    }>
  }>

export type UninitializedBeds = Readonly<{
  type: 'uninitialized-beds'
  allBeds: ReadonlyArray<UninitializedBed>
  initializationError?: string
}>

export type InitializedBeds = Readonly<{
  type: 'initialized-beds'
  allBeds: ReadonlyArray<Bed>
}>

export type Beds = InitializedBeds | UninitializedBeds
