import React, { useState, useEffect, useCallback } from 'react'
import { KeyboardDateTimePicker, KeyboardDateTimePickerProps } from '@material-ui/pickers'
import { createStyles, makeStyles, Theme } from '@material-ui/core'

export interface TimelineRangeDateTimePickerProps {
  fromMillis: number
  toMillis: number
  onTimeRangeChange: (fromMillis: number, toMillis: number) => void
  disabled?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'start',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
  })
)

export const TimelineRangeDateTimePicker = ({
  fromMillis,
  toMillis,
  onTimeRangeChange,
  disabled = false,
}: TimelineRangeDateTimePickerProps) => {
  const classes = useStyles()

  const [fromDate, setFromDate] = useState<Date | null>(null)
  const [toDate, setToDate] = useState<Date | null>(null)

  useEffect(() => {
    setFromDate(new Date(fromMillis))
  }, [fromMillis, setFromDate])

  useEffect(() => {
    setToDate(new Date(toMillis))
  }, [toMillis, setToDate])

  const handleBlur = useCallback(() => {
    if (fromDate && toDate) {
      const fromDateMillis = fromDate.valueOf()
      const toDateMillis = toDate.valueOf()

      if (fromDateMillis < toDateMillis && (fromDateMillis !== fromMillis || toDateMillis !== toMillis)) {
        onTimeRangeChange(fromDateMillis, toDateMillis)
      }
    }
  }, [fromDate, toDate, fromMillis, toMillis, onTimeRangeChange])

  const commonPickerProps: Partial<KeyboardDateTimePickerProps> = {
    variant: 'inline',
    size: 'small',
    ampm: false,
    onError: console.log,
    disableFuture: true,
    format: 'yyyy-MM-dd HH:mm:ss.SSS',
    views: ['date', 'hours', 'minutes'],
    onBlur: handleBlur,
    onClose: handleBlur,
  }

  return (
    <div className={classes.root}>
      <KeyboardDateTimePicker
        label="From"
        value={fromDate}
        onChange={(date) => {
          setFromDate(date as Date)
        }}
        maxDate={toDate}
        maxDateMessage="From-Date must be before To-Date"
        {...commonPickerProps}
        disabled={disabled}
      ></KeyboardDateTimePicker>
      <KeyboardDateTimePicker
        label="To"
        value={toDate}
        onChange={(date) => {
          setToDate(date as Date)
        }}
        minDate={fromDate}
        minDateMessage="To-Date must be after from-Date"
        {...commonPickerProps}
        disabled={disabled}
      ></KeyboardDateTimePicker>
    </div>
  )
}
