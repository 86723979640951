import { InfoTile, InfoTileVariant } from './InfoTile'
import { BedId, BedIsolation } from '../../model'
import { makeStyles } from '@material-ui/styles'
import { Theme, Typography, useTheme } from '@material-ui/core'
import { BedIsolationDisplay, getBedIsolationDisplay } from '../shared'
import HourglassIcon from '@material-ui/icons/HourglassFull'
import { grey } from '@material-ui/core/colors'
import { format, formatDistanceToNow } from 'date-fns'
import { INFO_TILE_LABEL_FONT_SIZE } from './shared'

type StyleProps = Readonly<{ bedIsolationDisplay: BedIsolationDisplay; variant: InfoTileVariant }>

const useStyles = makeStyles((theme: Theme) => ({
  contents: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr auto',
    placeItems: 'center',
    placeContent: 'center',
  },
  box: ({ bedIsolationDisplay, variant }: StyleProps) => ({
    display: 'grid',
    placeItems: 'center',
    width: variant === 'bed-card' ? 30 : 25,
    height: variant === 'bed-card' ? 30 : 25,
    backgroundColor: bedIsolationDisplay.borderColor,
    borderRadius: 2,
  }),
  tooltip: {
    textAlign: 'center',
  },
  label: ({ variant }: StyleProps) => ({
    height: '100%',
    fontSize: variant === 'bed-card' ? INFO_TILE_LABEL_FONT_SIZE : '0.5em',
    marginBottom: -3,
  }),
  icon: {
    fontSize: 'medium',
    color: theme.palette.type === 'light' ? 'white' : grey[800],
  },
}))

export interface Props {
  readonly bedId: BedId
  readonly bedIsolation: BedIsolation
  readonly bedIsolationTimestamp?: number
  readonly variant: InfoTileVariant
  readonly onClick: () => void
}

export const IsolationTile = ({ bedId, bedIsolation, bedIsolationTimestamp = 0, onClick, variant }: Props) => {
  const theme = useTheme()
  const bedIsolationDisplay = getBedIsolationDisplay(bedIsolation, theme)
  const classes = useStyles({ bedIsolationDisplay, variant })
  const tooltip = (
    <div className={classes.tooltip}>
      <Typography>{bedIsolationDisplay.testStatus}</Typography>
      <Typography>{bedIsolationDisplay.isolationStatus}</Typography>
      <LastUpdatedInfo timestamp={bedIsolationTimestamp} />
    </div>
  )
  return (
    <InfoTile bedId={bedId} tooltip={tooltip} variant={variant} onClick={onClick}>
      <div className={classes.contents}>
        <div className={classes.box}>
          {bedIsolationDisplay.bedIsolation === BedIsolation.TestedResultPending && (
            <HourglassIcon className={classes.icon} />
          )}
        </div>
        <Typography className={classes.label}>{'Isolation'}</Typography>
      </div>
    </InfoTile>
  )
}

interface LastUpdatedInfoProps {
  timestamp: number
}

const LastUpdatedInfo = ({ timestamp }: LastUpdatedInfoProps) => {
  return timestamp > 0 ? (
    <Typography>
      <br />
      {`Last updated ${formatDistanceToNow(timestamp)} ago`}
      <br />
      <em>{format(timestamp, 'dd.MM.yyyy HH:mm')}</em>
    </Typography>
  ) : (
    <div />
  )
}
