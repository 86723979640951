import { ADD_SOCKET, ADD_VALUE, CONNECT_LIVE_DATA, DISPOSE_LIVE_DATA, FREE_MEMORY, REMOVE_SOCKET } from './actionTypes'
import { SignalConfig, TimestampedValue } from '../../signals/model'

export type LiveDataAction =
  | ConnectLiveDataAction
  | DisposeLiveDataAction
  | LiveDataSocketsAction
  | LiveDataValuesAction

export type LiveDataSocketsAction = AddSocketAction | RemoveSocketAction

export type LiveDataValuesAction = AddValueAction | FreeMemoryAction

export interface ConnectLiveDataAction {
  readonly type: typeof CONNECT_LIVE_DATA
  readonly signalConfigs: ReadonlyArray<SignalConfig>
}

export const connectLiveData = (signalConfigs: ReadonlyArray<SignalConfig>): ConnectLiveDataAction => ({
  type: CONNECT_LIVE_DATA,
  signalConfigs,
})

export interface DisposeLiveDataAction {
  readonly type: typeof DISPOSE_LIVE_DATA
}

export const disposeLiveData = (): DisposeLiveDataAction => ({
  type: DISPOSE_LIVE_DATA,
})

export interface AddSocketAction {
  readonly type: typeof ADD_SOCKET
  readonly socket: WebSocket
}

export const addSocket = (socket: WebSocket): AddSocketAction => ({
  type: ADD_SOCKET,
  socket,
})

export interface RemoveSocketAction {
  readonly type: typeof REMOVE_SOCKET
  readonly streamUrl: string
}

export const removeSocket = (streamUrl: string): RemoveSocketAction => ({
  type: REMOVE_SOCKET,
  streamUrl,
})

export interface AddValueAction {
  readonly type: typeof ADD_VALUE
  readonly streamUrl: string
  readonly value: TimestampedValue
}

export const addValue = (streamUrl: string, value: TimestampedValue): AddValueAction => ({
  type: ADD_VALUE,
  streamUrl,
  value,
})

export interface FreeMemoryAction {
  readonly now: number
  readonly type: typeof FREE_MEMORY
}

export const freeMemory = (now: number = Date.now()): FreeMemoryAction => ({ now, type: FREE_MEMORY })
