import { Domain } from 'react-svg-timeline'
import { STD_RGBA } from '../../shared/utils'
import { YScale } from '../../signals/model'
import { TimelineId, TimelineStatusInitialized, TimelineStatusUninitialized } from '../../timeline/model'

enum CursorPositionBrand {
  None = 'NONE',
}

export type CursorPosition = number & CursorPositionBrand

export const CursorPositionNone: CursorPosition = CursorPositionBrand.None as CursorPosition

export interface TimelinesScreenStateValue {
  timeRange: Domain
  cursorPosition: CursorPosition
  timelines: TimelinesSelectionValue
  timelineConfigs: TimelineConfigs
  animate: boolean
}

export type TimelinesSelectionValue = TimelineStatusUninitialized | TimelinesSelectionInitialized

export type TimelinesSelectionInitialized = TimelineStatusInitialized & TimelinesSelection

export interface TimelinesSelection {
  availableTimelines: ReadonlyArray<Timeline>
  selectedTimelines: ReadonlyArray<TimelineId>
}

export interface Timeline {
  id: TimelineId
  label: string
  options: TimelineOptions
}

export interface TimelineOptions {
  interval: TimelineOptionsInterval
  curve: TimelineOptionsCurve
  color: STD_RGBA
}

export interface TimelineOptionsInterval {
  min: number
}

export type TimelineOptionsCurve = 'linear' | 'step'

export const TimeRangeNone: Domain = [NaN, NaN]

export interface TimelineConfig {
  customYScale?: YScale
}

export type TimelineConfigs = Map<TimelineId, TimelineConfig>

export type TimelineScreenId = string | number

export type TimelinesScreenState = ReadonlyMap<TimelineScreenId, TimelinesScreenStateValue>
