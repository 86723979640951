import { ActiveUser, BedSettings, Settings, SignalSettings, UserId } from './model'
import { defaultActiveUserId, defaultBedSettingsMap } from './defaults'
import { UserAction } from './actions'
import {
  UPDATE_CONTRAST_LAYER_OPACITY,
  UPDATE_DIVIDER_POSITION,
  UPDATE_SELECTED_SIGNALS,
  UPDATE_SIGNAL_CONFIG_DIALOG_STATE,
  UPDATE_SIGNAL_SETTINGS_YSCALE,
  UPDATE_SIGNALS_LAYER_VISIBILITY,
  UPDATE_VIDEO_LAYER_VISIBILITY,
  UPDATE_YAXIS_CONFIG_DIALOG_STATE,
  SET_XAXIS_OFFSET_CORRECTION_ENABLED,
  SET_XAXIS_OFFSET_CORRECTION_DELAY,
} from './actionTypes'
import { getBedSettings, getSignalSettings } from './selectors'
import { combineReducers } from 'redux'
import { BedId } from '../beds/model'
import { screensRootReducer } from '../screens/reducer'
import { SignalId } from '../signals/model'
import { themeReducer } from '../themes/reducer'
import { videoReducer } from '../video/reducers'

const userIdReducer = (state: UserId = defaultActiveUserId): UserId => state

const activeUserBedSettingsReducer = (
  state: ReadonlyMap<BedId, BedSettings> = defaultBedSettingsMap,
  action: UserAction
): ReadonlyMap<BedId, BedSettings> => {
  switch (action.type) {
    case UPDATE_SIGNAL_CONFIG_DIALOG_STATE:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return {
          ...bedSettings,
          signalConfigDialogState: action.signalConfigDialogState,
        }
      })
    case UPDATE_YAXIS_CONFIG_DIALOG_STATE:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return {
          ...bedSettings,
          yAxisConfigDialogState: action.yAxisConfigDialogState,
        }
      })
    case UPDATE_SELECTED_SIGNALS:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return {
          ...bedSettings,
          selectedSignals: action.signalIds,
        }
      })
    case UPDATE_VIDEO_LAYER_VISIBILITY:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return {
          ...bedSettings,
          showVideoLayer: action.showVideoLayer,
        }
      })
    case UPDATE_SIGNALS_LAYER_VISIBILITY:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return {
          ...bedSettings,
          showSignalsLayer: action.showSignalsLayer,
        }
      })
    case UPDATE_CONTRAST_LAYER_OPACITY:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return {
          ...bedSettings,
          contrastLayerOpacity: action.opacity,
        }
      })
    case UPDATE_DIVIDER_POSITION:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return {
          ...bedSettings,
          dividerPosition: action.dividerPosition,
        }
      })
    case UPDATE_SIGNAL_SETTINGS_YSCALE:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return signalSettingsReducer(bedSettings, action.signalId, (signalSettings) => {
          return {
            ...signalSettings,
            yScale: action.yScale,
          }
        })
      })
    case SET_XAXIS_OFFSET_CORRECTION_ENABLED:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return {
          ...bedSettings,
          xAxisOffsetCorrection: {
            ...bedSettings.xAxisOffsetCorrection,
            enabled: action.enabled,
          },
        }
      })
    case SET_XAXIS_OFFSET_CORRECTION_DELAY:
      return bedSettingsReducer(state, action.bedId, (bedSettings) => {
        return {
          ...bedSettings,
          xAxisOffsetCorrection: {
            ...bedSettings.xAxisOffsetCorrection,
            currentDelay: action.currentDelay,
          },
        }
      })
    default:
      return state
  }
}

function bedSettingsReducer(
  state: ReadonlyMap<BedId, BedSettings>,
  bedId: BedId,
  update: (prevSettings: BedSettings) => BedSettings
): ReadonlyMap<BedId, BedSettings> {
  const prevSettings = getBedSettings(state, bedId)
  const newSettings = update(prevSettings)
  const bedSettings = new Map<BedId, BedSettings>(state)
  return bedSettings.set(bedId, newSettings)
}

function signalSettingsReducer(
  bedSettings: BedSettings,
  signalId: SignalId,
  update: (prevSettings: SignalSettings) => SignalSettings
): BedSettings {
  const prevSettings = getSignalSettings(bedSettings, signalId)
  const newSettings = update(prevSettings)
  const signalSettings = new Map(bedSettings.signalSettings)
  signalSettings.set(signalId, newSettings)
  return {
    ...bedSettings,
    signalSettings,
  }
}

export const activeUserReducer = combineReducers<ActiveUser, UserAction>({
  id: userIdReducer,
  settings: combineReducers<Settings>({
    bedSettings: activeUserBedSettingsReducer,
    videoBlurMode: videoReducer,
    theme: themeReducer,
    screen: screensRootReducer,
  }),
})
