import { TimelineId } from '../../timeline/model'
import { TimelinesScreenAction } from './actions'
import {
  ERROR_TIMELINES,
  FETCH_TIMELINES,
  RECEIVE_TIMELINES,
  RESET_CURSOR_POSITION,
  RESET_TIME_RANGE,
  SET_CURSOR_POSITION,
  SET_SELECTED_TIMELINES,
  SET_TIMELINES_ANIMATE,
  SET_TIME_RANGE,
  SET_TIMELINE_CONFIG_YSCALE,
  RESET_TIMELINE_CONFIG_YSCALE,
} from './actionTypes'
import { defaultTimelinesScreenState, defaultTimelinesScreenStateValue } from './defaults'
import {
  TimelinesScreenState,
  TimelinesScreenStateValue,
  TimeRangeNone,
  CursorPositionNone,
  TimelineConfig,
  TimelineScreenId,
} from './model'

export const maxRange = 1000 * 60 * 60 * 24 * 365 * 1000 // 1000 years
export const minRange = 1 // 1 ms

const getTimelinesValue = (
  timelinesScreenState: Readonly<TimelinesScreenState>,
  timelineScreenId: TimelineScreenId
): TimelinesScreenStateValue =>
  timelinesScreenState.has(timelineScreenId)
    ? timelinesScreenState.get(timelineScreenId)!
    : defaultTimelinesScreenStateValue

const getTimelineConfigsValue = (
  timelinesScreenStateValue: Readonly<TimelinesScreenStateValue>,
  timelineId: TimelineId
): TimelineConfig =>
  timelinesScreenStateValue.timelineConfigs.has(timelineId)
    ? timelinesScreenStateValue.timelineConfigs.get(timelineId)!
    : ({} as TimelineConfig)

export const timelinesScreenReducer = (
  state: TimelinesScreenState = defaultTimelinesScreenState(),
  action: TimelinesScreenAction
): TimelinesScreenState => {
  switch (action.type) {
    case SET_TIME_RANGE: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        timeRange: [
          Math.min(
            Math.max(Math.floor(action.payload.timeRange[0]), Math.floor(action.payload.timeRange[1] - maxRange)),
            Math.floor(action.payload.timeRange[1] - minRange)
          ),
          Math.min(Date.now(), Math.round(action.payload.timeRange[1])),
        ],
      })
    }
    case RESET_TIME_RANGE: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        timeRange: TimeRangeNone,
      })
    }
    case FETCH_TIMELINES: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        timelines: {
          ...previousValue.timelines,
          isLoading: true,
          error: null,
        },
      })
    }
    case RECEIVE_TIMELINES: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        timelines: {
          ...previousValue.timelines,
          status: 'initialized',
          isLoading: false,
          error: null,
          availableTimelines: action.payload.availableTimelines,
          selectedTimelines:
            previousValue.timelines.status === 'initialized' ? previousValue.timelines.selectedTimelines : [],
        },
      })
    }
    case ERROR_TIMELINES: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        timelines: {
          ...previousValue.timelines,
          isLoading: false,
          error: action.payload.error,
        },
      })
    }
    case SET_SELECTED_TIMELINES: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      if (previousValue.timelines.status !== 'initialized') {
        throw new Error('Attempted to set selected timelines for an uninitialzed bed')
      }

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        timelines: {
          ...previousValue.timelines,
          selectedTimelines: action.payload.selectedTimelines.filter(
            (selectedTimelineId) =>
              previousValue.timelines.status === 'initialized' &&
              previousValue.timelines.availableTimelines.findIndex(
                (availableTimeline) => availableTimeline.id === selectedTimelineId
              ) >= 0
          ),
        },
      })
    }
    case SET_TIMELINES_ANIMATE: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        animate: action.payload.animate,
      })
    }
    case SET_CURSOR_POSITION: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        cursorPosition: action.payload.cursorPosition,
      })
    }
    case RESET_CURSOR_POSITION: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        cursorPosition: CursorPositionNone,
      })
    }
    case SET_TIMELINE_CONFIG_YSCALE: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      const previousTimelineConfig = getTimelineConfigsValue(previousValue, action.payload.timelineId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        timelineConfigs: new Map(previousValue.timelineConfigs).set(action.payload.timelineId, {
          ...previousTimelineConfig,
          customYScale: action.payload.customYScale,
        }),
      })
    }
    case RESET_TIMELINE_CONFIG_YSCALE: {
      const previousValue = getTimelinesValue(state, action.payload.timelineScreenId)

      const previousTimelineConfig = getTimelineConfigsValue(previousValue, action.payload.timelineId)

      return new Map(state).set(action.payload.timelineScreenId, {
        ...previousValue,
        timelineConfigs: new Map(previousValue.timelineConfigs).set(action.payload.timelineId, {
          ...previousTimelineConfig,
          customYScale: undefined,
        }),
      })
    }
    default:
      return state
  }
}
