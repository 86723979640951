import React from 'react'
import { YAxis as YAxisComponent } from '../components/YAxis'
import { SignalConfig, SignalId, YScale } from '../model'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import { useActiveBed } from '../../screens/selectors'
import {
  hideYAxisConfigDialog,
  showYAxisConfigDialog,
  updateSignalSettingsYScale,
  UpdateSignalSettingsYScaleAction,
  UpdateYAxisConfigDialogStateAction,
} from '../../users/actions'
import { useBedSettings } from '../../users/selectors'
import { SignalSettings } from '../../users/model'

interface Props {
  readonly width: number
  readonly height: number
  readonly signalConfigs: ReadonlyArray<SignalConfig>
  readonly signalSettings: ReadonlyMap<SignalId, SignalSettings>
}

export const YAxis = (props: Props) => {
  const bedId = useActiveBed().id
  const bedSettings = useBedSettings(bedId)
  const configDialogState = bedSettings.yAxisConfigDialogState

  const dispatch = useCockpitDispatch<UpdateYAxisConfigDialogStateAction | UpdateSignalSettingsYScaleAction>()

  const onShowConfigDialog = (signalId: SignalId) => dispatch(showYAxisConfigDialog(bedId, signalId))
  const onHideConfigDialog = () => dispatch(hideYAxisConfigDialog(bedId))
  const onUpdateYScale = (signalId: SignalId, yScale: YScale) =>
    dispatch(updateSignalSettingsYScale(bedId, signalId, yScale))

  return (
    <YAxisComponent
      {...props}
      configDialogState={configDialogState}
      onShowConfigDialog={onShowConfigDialog}
      onHideConfigDialog={onHideConfigDialog}
      onUpdateYScale={onUpdateYScale}
    />
  )
}
