import { useState } from 'react'
import {
  currentZoomScale,
  Domain,
  nextBiggerZoomScale,
  nextSmallerZoomScale,
  ZoomLevels,
  ZoomScale,
  zoomScaleWidth,
} from 'react-svg-timeline'
import { ZoomFn } from './InteractionLayer'

export const zoomScales: ReadonlyArray<ZoomLevels> = [
  ZoomLevels.ONE_MONTH,
  ZoomLevels.ONE_WEEK,
  ZoomLevels.ONE_DAY,
  ZoomLevels.SIX_HOURS,
  ZoomLevels.ONE_HOUR,
  ZoomLevels.TEN_MINS,
  ZoomLevels.ONE_MIN,
  ZoomLevels.TEN_SECS,
  ZoomLevels.ONE_SEC,
  ZoomLevels.HUNDRED_MS,
  ZoomLevels.TEN_MS,
]

export const useZoom = (
  onZoom: ZoomFn,
  domain: Domain,
  maxDomain?: Domain
): [
  (timeAtCursor?: number | undefined) => void,
  (timeAtCursor?: number | undefined) => void,
  () => void,
  boolean,
  boolean,
  ZoomLevels,
  ZoomLevels,
  ZoomLevels
] => {
  const [defaultDomain] = useState<Domain>(domain)

  const zoomScale = currentZoomScale(domain, zoomScales)
  const smallerZoomScale = nextSmallerZoomScale(domain, zoomScales)
  const biggerZoomScale = nextBiggerZoomScale(domain, zoomScales)

  const isZoomInPossible = smallerZoomScale !== 'minimum'
  const isZoomOutPossible = true // currentDomainWidth < defaultDomainWidth - always true, because we can always load more data

  const getDomainSpan = (time: number, width: number): Domain =>
    maxDomain
      ? [Math.max(maxDomain[0], time - width / 2), Math.min(maxDomain[1], time + width / 2)]
      : [time - width / 2, time + width / 2]

  const currentDomainWidth = domain[1] - domain[0]

  const updateDomain = (zoomScale: ZoomScale) => (timeAtCursor?: number) => {
    const newZoomWidth = zoomScale === ZoomLevels.MAX ? currentDomainWidth * 2 : zoomScaleWidth(zoomScale)
    onZoom(getDomainSpan(timeAtCursor ?? (domain[0] + domain[1]) / 2, newZoomWidth))
  }

  const onZoomIn = updateDomain(smallerZoomScale)
  const onZoomOut = updateDomain(biggerZoomScale)
  const onZoomReset = () => {
    onZoom(defaultDomain)
  }

  return [
    onZoomIn,
    onZoomOut,
    onZoomReset,
    isZoomInPossible,
    isZoomOutPossible,
    smallerZoomScale,
    biggerZoomScale,
    zoomScale,
  ]
}
