import React, { useState, useEffect } from 'react'

import makeStyles from '@material-ui/core/styles/makeStyles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { Store } from 'redux'
import SplitPane from 'react-split-pane'

import { LiveDataAction } from '../../store/live/actions'
import { resizeCanvas } from './webgl-rendering'
import { paintTraces } from './paintTraces'
import { NumberDisplay, NumberDisplayParams, toLaneData } from './NumberDisplay'
import { Signal, SignalConfig, SignalId } from '../model'
import { YAxis } from '../containers/YAxis'
import { useWebGLRenderingInfo } from '../hooks/useWebGLRenderingInfo'
import { Theme } from '@material-ui/core'
import { SignalSettings, XAxisOffsetCorrection } from '../../users/model'
import { useLiveDataStateStore } from '../../store/live/useLiveDataState'
import { LiveDataState } from '../../store/live/model'

const dividerControlSize = 10

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'grid',
  },
  layer: ({ isPinned }: { isPinned: boolean; dividerPosition: number }) => ({
    position: 'absolute',
    left: '0px',
    top: isPinned ? dividerControlSize : 0,
    width: '100%',
    height: isPinned ? `calc(100% - ${dividerControlSize}px)` : '100%',
  }),
  xAxisOffsetLabel: ({ dividerPosition }: { isPinned: boolean; dividerPosition: number }) => ({
    position: 'absolute',
    top: 0,
    left: `${dividerPosition * 100}%`,
    fontSize: `${dividerControlSize}px`,
    marginLeft: `${dividerControlSize}px`,
    lineHeight: 1,
    color: theme.palette.text.hint,
  }),
}))

interface WebSocketSignalCanvasProps {
  /** Original configurations coming from server */
  readonly signalConfigs: ReadonlyArray<SignalConfig>
  /** Custom user-specific settings (incl. custom y-scales) */
  readonly signalSettings: ReadonlyMap<SignalId, SignalSettings>
  readonly isPinned: boolean
  readonly dividerPosition: number
  readonly xAxisOffset: XAxisOffsetCorrection
  readonly onUpdateDividerPosition: (dividerPosition: number) => void
}

/**
 * Component that shows the trace of the signal and the most recent numeric value.
 */
export const WebSocketSignalCanvas = ({
  signalConfigs,
  signalSettings,
  isPinned,
  dividerPosition,
  xAxisOffset,
  onUpdateDividerPosition,
}: WebSocketSignalCanvasProps) => {
  const classes = useStyles({ isPinned, dividerPosition })

  const [numberDisplayParams, setNumberDisplayParams] = useState<NumberDisplayParams>()

  const [renderingInfo, canvasRef] = useWebGLRenderingInfo()

  // This component intentionally reads directly from the Redux store
  // i.e. it does NOT use the usual selector mechanism
  // It thus has full control over its own updates (fixed frame rate)
  const store = useLiveDataStateStore()

  useEffect(() => {
    let shouldDraw = true
    let animationRequest: number
    let previousDrawTimestamp: number
    let skipNumberDisplayUpdate = false

    const draw = (timestamp: number) => {
      if (renderingInfo && previousDrawTimestamp !== timestamp) {
        previousDrawTimestamp = timestamp
        const signals = readSignalsFromStore(signalConfigs, signalSettings, store)

        // Adjust the size of the GL drawing buffer to the size of the HTML canvas element
        resizeCanvas(renderingInfo.canvas)
        renderingInfo.gl.viewport(0, 0, renderingInfo.gl.canvas.width * dividerPosition, renderingInfo.gl.canvas.height)

        const now = Date.now() - (xAxisOffset.enabled ? xAxisOffset.currentDelay : 0)
        paintTraces(renderingInfo, signals, now)

        // It's enough to update the number display just every second draw
        if (skipNumberDisplayUpdate === false) {
          setNumberDisplayParams({
            lanes: toLaneData(signals),
            width: renderingInfo.canvas.clientWidth,
            height: renderingInfo.canvas.clientHeight,
          })
        }
        skipNumberDisplayUpdate = !skipNumberDisplayUpdate
      }

      if (shouldDraw) {
        animationRequest = requestAnimationFrame(draw)
      }
    }

    animationRequest = requestAnimationFrame(draw)

    return () => {
      shouldDraw = false
      if (animationRequest) cancelAnimationFrame(animationRequest)
    }
  }, [store, signalConfigs, signalSettings, dividerPosition, renderingInfo, xAxisOffset])

  return (
    <div className={classes.root}>
      {isPinned && numberDisplayParams && (
        <DividerControl
          width={numberDisplayParams.width}
          dividerPosition={dividerPosition}
          onUpdateDividerPosition={onUpdateDividerPosition}
        />
      )}
      <canvas ref={canvasRef} className={classes.layer} />
      <div className={classes.layer}>
        {numberDisplayParams && <NumberDisplay dividerPosition={dividerPosition} {...numberDisplayParams} />}
      </div>
      <div className={classes.layer}>
        {numberDisplayParams && (
          <YAxis
            width={numberDisplayParams.width}
            height={numberDisplayParams.height}
            signalConfigs={signalConfigs}
            signalSettings={signalSettings}
          />
        )}
      </div>
    </div>
  )
}

const useDividerControlStyles = makeStyles({
  resizerMouseHandle: {
    height: 2 * dividerControlSize,
    width: dividerControlSize,
    marginLeft: -(dividerControlSize / 2),
    cursor: 'ew-resize',
    boxSizing: 'border-box',
    zIndex: 1,
    backgroundClip: 'padding-box',
  },
  resizerIcon: {
    fontSize: 40,
    marginRight: -20,
    marginTop: -15,
  },
  leftPane: {
    display: 'grid',
    justifyItems: 'end',
  },
})

interface DividerControlProps {
  readonly width: number
  readonly dividerPosition: number
  readonly onUpdateDividerPosition: (dividerPosition: number) => void
}

const DividerControl = ({ width, dividerPosition, onUpdateDividerPosition }: DividerControlProps) => {
  const classes = useDividerControlStyles()
  const onChange = (leftPaneWidth: number) => onUpdateDividerPosition(leftPaneWidth / width)
  return (
    // https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-typescript-definitions
    // @ts-ignore
    <SplitPane
      split={'vertical'}
      size={dividerPosition * width}
      resizerClassName={classes.resizerMouseHandle}
      onChange={onChange}
      minSize={width / 2}
      maxSize={width}
    >
      <div className={classes.leftPane}>
        <ArrowDropDownIcon className={classes.resizerIcon} />
      </div>
      <div />
    </SplitPane>
  )
}

function readSignalsFromStore(
  signalConfigs: ReadonlyArray<SignalConfig>,
  signalSettings: ReadonlyMap<SignalId, SignalSettings>,
  store: Store<LiveDataState, LiveDataAction>
): ReadonlyArray<Signal> {
  return signalConfigs.map((s) => {
    const getValues = (streamUrl: string) => store.getState().valuesByStreamUrl.get(streamUrl) ?? []
    const yScale = signalSettings.get(s._id)?.yScale ?? s.traceDisplay.yScale
    return {
      ...s,
      traceDisplay: {
        ...s.traceDisplay,
        yScale,
        values: getValues(s.traceDisplay.streamUrl),
      },
      numberDisplays: (s.numberDisplays ?? []).map((nd) => {
        const values = getValues(nd.streamUrl)
        const value = values.length > 0 ? values[values.length - 1].value : NaN
        return {
          ...nd,
          value,
        }
      }),
    }
  })
}
