import { scaleLinear } from 'd3-scale'
import { Signal } from '../model'
import { slidingTimeWindowMs } from '../defaults'
import { drawTrace, RenderingInfo } from './webgl-rendering'
import { laneYScale } from './shared'

/**
 * Paints the signal traces
 *
 * @param renderingInfo
 * @param signals
 * @param now end of sliding time window
 */
export function paintTraces(renderingInfo: RenderingInfo, signals: ReadonlyArray<Signal>, now: number) {
  const lanesScaleY = laneYScale(
    signals.map(s => s._id),
    renderingInfo.gl.drawingBufferHeight
  ).range([renderingInfo.gl.drawingBufferHeight - 1, 0]) // y-axis upside down

  const traceScaleX = scaleLinear()
    .domain([now - slidingTimeWindowMs, now])
    .range([0, renderingInfo.gl.drawingBufferWidth - 1])
    .clamp(true)

  renderingInfo.gl.clearColor(0, 0, 0, 0) // fully transparent to be overlaid on top of dedicated contrast layer
  renderingInfo.gl.clear(renderingInfo.gl.COLOR_BUFFER_BIT)

  signals.forEach(signal => {
    const scaleYRangeMin = lanesScaleY(signal._id) ?? 0
    const scaleYRangeMax = scaleYRangeMin + lanesScaleY.bandwidth()
    const scaleYRange: [number, number] = [scaleYRangeMin, scaleYRangeMax]

    const traceScaleY = scaleLinear()
      .domain([signal.traceDisplay.yScale.min, signal.traceDisplay.yScale.max])
      .range(scaleYRange)
      .clamp(true)

    drawTrace(signal.traceDisplay, traceScaleX, traceScaleY, renderingInfo, signal.color, now)
  })
}
