import React, { useCallback, useEffect, useMemo } from 'react'
import { debounce } from 'lodash'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import { fetchTimeline } from '../actions'
import { TimelineView } from '../components/TimelineView'
import {
  TimelineInteractionModeChangeFn,
  TimelineMarker,
  TimelineOnCursorMoveFn,
  TimelineZoomFn,
} from '../components/Timeline'
import { TimelineId } from '../model'
import { useTimelineData } from '../selectors'
import { Error } from '../../shared/components/Error'
import { Loader } from '../../shared/components/Loader'
import { BedId } from '../../beds/model'
import { RGBA } from '../../shared/utils'
import { YScale } from '../../signals/model'
import { TimelineOptionsCurve, TimelineScreenId } from '../../screens/timelines/model'
import { resetTimelineConfigYScale, setTimelineConfigYScale } from '../../screens/timelines/actions'
import { useTimelineConfig, useTimelineInfo } from '../../screens/timelines/selectors'

interface TimelineProps {
  bedId: BedId
  timelineScreenId: TimelineScreenId
  timelineId: TimelineId
  fromMillis: number
  toMillis: number
  curve: TimelineOptionsCurve
  color: RGBA
  width: number
  height: number
  animate: boolean
  onRangeChange: TimelineZoomFn
  onCursorMove: TimelineOnCursorMoveFn
  onInteractionModeChange: TimelineInteractionModeChangeFn
  marker?: TimelineMarker
}

export const Timeline = ({
  bedId,
  timelineScreenId,
  timelineId,
  width,
  height,
  fromMillis,
  toMillis,
  curve,
  color,
  onRangeChange,
  onCursorMove,
  animate,
  onInteractionModeChange,
  marker,
}: TimelineProps) => {
  const timelineData = useTimelineData(timelineScreenId, timelineId)
  const timelineInfo = useTimelineInfo(timelineScreenId, timelineId)
  const timelineConfig = useTimelineConfig(timelineScreenId, timelineId)

  const cockpitDispatch = useCockpitDispatch()

  const handleYScaleChange = useCallback(
    (yScale: YScale) => {
      cockpitDispatch(setTimelineConfigYScale(timelineScreenId, timelineId, yScale))
    },
    [cockpitDispatch, timelineScreenId, timelineId]
  )

  const handleResetYScale = useCallback(() => {
    cockpitDispatch(resetTimelineConfigYScale(timelineScreenId, timelineId))
  }, [cockpitDispatch, timelineScreenId, timelineId])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedDispatch = useCallback(
    // Delay must be higher than timelines Animation-Time
    // otherwise it leads to temporary animation issues
    // needs to be investigated once this is a problem
    debounce((arg: any) => cockpitDispatch(arg), 750),
    [cockpitDispatch]
  )

  const margin = useMemo(() => ({ top: 26, right: 10, bottom: 26, left: 60 }), [])

  const resolution = Math.min(500, width - (margin.right + margin.left))

  // Fetch timeline data when bed/timeline or range changes
  useEffect(() => {
    const rangeDiff = toMillis - fromMillis

    const minInterval =
      rangeDiff / (timelineInfo?.options.interval.min ?? 1) > resolution
        ? Math.round(rangeDiff / resolution)
        : timelineInfo?.options.interval.min ?? 1

    // Never query data for a range that is smaller than it's minInterval, so we get data points outside
    // of the visible area to display.
    const clearedFromMillis = Math.floor(
      rangeDiff < minInterval ? fromMillis + rangeDiff / 2 - minInterval : fromMillis
    )
    const clearedToMillis = Math.round(rangeDiff < minInterval ? toMillis - rangeDiff / 2 + minInterval : toMillis)

    // Use debounecd dispatch to limit requests while panning
    debouncedDispatch(
      fetchTimeline(bedId, timelineScreenId, timelineId, clearedFromMillis, clearedToMillis, minInterval)
    )
  }, [
    bedId,
    timelineScreenId,
    timelineId,
    fromMillis,
    toMillis,
    resolution,
    debouncedDispatch,
    timelineInfo?.options.interval.min,
  ])

  if (timelineData.status === 'uninitialized') {
    return (
      <div style={{ width, height }}>
        {timelineData.error ? <Error size="large" label={timelineData.error} /> : <Loader size={40} />}
      </div>
    )
  }

  // TODO: Support multiple data series in 1 chart
  return (
    <TimelineView
      timelineScreenId={timelineScreenId}
      data={timelineData}
      isLoading={timelineData.isLoading}
      error={timelineInfo ? timelineData.error : 'Timeline info not loaded'}
      onZoom={onRangeChange}
      onCursorMove={onCursorMove}
      curve={curve === 'step' ? 'stepAfter' : curve}
      color={color}
      fromMillis={fromMillis}
      toMillis={toMillis}
      width={width}
      height={height}
      margin={margin}
      animate={animate}
      onInteractionModeChange={onInteractionModeChange}
      defaultYScale={timelineData.yScale}
      yScale={timelineConfig?.customYScale}
      onYScaleChange={handleYScaleChange}
      onResetYScale={handleResetYScale}
      marker={marker}
    />
  )
}
