import { makeStyles } from '@material-ui/styles'
import { Theme } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles/colorManipulator'
import { clamp } from '../../../shared/utils'

const BORDER_RADIUS = 1
const BORDER_WIDTH = 1

const BAR_WIDTH = 8

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    justifyItems: 'center',
  },
  bar: {
    width: BAR_WIDTH,
    height: '100%',
    display: 'grid',
    alignItems: 'end',
    borderStyle: 'solid',
    borderRadius: BORDER_RADIUS,
    borderWidth: BORDER_WIDTH,
    borderColor: alpha(theme.palette.text.primary, 0.2),
  },
  barFill: ({ showAlarm }: Props) => ({
    width: '100%',
    backgroundColor: showAlarm ? theme.palette.secondary.main : theme.palette.text.primary,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderWidth: BORDER_WIDTH,
  }),
}))

export interface Props {
  readonly probability: number | undefined
  readonly showAlarm: boolean
}

export const ProbabilityIndicator = (props: Props) => {
  const classes = useStyles(props)
  const { probability } = props
  const isMissingProbability = probability === undefined || isNaN(probability)
  const barHeight = isMissingProbability ? 0 : clamp(Math.round(probability! * 100), 0, 100)
  return (
    <div className={classes.root}>
      <div className={classes.bar}>
        {barHeight > 0 && <div style={{ height: `${barHeight}%` }} className={classes.barFill} />}
      </div>
    </div>
  )
}
