import * as React from 'react'
import { BedIsolationEditDialog as BedIsolationEditDialogComponent } from '../components/BedIsolationEditDialog'
import { Bed, BedId, BedIsolation } from '../model'
import { saveBedIsolation } from '../actions'
import { OverviewScreenAction, switchToReadOnlyMode, UpdateBedEditModeAction } from '../../screens/overview/actions'
import { useCockpitState } from '../../store/cockpit/useCockpitState'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'

interface Props {
  readonly bed: Bed
}

export const BedIsolationEditDialog = ({ bed }: Props) => {
  // TODO: Create a selector for this
  const isOpen = useCockpitState<boolean>((s) => {
    const bedEditMode = s.ui.activeUser.settings.screen.overview.bedEditMode
    return bedEditMode.type === 'edit-in-progress' && bedEditMode.bedId === bed.id
  })

  const dispatch = useCockpitDispatch<OverviewScreenAction | UpdateBedEditModeAction>()

  const onCancel = () => {
    dispatch(switchToReadOnlyMode())
  }

  const onBedIsolationChange = (bedId: BedId, bedIsolation: BedIsolation) => {
    dispatch(switchToReadOnlyMode())
    dispatch(saveBedIsolation(bedId, bedIsolation))
  }

  return (
    <BedIsolationEditDialogComponent
      bed={bed}
      isOpen={isOpen}
      onCancel={onCancel}
      onBedIsolationChange={onBedIsolationChange}
    />
  )
}
