import { makeStyles } from '@material-ui/core/styles'
import { SignalConfig, SignalId, YScale } from '../model'
import * as React from 'react'
import { useMemo, useState } from 'react'
import { IconButton } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import SettingsIcon from '@material-ui/icons/Settings'
import { DEFAULT_FRACTION_DIGITS, laneYScale, NUMBER_DISPLAY_BACKGROUND_OPACITY } from './shared'
import { YAxisConfigDialog } from './YAxisConfigDialog'
import { cn, RGBA, toBackgroundColor, toFillColor } from '../../shared/utils'
import { SignalSettings, YAxisConfigDialogState } from '../../users/model'

const useStyles = makeStyles({
  lane: (props: LaneYAxisProps) => ({
    position: 'absolute',
    left: 0,
    top: props.y,
    display: 'grid',
    justifyContent: 'flex-start',
    height: props.height,
    minWidth: 48, // encompass settings icon
  }),
  cell: (props: LaneYAxisProps) => ({
    padding: 2,
    color: toFillColor(props.laneColor),
    backgroundColor: toBackgroundColor(props.laneColor, NUMBER_DISPLAY_BACKGROUND_OPACITY),
    textAlign: 'right',
    cursor: 'default', // favor arrow over text-cursor
  }),
  max: {
    alignSelf: 'start',
  },
  min: {
    alignSelf: 'end',
  },
  settingsIconPane: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    backgroundColor: 'rgba(200,200,200,0.2)',
  },
})

interface Props {
  readonly width: number
  readonly height: number
  /** Original configurations coming from server (incl. default y-scales) */
  readonly signalConfigs: ReadonlyArray<SignalConfig>
  /** Custom user-specific settings (incl. custom y-scales) */
  readonly signalSettings: ReadonlyMap<SignalId, SignalSettings>
  readonly configDialogState: YAxisConfigDialogState
  readonly onShowConfigDialog: (signalId: SignalId) => void
  readonly onHideConfigDialog: () => void
  readonly onUpdateYScale: (signalId: SignalId, yScale: YScale) => void
}

export const YAxis = (props: Props) => {
  const comparableProps = JSON.stringify(props)
  // Map type must be handled explicitly
  const comparableSignalSettings = JSON.stringify(Array.from(props.signalSettings))
  return useMemo(() => {
    const {
      width,
      height,
      signalConfigs,
      signalSettings,
      configDialogState,
      onShowConfigDialog,
      onHideConfigDialog,
      onUpdateYScale,
    } = props

    const yScale = laneYScale(
      signalConfigs.map((s) => s._id),
      height
    )

    return (
      <div style={{ width, height }}>
        {signalConfigs.map((s) => {
          const isConfigDialogOpen = configDialogState.type === 'visible' && configDialogState.signalId === s._id
          const defaultYScale = s.traceDisplay.yScale
          const currentYScale = signalSettings.get(s._id)?.yScale ?? defaultYScale
          return (
            <div key={s._id}>
              <LaneYAxis
                id={s._id}
                y={yScale(s._id) ?? 0}
                height={yScale.bandwidth()}
                currentYScale={currentYScale}
                defaultYScale={defaultYScale}
                laneColor={s.color}
                fractionDigits={s.fractionDigits ?? DEFAULT_FRACTION_DIGITS}
                unit={s.unit ?? ''}
                isConfigDialogOpen={isConfigDialogOpen}
                onShowConfigDialog={onShowConfigDialog}
                onHideConfigDialog={onHideConfigDialog}
                onUpdateYScale={onUpdateYScale}
              />
            </div>
          )
        })}
      </div>
    )
    // use string representation as basis for memoization
    // eslint-disable-next-line
  }, [comparableProps, comparableSignalSettings])
}

interface LaneYAxisProps {
  readonly id: SignalId
  readonly height: number
  readonly y: number
  readonly currentYScale: YScale
  readonly defaultYScale: YScale
  readonly laneColor: RGBA
  readonly fractionDigits: number
  readonly unit: string
  readonly isConfigDialogOpen: boolean
  readonly onShowConfigDialog: (signalId: SignalId) => void
  readonly onHideConfigDialog: () => void
  readonly onUpdateYScale: (signalId: SignalId, yScale: YScale) => void
}

const LaneYAxis = (props: LaneYAxisProps) => {
  const classes = useStyles(props)
  const {
    id,
    fractionDigits,
    unit,
    currentYScale,
    defaultYScale,
    isConfigDialogOpen,
    onShowConfigDialog,
    onHideConfigDialog,
    onUpdateYScale,
  } = props

  const [settingsIconPaneVisibility, setSettingsIconPaneVisibility] = useState<'visible' | 'hidden'>('hidden')

  const onMouseEnter = () => setSettingsIconPaneVisibility('visible')
  const onMouseLeave = () => setSettingsIconPaneVisibility('hidden')

  const onConfigDialogOk = (yScale: YScale) => {
    onHideConfigDialog()
    onUpdateYScale(id, yScale)
    onMouseLeave()
  }

  const onConfigDialogCancel = () => {
    onHideConfigDialog()
    onMouseLeave()
  }

  return (
    <div className={classes.lane} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <div className={cn(classes.cell, classes.max)}>{`${currentYScale.max.toFixed(fractionDigits)} ${unit}`}</div>
      <div className={cn(classes.cell, classes.min)}>{`${currentYScale.min.toFixed(fractionDigits)} ${unit}`}</div>
      <div className={classes.settingsIconPane} style={{ visibility: settingsIconPaneVisibility }}>
        <IconButton onClick={() => onShowConfigDialog(id)}>
          <Tooltip title={'Configure Y-Scale'}>
            <SettingsIcon />
          </Tooltip>
          <YAxisConfigDialog
            title={`Configure Y-Axis`}
            isOpen={isConfigDialogOpen}
            originalYScale={currentYScale}
            defaultYScale={defaultYScale}
            onCancel={onConfigDialogCancel}
            onOk={onConfigDialogOk}
          />
        </IconButton>
      </div>
    </div>
  )
}
