import React from 'react'
import { BirdsEyeNavigation as BirdsEyeNavigationComponent } from '../components/BirdsEyeNavigation'
import { useCockpitState } from '../../store/cockpit/useCockpitState'
import { useNavigate } from 'react-router-dom'
import { BedConfig, BedId } from '../../beds/model'
import { useRoutedBed } from '../../screens/selectors'

export const BirdsEyeNavigation = () => {
  const bedIds = useCockpitState<ReadonlyArray<BedId>>((s) => s.entities.beds.allBeds.map((b: BedConfig) => b.id))
  const focusBedId = useRoutedBed().id
  const navigate = useNavigate()
  const onClick = () => navigate('/')
  return <BirdsEyeNavigationComponent bedIds={bedIds} focusBedId={focusBedId} onClick={onClick} />
}
