import React, { useRef, useLayoutEffect, useState } from 'react'

export interface DynamicSizedSvgTextProps extends React.SVGProps<SVGTextElement> {
  maxWidth: number
  children: any
}

export const DynamicWidthSvgText = ({ maxWidth, children, ...textProps }: DynamicSizedSvgTextProps) => {
  const [adjustLength, setAdjustLength] = useState<boolean>(false)
  const ref = useRef<SVGTextElement>(null)

  useLayoutEffect(() => {
    if (ref.current && !adjustLength) {
      const { width } = ref.current.getBBox()
      setAdjustLength(width > maxWidth)
    }

    return () => {
      if (adjustLength) {
        setAdjustLength(false)
      }
    }
  }, [children, textProps.fontSize, maxWidth, adjustLength])

  return (
    <text {...textProps} ref={ref} textLength={adjustLength ? maxWidth : undefined}>
      {children}
    </text>
  )
}
