import * as React from 'react'
import BedIcon from '@material-ui/icons/LocalHotel'
import { FormControl, MenuItem, Select, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Bed, BedId, BedIsolation } from '../../beds/model'

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  icon: {
    margin: theme.spacing(1),
  },
  select: {
    color: 'inherit',
    minWidth: 100,
  },
}))

interface Props {
  beds: ReadonlyArray<Bed>
  focusBedId: BedId
  onSelect: (bedId: BedId) => void
}

export const BedSelector = ({ beds, focusBedId, onSelect }: Props) => {
  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => onSelect(event.target.value as BedId)

  return (
    <div className={classes.root}>
      <BedIcon className={classes.icon} />
      <form autoComplete="off">
        <FormControl>
          <Select className={classes.select} value={focusBedId} onChange={handleChange}>
            {beds.map((bed) => {
              return (
                <MenuItem key={bed.id} disabled={bed.isolationState === BedIsolation.Vacant} value={bed.id}>
                  {bed.label}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </form>
    </div>
  )
}
