import React, { useCallback, useMemo, useState } from 'react'
import { rgb } from 'd3-color'
import { Line, LineProps, Serie, Datum } from '@nivo/line'
import { useInteractionLayer } from './layers/interaction/useInteractionLayer'
import { PointsLayer } from './layers/points/PointsLayer'
import { TimelineData, TimelineDatum } from './model'
import { InteractionMode } from 'react-svg-timeline'
import { calculateDataDensity, getAxisFormat } from './utils'
import { useTheme } from '@material-ui/core'
import { Margin } from '@nivo/core'
import { RangeAreaLayer } from './layers/range/RangeAreaLayer'
import { useCustomSlicesLayer } from '../containers/layers/custom-slices/useCustomSlicesLayer'
import { useCustomYScaleLayer } from './layers/custom-yscale/useCustomYScaleLayer'
import { YAxisConfigDialog } from '../../signals/components/YAxisConfigDialog'
import { TimelineScreenId } from '../../screens/timelines/model'
import { STD_RGBA } from '../../shared/utils'
import { YScale } from '../../signals/model'

export const DEFAULT_POINT_SIZE = 10
export const DEFAULT_POINTBORDER_SIZE = DEFAULT_POINT_SIZE * 0.3

export type TimelineZoomFn = (fromMillis: number, toMillis: number) => void

export type TimelineOnCursorMoveFn = (cursorPosition: number) => void

export type TimelineInteractionModeChangeFn = (interactionMode: InteractionMode) => void

export interface TimelineSerie extends Serie {
  data: (Datum & TimelineDatum)[]
}

export interface TimelineMarker {
  value: number
  label: string
}

export interface TimelineProps {
  timelineScreenId: TimelineScreenId
  data: TimelineData
  onZoom: TimelineZoomFn
  onCursorMove: TimelineOnCursorMoveFn
  onInteractionModeChange: TimelineInteractionModeChangeFn
  curve: LineProps['curve']
  color: STD_RGBA
  fromMillis: number
  toMillis: number
  height: number
  width: number
  margin: Margin
  animate: boolean
  defaultYScale?: YScale
  yScale?: YScale
  onYScaleChange: (yScale: YScale) => void
  onResetYScale: () => void
  marker?: TimelineMarker
}

export const Timeline = React.memo(
  ({
    timelineScreenId,
    data,
    onZoom,
    onCursorMove,
    curve,
    color,
    fromMillis,
    toMillis,
    height,
    width,
    margin,
    animate,
    onInteractionModeChange,
    defaultYScale,
    yScale,
    onYScaleChange,
    marker,
  }: TimelineProps) => {
    const theme = useTheme()

    const [isConfigDialogOpen, setIsConfigDialogOpen] = useState(false)

    const lineData: TimelineSerie[] = data.map((dataSeries) => ({
      // Add timestamp here, so that animation is cancelled with changing data
      id: `${dataSeries.label}-${dataSeries.values[0]?.timestamp}`,
      data: dataSeries.values.map((value) => ({
        ...value,
        x: new Date(value.timestamp),
        y: value.value,
      })),
    }))

    const initialYScale = useMemo(() => {
      // TODO: Support multiple data series
      const values = data[0].values.flatMap((v) => [v.min ?? v.value ?? 0, v.max ?? v.value ?? 0])

      return {
        min: Math.min(...values),
        max: Math.max(...values),
      }
    }, [data])

    const interactionLayer = useInteractionLayer(
      (range) => {
        onZoom(range[0], range[1])
      },
      onCursorMove,
      onInteractionModeChange,
      [fromMillis, toMillis]
    )

    const customSlicesLayer = useCustomSlicesLayer(timelineScreenId)

    const handleOpenCustomYScaleDialog = useCallback(() => {
      setIsConfigDialogOpen(true)
    }, [setIsConfigDialogOpen])

    const handleCloseCustomYScaleDialog = useCallback(() => {
      setIsConfigDialogOpen(false)
    }, [setIsConfigDialogOpen])

    const handleCustomYScaleConfirm = useCallback(
      (newYScale: YScale) => {
        setIsConfigDialogOpen(false)
        onYScaleChange(newYScale)
      },
      [onYScaleChange]
    )

    const customYScaleLayer = useCustomYScaleLayer(handleOpenCustomYScaleDialog)

    const pointSize = Math.min(
      DEFAULT_POINT_SIZE,
      1 / calculateDataDensity(lineData, fromMillis, toMillis, width - (margin.left + margin.right))
    )

    const fontSize = 12

    const hexColor = rgb(...color).formatHex()

    return (
      <>
        <Line
          data={lineData}
          height={height}
          width={width}
          colors={hexColor}
          theme={{
            background: theme.palette.background.paper,
            textColor: theme.palette.getContrastText(theme.palette.background.paper),
            fontSize,
            axis: {
              ticks: {
                line: {
                  stroke: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[300],
                  strokeWidth: 0.5,
                },
              },
            },
            grid: {
              line: {
                stroke: theme.palette.type === 'dark' ? theme.palette.grey[900] : theme.palette.grey[300],
                strokeWidth: 0.5,
              },
            },
          }}
          lineWidth={1.5}
          animate={false}
          xScale={{
            type: 'time',
            format: 'native',
            min: new Date(fromMillis), // TODO: new Date(millis) is not supported in all Browsers
            max: new Date(toMillis),
            useUTC: false,
            precision: 'millisecond',
          }}
          xFormat="time:%Y-%m-%d %H:%M:%S.%L"
          yScale={{
            type: 'linear',
            stacked: false,
            ...(yScale ?? defaultYScale),
          }}
          axisBottom={{
            ...getAxisFormat(fromMillis, toMillis),
            tickPadding: 2,
          }}
          axisLeft={{
            tickValues: Math.min(7, Math.floor((height - margin.top - margin.bottom) / fontSize)),
          }}
          enablePointLabel={false}
          pointSize={pointSize}
          pointBorderWidth={pointSize * 0.3}
          pointBorderColor={{
            theme: 'background',
          }}
          useMesh={false}
          enableSlices={false}
          enableGridX={true}
          isInteractive={false}
          margin={margin}
          curve={curve}
          layers={[
            // 'grid',
            'axes',
            'markers',
            // 'areas',
            // 'crosshair',
            RangeAreaLayer,
            // RangeLayer,
            'lines',
            customSlicesLayer,
            PointsLayer,
            interactionLayer,
            //'slices',
            //'mesh',
            'legends',
            customYScaleLayer,
          ]}
          legends={[
            {
              anchor: 'top-left',
              direction: 'row',
              translateX: 0,
              translateY: -22,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              itemOpacity: 1,
              symbolSize: 12,
              symbolShape: 'circle',
              symbolBorderColor: 'rgba(0, 0, 0, .5)',
              // We need to add this customly, because otherwise it would take the series id (which has a timestamp, see above)
              data: data.map((dataItem) => ({ id: dataItem.id as string, label: dataItem.label, color: hexColor })),
            },
          ]}
          markers={
            marker
              ? [
                  {
                    axis: 'y',
                    value: marker.value,
                    legend: marker.label,
                    legendOrientation: 'horizontal',
                    legendPosition: 'top-right',
                    lineStyle: { stroke: hexColor, strokeWidth: 1, strokeDasharray: '4,2' },
                    textStyle: { fill: theme.palette.getContrastText(theme.palette.background.paper), fontSize: 12 },
                  },
                ]
              : undefined
          }
        />
        <YAxisConfigDialog
          title={`Configure Y-Axis`}
          isOpen={isConfigDialogOpen}
          originalYScale={yScale ?? defaultYScale ?? initialYScale}
          defaultYScale={defaultYScale ?? initialYScale}
          onCancel={handleCloseCustomYScaleDialog}
          onOk={handleCustomYScaleConfirm}
        />
      </>
    )
  }
)
