import React, { useMemo } from 'react'
import { useTheme } from '@material-ui/core'
import { TimelineLoader } from './TimelineLoader'
import { TimelineError } from './TimelineError'
import { TimelineEmpty } from './TimelineEmpty'
import { Timeline, TimelineProps } from './Timeline'
import { TimelineSeries } from './model'

export type TimelineViewProps = Omit<TimelineProps, 'data'> & {
  data: TimelineSeries
  isLoading: boolean
  error: string | null
}

export const TimelineView = React.memo(
  ({
    timelineScreenId,
    data,
    onZoom,
    onCursorMove,
    isLoading = false,
    error = null,
    curve,
    color,
    fromMillis,
    toMillis,
    height,
    width,
    margin,
    animate,
    onInteractionModeChange,
    defaultYScale,
    yScale,
    onYScaleChange,
    onResetYScale,
    marker,
  }: TimelineViewProps) => {
    const theme = useTheme()

    // Memoize this transformation to avoid unneccessary rerenders
    const timelineDataAsArray = useMemo(
      () => [
        {
          id: data.id,
          label: data.label,
          values: data.values,
          yScale: data.yScale,
        },
      ],
      [data.id, data.label, data.values, data.yScale]
    )

    return (
      <>
        <Timeline
          timelineScreenId={timelineScreenId}
          data={timelineDataAsArray}
          onZoom={onZoom}
          onCursorMove={onCursorMove}
          curve={curve}
          color={color}
          fromMillis={fromMillis}
          toMillis={toMillis}
          width={width}
          height={height}
          margin={margin}
          animate={animate}
          onInteractionModeChange={onInteractionModeChange}
          defaultYScale={defaultYScale}
          yScale={yScale}
          onYScaleChange={onYScaleChange}
          onResetYScale={onResetYScale}
          marker={marker}
        />
        {!isLoading && timelineDataAsArray.findIndex((serie) => serie.values.length > 0) === -1 && (
          <TimelineEmpty width={width} height={height} />
        )}
        {isLoading && <TimelineLoader width={width} height={height} backgroundColor={theme.palette.background.paper} />}
        {error && <TimelineError position={{ top: 20, left: width }} msg={error} />}
      </>
    )
  }
)
