import { useCockpitState } from '../../store/cockpit/useCockpitState'
import { Timeline, CursorPosition, TimelinesSelectionValue, TimelineConfig, TimelineScreenId } from './model'
import { defaultTimelinesScreenStateValue } from './defaults'
import { TimelineId } from '../../timeline/model'
import { Domain } from 'react-svg-timeline'

export const useTimelineInfo = (timelineScreenId: TimelineScreenId, timelineId: TimelineId) =>
  useCockpitState<Timeline | undefined>((s) => {
    const bedTimelinesScreenState = s.ui.activeUser.settings.screen.timelines.get(timelineScreenId)

    if (bedTimelinesScreenState && bedTimelinesScreenState.timelines.status === 'initialized') {
      return bedTimelinesScreenState.timelines.availableTimelines.find((timeline) => timeline.id === timelineId)
    } else {
      return undefined
    }
  })

export const useTimelineTimelines = (timelineScreenId: TimelineScreenId) =>
  useCockpitState<TimelinesSelectionValue>(
    (s) =>
      s.ui.activeUser.settings.screen.timelines.get(timelineScreenId)?.timelines ??
      defaultTimelinesScreenStateValue.timelines
  )

export const useTimelineAnimate = (timelineScreenId: TimelineScreenId) =>
  useCockpitState<boolean>(
    (s) =>
      s.ui.activeUser.settings.screen.timelines.get(timelineScreenId)?.animate ??
      defaultTimelinesScreenStateValue.animate
  )

export const useTimelineTimeRange = (timelineScreenId: TimelineScreenId) =>
  useCockpitState<Domain>(
    (s) =>
      s.ui.activeUser.settings.screen.timelines.get(timelineScreenId)?.timeRange ??
      defaultTimelinesScreenStateValue.timeRange
  )

export const useTimelineCursorPosition = (timelineScreenId: TimelineScreenId) =>
  useCockpitState<CursorPosition>(
    (s) =>
      s.ui.activeUser.settings.screen.timelines.get(timelineScreenId)?.cursorPosition ??
      defaultTimelinesScreenStateValue.cursorPosition
  )

export const useTimelineConfig = (timelineScreenId: TimelineScreenId, timelineId: TimelineId) =>
  useCockpitState<TimelineConfig | undefined>(
    (s) => s.ui.activeUser.settings.screen.timelines.get(timelineScreenId)?.timelineConfigs.get(timelineId) ?? undefined
  )
