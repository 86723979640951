import { scaleBand } from 'd3-scale'
import { SignalId, TimestampedValue } from '../model'
import { bisector } from 'd3-array'
import { slidingTimeWindowMs } from '../defaults'
import { formatDistanceStrict } from 'date-fns'

export const SIGNAL_LANE_PADDING_INNER = 0.05
export const SIGNAL_LANE_PADDING_OUTER = 0.025
export const SIGNAL_TRACE_LINE_WIDTH = 3

export const NUMBER_DISPLAY_BACKGROUND_OPACITY = 0.1

export const DEFAULT_FRACTION_DIGITS = 2

export const laneYScale = (signalIds: ReadonlyArray<SignalId>, height: number) =>
  scaleBand<SignalId>()
    .domain(signalIds)
    .paddingInner(signalIds.length > 1 ? SIGNAL_LANE_PADDING_INNER : 0)
    .paddingOuter(signalIds.length > 1 ? SIGNAL_LANE_PADDING_OUTER : 0)
    .range([1, height])

export const findSlidingTimeWindowStartIndex = (values: ReadonlyArray<TimestampedValue>, now: number) =>
  bisector((v: TimestampedValue) => v.timestamp).right(values, now - slidingTimeWindowMs)

export const findSlidingTimeWindowEndIndex = (values: ReadonlyArray<TimestampedValue>, now: number) =>
  bisector((v: TimestampedValue) => v.timestamp).left(values, now)

export const formatDelay = (delayMillis: number) => {
  if (delayMillis < 1000) {
    return `${Math.round(delayMillis)}ms`
  } else {
    const now = Date.now()

    return formatDistanceStrict(now - delayMillis, now, { roundingMethod: 'round' })
  }
}
