import { applyMiddleware, createStore, Store } from 'redux'
import { LiveDataState } from './model'
import { LiveDataAction } from './actions'
import { liveDataRootReducer } from './reducer'
import { Context, createContext } from 'react'
import { liveDataMiddleware } from './middleware'
import { ReactReduxContextValue } from 'react-redux'

export const createLiveDataStore = (): Store<LiveDataState, LiveDataAction> => {
  return createStore(liveDataRootReducer, applyMiddleware(liveDataMiddleware))
}

// @ts-ignore // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37737
export const liveDataContext: Context<ReactReduxContextValue> = createContext(null) as Context<ReactReduxContextValue>
