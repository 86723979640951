import { TimelineScreenId } from '../screens/timelines/model'
import { TimelineId, TimelineState, TimelineStateValue, TimelineStatusUninitialized } from './model'

export const defaultTimelineStatusUninitialized: TimelineStatusUninitialized = {
  status: 'uninitialized',
  isLoading: false,
  error: null,
}

export const defaultTimelineState = (): TimelineState =>
  new Map<TimelineScreenId, Map<TimelineId, TimelineStateValue>>()
