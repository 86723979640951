import React from 'react'
import { BedSignalConfigDialog as BedSignalConfigDialogComponent } from '../components/BedSignalConfigDialog'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import { Bed } from '../model'
import { useSelectedSignalConfigs } from '../../screens/overview/selectors'
import { SignalId } from '../../signals/model'
import { hideSignalConfigDialog, updateSelectedSignals, UserAction } from '../../users/actions'
import { useBedSettings } from '../../users/selectors'

interface Props {
  readonly bed: Bed
}

export const BedSignalConfigDialog = ({ bed }: Props) => {
  const bedId = bed.id
  const isOpen = useBedSettings(bedId).signalConfigDialogState.type === 'visible'
  const selectedSignals = useSelectedSignalConfigs(bed).map((s) => s._id)

  const dispatch = useCockpitDispatch<UserAction>()

  const onCancel = () => {
    dispatch(hideSignalConfigDialog(bedId))
  }

  const onSignalConfigChange = (signalIds: ReadonlyArray<SignalId>) => {
    dispatch(hideSignalConfigDialog(bedId))
    dispatch(updateSelectedSignals(bedId, signalIds))
  }

  return (
    <BedSignalConfigDialogComponent
      title={`Signals ${bed.label}`}
      isOpen={isOpen}
      availableSignals={bed.signals.availableSignals}
      selectedSignals={selectedSignals}
      onCancel={onCancel}
      onSignalSelectionChange={onSignalConfigChange}
    />
  )
}
