import { InfoTile } from './InfoTile'
import { BedId } from '../../model'
import { ProbabilityInfoTileContents } from './ProbabilityInfoTileContents'

interface Props {
  readonly bedId: BedId
  readonly probability?: number
  readonly showAlarm?: boolean
  readonly tooltip?: string
}

export const BrainTile = ({ bedId, probability, showAlarm = false, tooltip }: Props) => {
  return (
    <InfoTile
      bedId={bedId}
      disabled={probability === undefined}
      detailScreen={'detail/dci'} // TODO: Support specific detail screens
      tooltip={tooltip}
      variant={'bed-card'}
    >
      <ProbabilityInfoTileContents
        infoTileType={'brain'}
        probability={probability ?? undefined}
        showAlarm={showAlarm}
      />
    </InfoTile>
  )
}
