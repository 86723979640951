import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Bar } from '@nivo/bar'
import AutoSizer from 'react-virtualized-auto-sizer'
import { DciStatusUninitialized, DciWaterfallInitialized } from '../../../dci/model'

const useStyles = makeStyles({
  root: { display: 'grid', width: '100%', height: '100%' },
})

interface DciWaterfallChartProps {
  data: DciWaterfallInitialized | DciStatusUninitialized
}

export const DciWaterfallChart = ({ data }: DciWaterfallChartProps) => {
  const classes = useStyles()

  if (data.status === 'uninitialized') {
    return <p>Loading…</p>
  }

  return (
    <div className={classes.root}>
      <AutoSizer>
        {({ width, height }) => (
          <Bar
            // @ts-ignore
            data={data.contributors}
            indexBy="label"
            keys={['contribution']}
            colorBy="indexValue"
            markers={[
              {
                axis: 'y',
                value: 0,
                lineStyle: { strokeWidth: 1 },
                legend: `${data.static}`,
                legendOrientation: 'vertical',
                legendPosition: 'bottom-right',
                textStyle: {
                  fontSize: 18,
                },
              },
            ]}
            padding={0.55}
            height={height}
            width={width}
            animate={false}
            enableLabel={false}
            enableGridX={false}
            enableGridY={false}
            theme={{
              tooltip: {
                container: {
                  color: 'black',
                },
              },
            }}
          />
        )}
      </AutoSizer>
    </div>
  )
}
