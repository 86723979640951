import { makeStyles, Theme } from '@material-ui/core/styles'

export const borderStyle = {
  borderRadius: 4,
  borderWidth: 2,
  borderColor: 'rgba(150, 150, 150, 0.5)',
  borderStyle: 'solid',
}

export const useStyles = makeStyles((theme: Theme) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2),
    overflow: 'hidden', // lists are scrollable
  },
  listContainer: {
    ...borderStyle,
    overflow: 'hidden',
  },
  listTitle: {
    padding: theme.spacing(2),
  },
  listSearch: {
    padding: theme.spacing(2),
  },
  emptyText: {
    padding: theme.spacing(2),
  },
  list: {
    height: '50vh',
    maxHeight: '50vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  listLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  listDragIcon: {
    minWidth: `${24 + theme.spacing(1)}px !important`,
  },
  listItemWithAction: {
    paddingRight: `calc(48px + ${theme.spacing(1)}px) !important`,
  },
  listAction: {
    right: `${theme.spacing(1)} !important`,
  },
}))
