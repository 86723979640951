import React from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import ToggleButton from '@material-ui/lab/ToggleButton'

export interface ToolbarItemProps {
  content: JSX.Element
  tooltip: JSX.Element
  active: boolean
  disabled: boolean
  onClick: React.MouseEventHandler
}

export const ToolbarItem = ({ content, tooltip, active, disabled, onClick }: ToolbarItemProps) => {
  return (
    <Tooltip key={`tooltip-${active}`} title={tooltip} interactive>
      <span>
        <ToggleButton onClick={onClick} disabled={disabled} selected={active} size="small" value={active}>
          {content}
        </ToggleButton>
      </span>
    </Tooltip>
  )
}
