import { makeStyles } from '@material-ui/core/styles'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  svg: {
    fill: theme.palette.text.primary,
  },
}))

interface Props {
  readonly size: number
}

export const LungIcon = ({ size }: Props) => {
  const classes = useStyles()
  return (
    <svg className={classes.svg} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
      <g>
        <path
          d="M345.6,102.4c-11.546,0-21.623,4.745-28.373,13.363c-14.046,17.946-7.629,43.921-1.417,69.043
			c0.486,1.971,1.05,4.275,1.604,6.579C299.682,187.767,268.8,174.797,268.8,128V38.4c0-7.074-5.726-12.8-12.8-12.8
			c-7.074,0-12.8,5.726-12.8,12.8V128c0,46.814-30.908,59.776-48.606,63.386c0.546-2.313,1.109-4.617,1.596-6.579
			c6.212-25.122,12.629-51.098-1.417-69.043c-6.75-8.619-16.828-13.363-28.373-13.363C131.166,102.4,0,160.017,0,332.8
			c0,80.947,3.917,153.6,51.2,153.6c18.458,0,30.916-7.851,40.926-14.148c10.155-6.4,18.176-11.452,35.874-11.452
			c89.6,0,89.6-51.61,89.6-204.8c0-18.202-3.396-31.411-9.924-41.557c17.536-5.205,36.224-16.606,48.324-36.881
			c12.1,20.275,30.788,31.676,48.324,36.881c-6.528,10.146-9.924,23.356-9.924,41.557c0,153.19,0,204.8,89.6,204.8
			c17.698,0,25.719,5.052,35.874,11.452c10.01,6.298,22.468,14.148,40.926,14.148c47.283,0,51.2-72.653,51.2-153.6
			C512,160.017,380.834,102.4,345.6,102.4z M128,435.2c-44.186,0-51.2,25.6-76.8,25.6c-25.6,0-25.6-90.889-25.6-128
			C25.6,179.2,140.8,128,166.4,128c25.199,0,0,56.772,0,76.8c0,25.6,25.6,0.939,25.6,51.2C192,409.6,192,435.2,128,435.2z
			 M460.8,460.8c-25.6,0-32.614-25.6-76.8-25.6c-64,0-64-25.6-64-179.2c0-50.261,25.6-25.6,25.6-51.2c0-20.028-25.199-76.8,0-76.8
			c25.6,0,140.8,51.2,140.8,204.8C486.4,369.911,486.4,460.8,460.8,460.8z"
        />
      </g>
    </svg>
  )
}
