import React from 'react'
import { useSpring, animated } from '@react-spring/web'
import { useMotionConfig } from '@nivo/core'

export interface SlicesLabelProps {
  x: number
  y: number
  label: string
  fontSize: number
  fontColor: string
  bgColor: string
  maxWidth: number
  axis: 'x' | 'y'
  axisOffset: number
  innerWidth: number
  innerHeight: number
}

export const SlicesLabel = ({
  x,
  y,
  label,
  fontColor,
  fontSize,
  bgColor,
  maxWidth,
  axis,
  axisOffset,
  innerWidth,
  innerHeight,
}: SlicesLabelProps) => {
  const { animate, config: springConfig } = useMotionConfig()

  const animatedProps = useSpring({
    transform: `translate(${
      axis === 'y' ? 0 - maxWidth - axisOffset : Math.min(Math.max(0, x - maxWidth / 2), innerWidth - maxWidth)
    }, ${axis === 'y' ? y - fontSize : innerHeight + axisOffset})`,
    config: springConfig,
    immediate: !animate,
  })

  return (
    <animated.g transform={animatedProps.transform} width={maxWidth}>
      <rect
        width={maxWidth}
        height={2 * fontSize}
        x={0}
        y={0}
        fill={bgColor}
        rx={fontSize / 2}
        ry={fontSize / 2}
      ></rect>
      <text
        x={axis === 'y' ? maxWidth - fontSize / 2 : maxWidth / 2}
        y={fontSize / 2}
        style={{
          opacity: 1,
          fontWeight: 'bold',
          fontSize: fontSize,
          lineHeight: fontSize,
        }}
        fill={fontColor}
        dominantBaseline="hanging"
        textAnchor={axis === 'y' ? 'end' : 'middle'}
      >
        {label}
      </text>
    </animated.g>
  )
}
