import { Dispatch, Middleware } from 'redux'

import { setSelectedTimelines, TimelinesScreenAction } from './actions'
import { RECEIVE_TIMELINES } from './actionTypes'
import { CockpitState } from '../../store/cockpit/model'
import { BedId } from '../../beds/model'

export const initSelectedTimelinesMiddleware: Middleware<{}, CockpitState> =
  (store) => (next: Dispatch<TimelinesScreenAction>) => (action: TimelinesScreenAction) => {
    switch (action.type) {
      case RECEIVE_TIMELINES:
        const state = store.getState()

        if (
          state.ui.activeUser.settings.screen.timelines.has(action.payload.timelineScreenId) &&
          state.ui.activeUser.settings.screen.timelines.get(action.payload.timelineScreenId)?.timelines.status ===
            'uninitialized'
        ) {
          next(action)

          const bedSettingsSelectedSignals = state.ui.activeUser.settings.bedSettings.get(
            action.payload.timelineScreenId as BedId
          )?.selectedSignals
          const bedSelectedSignals =
            state.entities.beds.type !== 'uninitialized-beds'
              ? state.entities.beds.allBeds.find((bed) => bed.id === action.payload.timelineScreenId)?.signals
                  .selectedSignals
              : undefined

          const previouslySelectedSignals =
            bedSettingsSelectedSignals !== 'uninitialized' && bedSettingsSelectedSignals !== undefined
              ? bedSettingsSelectedSignals
              : bedSelectedSignals !== undefined
              ? bedSelectedSignals
              : []

          store.dispatch(setSelectedTimelines(action.payload.timelineScreenId, previouslySelectedSignals))
        } else {
          next(action)
        }
        break
      default:
        next(action)
    }
  }
