import * as React from 'react'
import { Theme } from '@material-ui/core'
import logoLightTheme from './icu-cockpit-logo-light-theme.png'
import logoDarkTheme from './icu-cockpit-logo-dark-theme.png'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    width: '100%',
    height: '100%',
    background: `url(${theme.palette.type === 'light' ? logoLightTheme : logoDarkTheme})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50%',
  },
}))

export const Logo = () => {
  const classes = useStyles()
  return <div className={classes.img} title={`v${process.env.REACT_APP_VERSION}`} />
}
