import React from 'react'
import { Slider, Theme, Typography, withStyles, makeStyles } from '@material-ui/core'

import { ToolbarItem } from './ToolbarItem'
import { Videocam, VideocamOff } from '@material-ui/icons'

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    display: 'grid',
    gridTemplateRows: 'auto auto auto',
    justifyContent: 'center',
    textAlign: 'center',
  },
  contrastLabel: {
    margin: theme.spacing(2, 0, 0, 1),
    textAlign: 'left',
  },
  contrastSlider: {
    width: 150,
    margin: theme.spacing(0.5, 1, 1, 1),
  },
}))

export interface VideoControlProps {
  showVideo: boolean
  onToggleShowVideo: (event: React.MouseEvent, checked: boolean) => void
  contrast: number
  onContrastChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void
}

export const VideoControl = ({ showVideo, onToggleShowVideo, contrast, onContrastChange }: VideoControlProps) => {
  const classes = useStyles()

  return (
    <ToolbarItem
      active={showVideo}
      disabled={false}
      content={showVideo ? <Videocam /> : <VideocamOff />}
      onClick={(e) => {
        onToggleShowVideo(e, !showVideo)
      }}
      tooltip={
        <div className={classes.tooltip}>
          <Typography variant="inherit">{showVideo ? 'Hide Video' : 'Show Video'}</Typography>
          {showVideo && (
            <>
              <Typography variant="caption" className={classes.contrastLabel}>
                Contrast
              </Typography>
              <CustomSlider value={contrast * 100} onChange={onContrastChange} className={classes.contrastSlider} />
            </>
          )}
        </div>
      }
    />
  )
}

const CustomSlider = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  thumb: {
    backgroundColor: theme.palette.text.primary,
    '&:focus, &:hover, &.active': {
      boxShadow: 'inherit',
    },
  },
}))(Slider)
