import { BedSettings, SignalSettings } from './model'
import { defaultBedSettings, defaultSignalSettings } from './defaults'
import { useCockpitState } from '../store/cockpit/useCockpitState'

import { BedId } from '../beds/model'
import { SignalId } from '../signals/model'

export const getBedSettings = (state: ReadonlyMap<BedId, BedSettings>, bedId: BedId) =>
  state.get(bedId) ?? defaultBedSettings

export const getSignalSettings = (bedSettings: BedSettings, signalId: SignalId): SignalSettings =>
  bedSettings.signalSettings.get(signalId) ?? defaultSignalSettings

export const useBedSettings = (bedId: BedId) =>
  useCockpitState<BedSettings>((s) => getBedSettings(s.ui.activeUser.settings.bedSettings, bedId))
