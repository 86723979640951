import { Dispatch } from 'redux'

import {
  ERROR_TIMELINES,
  FETCH_TIMELINES,
  RECEIVE_TIMELINES,
  RESET_CURSOR_POSITION,
  RESET_TIME_RANGE,
  SET_CURSOR_POSITION,
  SET_SELECTED_TIMELINES,
  SET_TIMELINES_ANIMATE,
  SET_TIME_RANGE,
  SET_TIMELINE_CONFIG_YSCALE,
  RESET_TIMELINE_CONFIG_YSCALE,
} from './actionTypes'
import { Domain } from 'react-svg-timeline'
import { Timeline, CursorPosition, TimelineScreenId } from './model'
import { BedId } from '../../beds/model'
import { getCredentials } from '../../shared/credentials'
import { YScale } from '../../signals/model'
import { TimelineId } from '../../timeline/model'

export type TimelinesScreenAction =
  | SetTimeRangeAction
  | ResetTimeRangeAction
  | FetchTimelinesAction
  | ReceiveTimelinesAction
  | ErrorTimelinesAction
  | SetSelectedTimelinesAction
  | SetTimelinesAnimateAction
  | SetCursorPositionAction
  | ResetCursorPositionAction
  | SetTimelineConfigYScaleAction
  | ResetTimelineConfigYScaleAction

interface SetTimeRangeAction {
  type: typeof SET_TIME_RANGE
  payload: Readonly<{ timelineScreenId: TimelineScreenId; timeRange: Domain }>
}

export const setTimeRange = (timelineScreenId: TimelineScreenId, timeRange: Domain): SetTimeRangeAction => ({
  type: SET_TIME_RANGE,
  payload: {
    timelineScreenId,
    timeRange,
  },
})

interface ResetTimeRangeAction {
  type: typeof RESET_TIME_RANGE
  payload: Readonly<{ timelineScreenId: TimelineScreenId }>
}

export const resetTimeRange = (timelineScreenId: TimelineScreenId): ResetTimeRangeAction => ({
  type: RESET_TIME_RANGE,
  payload: {
    timelineScreenId,
  },
})

interface SetTimelinesAnimateAction {
  type: typeof SET_TIMELINES_ANIMATE
  payload: Readonly<{ timelineScreenId: TimelineScreenId; animate: boolean }>
}

export const setTimelinesAnimate = (
  timelineScreenId: TimelineScreenId,
  animate: boolean
): SetTimelinesAnimateAction => ({
  type: SET_TIMELINES_ANIMATE,
  payload: {
    timelineScreenId,
    animate,
  },
})

interface FetchTimelinesAction {
  type: typeof FETCH_TIMELINES
  payload: Readonly<{ timelineScreenId: TimelineScreenId }>
}

export const createFetchTimelinesAction = (timelineScreenId: TimelineScreenId): FetchTimelinesAction => ({
  type: FETCH_TIMELINES,
  payload: {
    timelineScreenId,
  },
})

export interface ReceiveTimelinesAction {
  type: typeof RECEIVE_TIMELINES
  payload: Readonly<{ timelineScreenId: TimelineScreenId; availableTimelines: ReadonlyArray<Timeline> }>
}

export const createReceiveTimelinesAction = (
  timelineScreenId: TimelineScreenId,
  availableTimelines: ReadonlyArray<Timeline>
): ReceiveTimelinesAction => ({
  type: RECEIVE_TIMELINES,
  payload: {
    timelineScreenId,
    availableTimelines,
  },
})

interface ErrorTimelinesAction {
  type: typeof ERROR_TIMELINES
  payload: Readonly<{ timelineScreenId: TimelineScreenId; error: string }>
}

export const createErrorTimelinesAction = (
  timelineScreenId: TimelineScreenId,
  error: string
): ErrorTimelinesAction => ({
  type: ERROR_TIMELINES,
  payload: {
    timelineScreenId,
    error,
  },
})

const restPath = process.env.REACT_APP_REST_PATH ?? ''

export const fetchTimelines = (bedId: BedId, timelineScreenId: TimelineScreenId) => {
  return async (dispatch: Dispatch<FetchTimelinesAction | ReceiveTimelinesAction | ErrorTimelinesAction>) => {
    dispatch(createFetchTimelinesAction(timelineScreenId))

    const requestInit = getCredentials()

    return fetch(`${restPath}/timelines/${bedId}`, requestInit)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Received invalid response')
        }
      })
      .then((fetchedTimelines: ReadonlyArray<Timeline>) => {
        // TODO: Some basic data checks
        dispatch(createReceiveTimelinesAction(timelineScreenId, fetchedTimelines))
      })
      .catch((e) => {
        const errorMsg = `Fetching timelines for bed ${timelineScreenId} failed`
        console.error(errorMsg, e)
        dispatch(createErrorTimelinesAction(timelineScreenId, errorMsg))
      })
  }
}

interface SetSelectedTimelinesAction {
  type: typeof SET_SELECTED_TIMELINES
  payload: Readonly<{ timelineScreenId: TimelineScreenId; selectedTimelines: ReadonlyArray<TimelineId> }>
}

export const setSelectedTimelines = (
  timelineScreenId: TimelineScreenId,
  selectedTimelines: ReadonlyArray<TimelineId>
): SetSelectedTimelinesAction => ({
  type: SET_SELECTED_TIMELINES,
  payload: {
    timelineScreenId,
    selectedTimelines,
  },
})

interface SetCursorPositionAction {
  type: typeof SET_CURSOR_POSITION
  payload: Readonly<{ timelineScreenId: TimelineScreenId; cursorPosition: CursorPosition }>
}

export const setCursorPosition = (
  timelineScreenId: TimelineScreenId,
  cursorPosition: CursorPosition
): SetCursorPositionAction => ({
  type: SET_CURSOR_POSITION,
  payload: {
    timelineScreenId,
    cursorPosition,
  },
})

interface ResetCursorPositionAction {
  type: typeof RESET_CURSOR_POSITION
  payload: Readonly<{ timelineScreenId: TimelineScreenId }>
}

export const resetCursorPosition = (timelineScreenId: TimelineScreenId): ResetCursorPositionAction => ({
  type: RESET_CURSOR_POSITION,
  payload: {
    timelineScreenId,
  },
})

interface SetTimelineConfigYScaleAction {
  type: typeof SET_TIMELINE_CONFIG_YSCALE
  payload: Readonly<{ timelineScreenId: TimelineScreenId; timelineId: TimelineId; customYScale: YScale }>
}

export const setTimelineConfigYScale = (
  timelineScreenId: TimelineScreenId,
  timelineId: TimelineId,
  customYScale: YScale
): SetTimelineConfigYScaleAction => ({
  type: SET_TIMELINE_CONFIG_YSCALE,
  payload: {
    timelineScreenId,
    timelineId,
    customYScale,
  },
})

interface ResetTimelineConfigYScaleAction {
  type: typeof RESET_TIMELINE_CONFIG_YSCALE
  payload: Readonly<{ timelineScreenId: TimelineScreenId; timelineId: TimelineId }>
}

export const resetTimelineConfigYScale = (
  timelineScreenId: TimelineScreenId,
  timelineId: TimelineId
): ResetTimelineConfigYScaleAction => ({
  type: RESET_TIMELINE_CONFIG_YSCALE,
  payload: {
    timelineScreenId,
    timelineId,
  },
})
