import { BedId } from '../beds/model'
import { delayUninitialized, XAxisOffsetCorrection } from '../users/model'
import { defaultXAxisOffsetCorrection } from '../users/defaults'
import { useCockpitState } from '../store/cockpit/useCockpitState'
import { CockpitState } from '../store/cockpit/model'

/**
 * Returns the maximum network delay for a beds signals
 */
export const useXAxisOffsetMillis = (bedId: BedId) =>
  useCockpitState<number>(
    (s: CockpitState) =>
      s.ui.activeUser.settings.bedSettings.get(bedId)?.xAxisOffsetCorrection.currentDelay ?? delayUninitialized
  )

export const useXAxisOffsetCorrection = (bedId: BedId) =>
  useCockpitState<XAxisOffsetCorrection>(
    (s: CockpitState) =>
      s.ui.activeUser.settings.bedSettings.get(bedId)?.xAxisOffsetCorrection ?? defaultXAxisOffsetCorrection
  )
