import React, { useCallback } from 'react'
import { IsolationTile as IsolationTileComponent } from '../../components/tiles/IsolationTile'
import { useCockpitDispatch } from '../../../store/cockpit/useCockpitDispatch'
import { BedIsolationEditDialog } from '../BedIsolationEditDialog'
import { useRoutedBed } from '../../../screens/selectors'
import { switchToEditInProgressMode } from '../../../screens/overview/actions'

/**
 * Intended for detail screen toolbar, hence inclusion of edit dialog (on
 * overview screen, edit dialog is already embedded in bed card).
 */
export const IsolationTile = () => {
  const bed = useRoutedBed()

  const dispatch = useCockpitDispatch()
  const onClick = useCallback(() => dispatch(switchToEditInProgressMode(bed.id)), [dispatch, bed.id])

  return (
    <div>
      <IsolationTileComponent
        bedId={bed.id}
        bedIsolation={bed.isolationState}
        bedIsolationTimestamp={bed.isolationTimestamp}
        onClick={onClick}
        variant={'toolbar'}
      />
      <BedIsolationEditDialog bed={bed} />
    </div>
  )
}
