import * as React from 'react'
import Card from '@material-ui/core/Card/Card'
import { CardActionArea, CardContent, Theme, Typography } from '@material-ui/core'
import { bedCardStyle } from './shared'
import { makeStyles } from '@material-ui/core/styles'
import { BedIcon } from './BedIcon'
import { InfoTiles } from './tiles/InfoTiles'
import { BedIsolationEditDialog } from '../containers/BedIsolationEditDialog'
import { Bed, BedId, BedIdNone, BedIsolation } from '../model'

const useStyles = makeStyles((theme: Theme) => ({
  card: ({
    isOccupied,
    showBedAlarm,
  }: {
    isOccupied: boolean
    isPinned: boolean
    isHovered: boolean
    showBedAlarm: boolean
  }) => ({
    ...bedCardStyle,
    color: isOccupied ? theme.palette.text.primary : theme.palette.text.disabled,
    backgroundColor: isOccupied
      ? showBedAlarm
        ? theme.palette.secondary.main
        : theme.palette.background.paper
      : theme.palette.background.default,
  }),
  cardActionArea: ({ isOccupied, isPinned }: { isOccupied: boolean; isPinned: boolean; isHovered: boolean }) => ({
    height: '100%',
    boxShadow: isOccupied ? `0px 0px 0px 4px inset ${isPinned ? theme.palette.primary.light : 'transparent'}` : '',
  }),
  cardContent: {
    display: 'grid',
    gridTemplateRows: 'auto 1fr',
    alignItems: 'start',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(2),
    height: '100%',
  },
  title: {
    display: 'grid',
    height: '100%',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },
  vacantLabel: {
    fontStyle: 'italic',
    alignSelf: 'end',
  },
}))

export interface Props {
  readonly bed: Bed
  readonly isHovered: boolean
  readonly isPinned: boolean
  readonly onHoverBed: (bed: BedId) => void
  readonly onPinBed: (bed: BedId) => void
  readonly onStartIsolationStatusEdit: (bedId: BedId) => void
}

export const BedCard = ({ bed, isHovered, isPinned, onHoverBed, onPinBed, onStartIsolationStatusEdit }: Props) => {
  const isOccupied = bed.isolationState !== BedIsolation.Vacant
  const isActive = isHovered || isPinned
  const showBedAlarm = bed.showAlarm ?? false
  const classes = useStyles({ isOccupied, isPinned, isHovered, showBedAlarm })

  const onMouseEnter = () => onHoverBed(bed.id)
  const onMouseLeave = () => onHoverBed(BedIdNone)

  const onClick = () => onPinBed(isPinned ? BedIdNone : bed.id)

  return (
    <Card
      className={classes.card}
      raised={isActive}
      variant={isOccupied ? 'elevation' : 'outlined'}
      elevation={3}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <CardActionArea className={classes.cardActionArea} disableRipple component={'div'}>
        <CardContent className={classes.cardContent}>
          <Typography className={classes.title} variant="h5">
            {bed.label}
            {<BedIcon bed={bed} />}
          </Typography>
          {isOccupied ? (
            <OccupiedBed bed={bed} onStartIsolationStatusEdit={onStartIsolationStatusEdit} />
          ) : (
            <Typography className={classes.vacantLabel}>{'Vacant'}</Typography>
          )}
        </CardContent>
      </CardActionArea>
      <BedIsolationEditDialog bed={bed} />
    </Card>
  )
}

interface OccupiedBedProps {
  readonly bed: Bed
  readonly onStartIsolationStatusEdit: (bedId: BedId) => void
}

const OccupiedBed = ({ bed, onStartIsolationStatusEdit }: OccupiedBedProps) => (
  <InfoTiles bed={bed} onStartIsolationStatusEdit={onStartIsolationStatusEdit} />
)
