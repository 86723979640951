import { App as AppComponent } from '../components/App'
import * as React from 'react'
import { useEffect } from 'react'
import { connectLiveData, disposeLiveData, freeMemory } from '../../store/live/actions'
import { useSelectedSignalConfigs } from '../../screens/overview/selectors'
import { Theme } from '@material-ui/core'
import { useLocation } from 'react-router'
import { useXAxisOffsetMillis } from '../../signals/selectors'
import { refreshBeds } from '../../beds/actions'
import { useActiveBed } from '../../screens/selectors'
import { useInterval } from '../../shared/useInterval'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import { useCockpitState } from '../../store/cockpit/useCockpitState'
import { useLiveDataDispatch } from '../../store/live/useLiveDataDispatch'

export const App = () => {
  // handle bed information changes
  const cockpitDispatch = useCockpitDispatch()
  useInterval(() => {
    return cockpitDispatch(refreshBeds())
  }, 1000 * 60)

  // handle live data
  const bed = useActiveBed()
  const signalConfigs = useSelectedSignalConfigs(bed)
  const liveDataDispatch = useLiveDataDispatch()
  const xAxisOffsetMillis = useXAxisOffsetMillis(bed.id)

  useEffect(() => {
    liveDataDispatch(connectLiveData(signalConfigs))
  }, [liveDataDispatch, signalConfigs])

  useEffect(
    () => () => {
      // clean-up when app is closed
      liveDataDispatch(disposeLiveData())
    },
    [liveDataDispatch]
  )

  // discard values outside sliding window
  useInterval(() => {
    // Ensure we keep more than slidingWindowMs of data
    // in case of an xAxis offset
    liveDataDispatch(freeMemory(Date.now() - xAxisOffsetMillis))
  }, 1000)

  const theme = useCockpitState<Theme>((s) => s.ui.activeUser.settings.theme)

  const containsSharpVideoUrl = useCockpitState<boolean>((s) => {
    if (s.entities.beds.type === 'uninitialized-beds') {
      return false
    } else {
      return s.entities.beds.allBeds.filter((b) => b.camUrlSharp !== undefined).length > 0
    }
  })

  const location = useLocation()
  const activeView = location.pathname.indexOf('bed') >= 0 ? 'detail' : 'overview'

  return <AppComponent theme={theme} activeView={activeView} containsSharpVideoUrl={containsSharpVideoUrl} />
}
