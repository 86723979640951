import * as React from 'react'
import { useMemo } from 'react'
import { scaleBand, ScaleBand } from 'd3-scale'
import { Signal, SignalId, SignalNumberDisplay } from '../model'
import { DEFAULT_FRACTION_DIGITS, laneYScale, NUMBER_DISPLAY_BACKGROUND_OPACITY } from './shared'
import { DynamicWidthSvgText } from './DynamicWidthSvgText'
import { RGBA, toFillColor } from '../../shared/utils'

/* ·················································································································· */
/*  Types & Conversion Helpers
/* ·················································································································· */

// Performance Considerations: In cases where number displays are much less volatile than signal traces, we don't
// want this component to be re-rendered frequently --> don't rely on `Signal` type which contains heavy & volatile
// trace data, but use light-weight types which can be compared via JSON.stringify so the component can be memoized.

interface LaneData {
  readonly laneId: SignalId
  readonly laneColor: RGBA
  readonly numberDisplays: ReadonlyArray<SignalNumberDisplay>
}

export interface NumberDisplayParams {
  readonly lanes: ReadonlyArray<LaneData>
  readonly width: number
  readonly height: number
}

const getNumberDisplays = (signal: Signal): ReadonlyArray<SignalNumberDisplay> => {
  if (signal.numberDisplays.length > 0) {
    return signal.numberDisplays
  } else {
    // Default to trace if no specific number displays are configured
    const values = signal.traceDisplay.values
    const value = values.length > 0 ? values[values.length - 1].value : NaN
    return [
      {
        label: signal.label,
        unit: signal.unit,
        fractionDigits: signal.fractionDigits,
        streamUrl: signal.traceDisplay.streamUrl,
        value,
      },
    ]
  }
}

export const toLaneData = (signals: ReadonlyArray<Signal>): ReadonlyArray<LaneData> =>
  signals.map((s) => ({
    laneId: s._id,
    laneColor: s.color,
    numberDisplays: getNumberDisplays(s),
  }))

/* ·················································································································· */
/*  Main NumberDisplay Component
/* ·················································································································· */

type Props = NumberDisplayParams &
  Readonly<{
    dividerPosition: number
  }>

export const NumberDisplay = (props: Props) => {
  const comparableProps = JSON.stringify(props)
  return useMemo(() => {
    const { lanes, width, height } = props
    const yScale = laneYScale(
      lanes.map((l) => l.laneId),
      height
    )
    return (
      <svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
        <NumberDisplayContents yScale={yScale} {...props} />
        <Grid yScale={yScale} width={width} />
      </svg>
    )
    // use string representation as basis for memoization
    // eslint-disable-next-line
  }, [comparableProps])
}

/* ·················································································································· */
/*  Grid                                                                                                              */
/* ·················································································································· */

type GridProps = Readonly<{
  yScale: ScaleBand<SignalId>
  width: number
}>

const GRID_COLOR = 'grey'

const Grid = ({ yScale, width }: GridProps) => {
  const gridPositions = computeGridPositions(yScale)
  return (
    <g>
      {gridPositions.map((p) => {
        return <line key={p.toString()} x1={0} y1={p} x2={width} y2={p} stroke={GRID_COLOR} />
      })}
    </g>
  )
}

function computeGridPositions(scale: ScaleBand<SignalId>): number[] {
  // paddingInner is in percent of the step size (not the bandwidth or the range!)
  return scale.domain().flatMap((key, i) => (i === 0 ? [] : [scale(key)! - (scale.paddingInner() * scale.step()) / 2]))
}

/* ·················································································································· */
/*  NumberDisplayContents
/* ·················································································································· */

type NumberDisplayContentsProps = NumberDisplayParams &
  Readonly<{
    yScale: ScaleBand<SignalId>
    dividerPosition: number
  }>

const NumberDisplayContents = ({ yScale, dividerPosition, ...parameters }: NumberDisplayContentsProps) => {
  const xOffset = dividerPosition * parameters.width
  const width = parameters.width - xOffset

  return (
    <g>
      {parameters.lanes.map((lane) => {
        const numberDisplays = lane.numberDisplays
        const yOffset = yScale(lane.laneId) ?? 0
        const fill = toFillColor(lane.laneColor)
        const fillOpacity = lane.laneColor[3]

        return (
          <g key={lane.laneId}>
            <SignalNumberDisplayPanel
              numberDisplays={numberDisplays}
              x={xOffset}
              y={yOffset}
              width={width}
              height={yScale.bandwidth()}
              fill={fill}
              fillOpacity={fillOpacity}
            />
          </g>
        )
      })}
    </g>
  )
}

/* ·················································································································· */
/*  SignalNumberDisplayPanel
/* ·················································································································· */

type SignalNumberDisplayProps = Readonly<{
  numberDisplays: ReadonlyArray<SignalNumberDisplay>
  x: number
  y: number
  width: number
  height: number
  fill: string
  fillOpacity: number
}>

const SignalNumberDisplayPanel = ({
  numberDisplays,
  x,
  y,
  width,
  height,
  fill,
  fillOpacity,
}: SignalNumberDisplayProps) => {
  const yScale = scaleBand()
    .domain(numberDisplays.map((i) => i.streamUrl))
    .range([y, y + height])
    .paddingInner(numberDisplays.length > 1 ? 0.1 : 0)

  const xTextOffset = 0.05 * width
  const yTextOffset = 0.08 * yScale.bandwidth()

  const smallerDimension = Math.min(yScale.bandwidth(), width)
  const labelFontSize = 0.3 * smallerDimension
  const valueFontSize = 0.4 * smallerDimension
  const unitFontSize = 0.2 * smallerDimension

  return (
    <g>
      {numberDisplays.map((nd, index) => {
        const value = isNaN(nd.value) ? '–' : nd.value.toFixed(nd.fractionDigits ?? DEFAULT_FRACTION_DIGITS)
        const maxWidth = width - 2 * xTextOffset
        const y = yScale(nd.streamUrl) ?? 0
        const yLabel = y + yTextOffset
        const yValue = y + yScale.bandwidth() - yTextOffset
        const yUnit = yValue - valueFontSize
        return (
          <g key={index}>
            <rect
              x={x}
              y={y}
              width={width}
              height={yScale.bandwidth()}
              fill={fill}
              fillOpacity={NUMBER_DISPLAY_BACKGROUND_OPACITY}
            />
            <DynamicWidthSvgText
              x={x + xTextOffset}
              y={yLabel}
              fontSize={labelFontSize}
              dominantBaseline={'hanging'}
              fill={fill}
              fillOpacity={fillOpacity}
              maxWidth={maxWidth}
            >
              {nd.label}
            </DynamicWidthSvgText>
            {nd.unit && (
              <DynamicWidthSvgText
                x={x + width - xTextOffset}
                y={yUnit}
                fontSize={unitFontSize}
                textAnchor={'end'}
                dominantBaseline={'auto'}
                fill={fill}
                fillOpacity={fillOpacity}
                maxWidth={maxWidth}
              >
                {nd.unit}
              </DynamicWidthSvgText>
            )}
            <DynamicWidthSvgText
              x={x + width - xTextOffset}
              y={yValue}
              fontSize={valueFontSize}
              textAnchor={'end'}
              dominantBaseline={'auto'}
              fill={fill}
              fillOpacity={fillOpacity}
              maxWidth={maxWidth}
            >
              {value}
            </DynamicWidthSvgText>
          </g>
        )
      })}
    </g>
  )
}
