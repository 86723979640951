import { Dispatch, Middleware } from 'redux'
import { LiveDataState } from './model'
import { addSocket, addValue, LiveDataAction, removeSocket } from './actions'
import { CONNECT_LIVE_DATA, DISPOSE_LIVE_DATA } from './actionTypes'
import { TimestampedValue } from '../../signals/model'

export const liveDataMiddleware: Middleware<{}, LiveDataState> =
  (store) => (next: Dispatch<LiveDataAction>) => (action: LiveDataAction) => {
    const disposeSocket = (ws: WebSocket) => {
      ws.close()
      store.dispatch(removeSocket(ws.url))
    }

    if (action.type === CONNECT_LIVE_DATA) {
      // gather all web socket URLs which are in use by any of the signal configurations
      const streamUrls = action.signalConfigs.flatMap((sc) => {
        return [sc.traceDisplay.streamUrl, ...(sc.numberDisplays ?? []).map((nd) => nd.streamUrl)]
      })

      streamUrls.forEach((streamUrl) => {
        if (!store.getState().sockets.find((ws) => ws.url === streamUrl)) {
          createAndRegisterSocket(streamUrl, store.dispatch)
        }
      })

      // dispose sockets (and their data) which are no longer in use
      const outdatedSockets = store.getState().sockets.filter((ws) => streamUrls.findIndex((v) => v === ws.url) < 0)
      outdatedSockets.forEach(disposeSocket)
    } else if (action.type === DISPOSE_LIVE_DATA) {
      store.getState().sockets.forEach(disposeSocket)
    }

    return next(action)
  }

const createAndRegisterSocket = (streamUrl: string, dispatch: Dispatch<LiveDataAction>) => {
  const ws = new WebSocket(streamUrl)
  dispatch(addSocket(ws))

  ws.binaryType = 'arraybuffer'

  ws.onopen = () => {
    console.debug(`[onopen] ${streamUrl} connected`)
  }

  ws.onmessage = (messageEvent: MessageEvent) => {
    const dataView = new DataView(messageEvent.data)
    const timestamp = dataView.getFloat64(0)

    const value: TimestampedValue = {
      timestamp,
      delay: Date.now() - timestamp,
      value: dataView.getFloat64(8),
    }

    dispatch(addValue(streamUrl, value))
  }

  ws.onerror = (error) => console.error('[onerror] WS error ' + error)

  ws.onclose = (event) => console.debug(`[onclose] WS connection closed (code: ${event.code}, reason: ${event.reason})`)
}
