import * as React from 'react'
import { useEffect, useState } from 'react'
import { makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  img: {
    objectFit: 'contain',
    width: '100%',
    height: '100%'
  }
}))

interface Props {
  source: string
}

export const LiveVideoStream = ({ source }: Props) => {
  const classes = useStyles()

  const [imgElement, setImgElement] = useState<HTMLImageElement>()

  const imgRef = (imgElement: HTMLImageElement) => setImgElement(imgElement)

  useEffect(() => {
    return () => {
      if (imgElement) {
        // prevent video from streaming in the background
        imgElement.src = ''
      }
    }
  }, [imgElement])

  return (
    <div className={classes.root}>
      <img ref={imgRef} className={classes.img} src={source} alt="Live Video" />
    </div>
  )
}
