import { Dispatch, Middleware } from 'redux'

import { DciAction, initDciBed } from './actions'
import { FETCH_HEATMAP, FETCH_TIMELINE, FETCH_WATERFALL } from './actionTypes'
import { CockpitState } from '../store/cockpit/model'

export const initDciBedMiddleware: Middleware<{}, CockpitState> =
  (store) => (next: Dispatch<DciAction>) => (action: DciAction) => {
    switch (action.type) {
      case FETCH_TIMELINE:
      case FETCH_WATERFALL:
      case FETCH_HEATMAP:
        const state = store.getState()

        if (!state.dci.has(action.payload.bedId)) {
          store.dispatch(initDciBed(action.payload.bedId))
        }
    }

    next(action)
  }
