import { SignalTraceDisplay, TimestampedValue } from '../model'
import { ScaleLinear } from 'd3-scale'
import { findSlidingTimeWindowEndIndex, findSlidingTimeWindowStartIndex, SIGNAL_TRACE_LINE_WIDTH } from './shared'
import { RGBA } from '../../shared/utils'

export interface RenderingInfo {
  readonly canvas: HTMLCanvasElement
  readonly gl: WebGLRenderingContext
  readonly glBuffer?: WebGLBuffer
  readonly shaders: WebGLProgram
}

export function drawTrace(
  signalTrace: SignalTraceDisplay,
  scaleX: ScaleLinear<number, number>,
  scaleY: ScaleLinear<number, number>,
  renderingInfo: RenderingInfo,
  color: RGBA,
  now: number
): void {
  if (signalTrace.values.length > 1) {
    const vertexArray: Float32Array = createVerticesPolyLine(
      signalTrace,
      scaleX,
      scaleY,
      renderingInfo.gl,
      SIGNAL_TRACE_LINE_WIDTH,
      now
    )

    if (vertexArray.length > 0) {
      drawSignalPolyLine(vertexArray, renderingInfo.gl, renderingInfo.glBuffer, renderingInfo.shaders, color)
    }
  }
}

function createVerticesPolyLine(
  signalTraceDisplay: SignalTraceDisplay,
  scaleX: ScaleLinear<number, number>,
  scaleY: ScaleLinear<number, number>,
  gl: WebGLRenderingContext,
  thickness: number,
  now: number
): Float32Array {
  // Compute the screen coordinates (in pixels) of the data point values
  // We use a flat array for performance reasons (avoid creation of intermediary arrays)
  // [ x1,y1, x2,y2 , ...]

  const values = signalTraceDisplay.values

  const startIndex = findSlidingTimeWindowStartIndex(values, now)
  const endIndex = findSlidingTimeWindowEndIndex(values, now)

  const valueCount = endIndex > startIndex ? endIndex - startIndex : 0
  const valuesCoordinates = new Float32Array(valueCount * 2)

  for (let i = startIndex; i < endIndex; i++) {
    const point = worldToScreen(values[i], scaleX, scaleY)
    const offset = (i - startIndex) * 2
    valuesCoordinates[offset + 0] = point[0]
    valuesCoordinates[offset + 1] = point[1]
  }

  // Compute the triangles for the line segments

  // We use a flat array for performance reasons
  // [ T1_x1,T1_y1, T1_x2,T1_y2, T1_x3,T1_y3,   T2_x1,T2_y1, T2_x2,T2_y2, T2_x3,T2_y3,   ...]

  const shift = thickness / 2

  let prev_segment_points: number[][] = []

  // 3 triangles per line segment, 3 vertices per triangle, 2 coordinates per vertex
  const lineSegmentCount = Math.max(valueCount - 1, 0)
  const coordinateCount = lineSegmentCount * 3 * 3 * 2
  const triangleCoordinates = new Float32Array(coordinateCount)

  const width = gl.canvas.width
  const height = gl.canvas.height

  for (let i = 0; i < lineSegmentCount; i++) {
    const pointOffset = i * 2

    // Create a vector from the start to the end point of the line segment
    const vector = createVectorLinear(
      valuesCoordinates[pointOffset + 0],
      valuesCoordinates[pointOffset + 1],
      valuesCoordinates[pointOffset + 2],
      valuesCoordinates[pointOffset + 3]
    )

    // Compute the normal vector for the line segment
    const normal = computeNormal(vector)

    // Compute how much to shift the points of the line segment rectangle
    // away from the center line for a desired line width
    const dx = normal[0] * shift
    const dy = normal[1] * shift

    // Shift the points by that amount
    const p1 = [valuesCoordinates[pointOffset + 0] - dx, valuesCoordinates[pointOffset + 1] - dy]
    const p2 = [valuesCoordinates[pointOffset + 0] + dx, valuesCoordinates[pointOffset + 1] + dy]
    const p3 = [valuesCoordinates[pointOffset + 2] - dx, valuesCoordinates[pointOffset + 3] - dy]
    const p4 = [valuesCoordinates[pointOffset + 2] + dx, valuesCoordinates[pointOffset + 3] + dy]

    // Create two triangles to draw the line segment rectangle

    const triangleOffset = i * (3 * 3 * 2)

    // triangle1 = [p1, p4, p2]
    triangleCoordinates[triangleOffset + 0] = screenToRenderer(p1[0], width)
    triangleCoordinates[triangleOffset + 1] = screenToRenderer(p1[1], height)
    triangleCoordinates[triangleOffset + 2] = screenToRenderer(p4[0], width)
    triangleCoordinates[triangleOffset + 3] = screenToRenderer(p4[1], height)
    triangleCoordinates[triangleOffset + 4] = screenToRenderer(p2[0], width)
    triangleCoordinates[triangleOffset + 5] = screenToRenderer(p2[1], height)

    // triangle2 = [p1, p3, p4]
    triangleCoordinates[triangleOffset + 6] = screenToRenderer(p1[0], width)
    triangleCoordinates[triangleOffset + 7] = screenToRenderer(p1[1], height)
    triangleCoordinates[triangleOffset + 8] = screenToRenderer(p3[0], width)
    triangleCoordinates[triangleOffset + 9] = screenToRenderer(p3[1], height)
    triangleCoordinates[triangleOffset + 10] = screenToRenderer(p4[0], width)
    triangleCoordinates[triangleOffset + 11] = screenToRenderer(p4[1], height)

    // Create an additional triangle to fill the gap that opens up above or below a line joint
    // depending on if the line bends up or down at this joint

    if (i > 0) {
      if (prev_segment_points[3][0] < p2[0]) {
        // triangle = [lineSegment[0], p2, prev_segment_points[3]]
        triangleCoordinates[triangleOffset + 12] = screenToRenderer(valuesCoordinates[pointOffset + 0], width)
        triangleCoordinates[triangleOffset + 13] = screenToRenderer(valuesCoordinates[pointOffset + 1], height)
        triangleCoordinates[triangleOffset + 14] = screenToRenderer(p2[0], width)
        triangleCoordinates[triangleOffset + 15] = screenToRenderer(p2[1], height)
        triangleCoordinates[triangleOffset + 16] = screenToRenderer(prev_segment_points[3][0], width)
        triangleCoordinates[triangleOffset + 17] = screenToRenderer(prev_segment_points[3][1], height)
      } else if (prev_segment_points[3][0] > p2[0]) {
        // triangle = [lineSegment[0], prev_segment_points[2], p1]
        triangleCoordinates[triangleOffset + 12] = screenToRenderer(valuesCoordinates[pointOffset + 0], width)
        triangleCoordinates[triangleOffset + 13] = screenToRenderer(valuesCoordinates[pointOffset + 1], height)
        triangleCoordinates[triangleOffset + 14] = screenToRenderer(prev_segment_points[2][0], width)
        triangleCoordinates[triangleOffset + 15] = screenToRenderer(prev_segment_points[2][1], height)
        triangleCoordinates[triangleOffset + 16] = screenToRenderer(p1[0], width)
        triangleCoordinates[triangleOffset + 17] = screenToRenderer(p1[1], height)
      }
    }

    prev_segment_points = [p1, p2, p3, p4]
  }

  return triangleCoordinates
}

function worldToScreen(
  timestampedValue: TimestampedValue,
  scaleX: ScaleLinear<number, number>,
  scaleY: ScaleLinear<number, number>
): number[] {
  const x = scaleX(timestampedValue.timestamp)
  const y = scaleY(timestampedValue.value)
  return [x, y]
}

// This can potentially be moved to the GPU
function screenToRenderer(screenCoordinate: number, screenSize: number): number {
  return (screenCoordinate / (screenSize - 1)) * 2 - 1
}

function createVectorLinear(p0x: number, p0y: number, p1x: number, p1y: number): number[] {
  return [p1x - p0x, p1y - p0y]
}

function computeNormal(vector: number[]): number[] {
  const length = Math.sqrt(vector[0] * vector[0] + vector[1] * vector[1])
  return [-vector[1] / length, vector[0] / length]
}

function drawSignalPolyLine(
  vertexPositions: Float32Array,
  gl: WebGLRenderingContext,
  glBuffer: WebGLBuffer | undefined,
  shaders: WebGLProgram,
  color: RGBA
): void {
  if (glBuffer) {
    // Copy the data in the Javascript array to the WebGL buffer currently bound
    gl.bufferData(gl.ARRAY_BUFFER, vertexPositions, gl.STATIC_DRAW)

    // Query the location index of the position attribute in the vertex shader
    const positionAttributeLocation = gl.getAttribLocation(shaders, 'position')
    // Enable the attribute array in the shader at the position of the attribute variable.
    gl.enableVertexAttribArray(positionAttributeLocation)
    // Describe and associate the buffer currently bound with the attribute array in the shader
    gl.vertexAttribPointer(
      positionAttributeLocation,
      2, // 2 values per vertex shader iteration
      gl.FLOAT, // data is 32bit floats
      false, // don't normalize
      0, // stride (0 = auto)
      0 // offset into buffer
    )

    const colorUniformLocation = gl.getUniformLocation(shaders, 'u_color')
    gl.uniform4fv(colorUniformLocation, color)

    gl.drawArrays(gl.TRIANGLES, 0, vertexPositions.length / 2)
  } else {
    console.warn('No WebGL Buffer available!')
  }
}

export function createShaders(gl: WebGLRenderingContext): WebGLProgram {
  // language=GLSL
  const vsGLSL: string = `
    attribute vec4 position;
    void main() {
      gl_Position = position;
    }
  `

  // language=GLSL
  const fsGLSL: string = `
    precision highp float;

    uniform vec4 u_color;
    
    void main() {
      gl_FragColor = u_color;
//      gl_FragColor = vec4(0, 0.7, 0.9, 1);
    }
  `

  const vertexShader: WebGLShader = gl.createShader(gl.VERTEX_SHADER)!
  gl.shaderSource(vertexShader, vsGLSL)
  gl.compileShader(vertexShader)
  if (!gl.getShaderParameter(vertexShader, gl.COMPILE_STATUS)) {
    throw new Error(gl.getShaderInfoLog(vertexShader)!)
  }

  const fragmentShader: WebGLShader = gl.createShader(gl.FRAGMENT_SHADER)!
  gl.shaderSource(fragmentShader, fsGLSL)
  gl.compileShader(fragmentShader)
  if (!gl.getShaderParameter(fragmentShader, gl.COMPILE_STATUS)) {
    throw new Error(gl.getShaderInfoLog(fragmentShader)!)
  }

  const webGLProgram: WebGLProgram = gl.createProgram()!
  gl.attachShader(webGLProgram, vertexShader)
  gl.attachShader(webGLProgram, fragmentShader)
  gl.linkProgram(webGLProgram)
  if (!gl.getProgramParameter(webGLProgram, gl.LINK_STATUS)) {
    throw new Error(gl.getProgramParameter(webGLProgram, gl.LINK_STATUS)!)
  }

  gl.detachShader(webGLProgram, vertexShader)
  gl.deleteShader(vertexShader)
  gl.detachShader(webGLProgram, fragmentShader)
  gl.deleteShader(fragmentShader)

  return webGLProgram
}

export function resizeCanvas(canvas: HTMLCanvasElement): void {
  // Lookup the size at which the browser is displaying the canvas.
  const dpr = window.devicePixelRatio || 1

  const displayWidth = canvas.clientWidth * dpr
  const displayHeight = canvas.clientHeight * dpr

  // Check if the canvas is not the same size.
  if (canvas.width !== displayWidth || canvas.height !== displayHeight) {
    // Make the canvas the same size
    canvas.width = displayWidth
    canvas.height = displayHeight
  }
}
