import { INIT_TIMELINE, ERROR_TIMELINE, FETCH_TIMELINE, RECEIVE_TIMELINE } from './actionTypes'
import { Dispatch } from 'redux'
import { TimelineId } from './model'
import { TimelineSeries } from './components/model'
import { TimelineScreenId } from '../screens/timelines/model'
import { BedId } from '../beds/model'
import { getCredentials } from '../shared/credentials'

export type TimelineAction = InitTimelineAction | FetchTimelineAction | ReceiveTimelineAction | ErrorTimelineAction

interface InitTimelineAction {
  type: typeof INIT_TIMELINE
  payload: Readonly<{ timelineScreenId: TimelineScreenId; timelineId: TimelineId }>
}

export const initTimeline = (timelineScreenId: TimelineScreenId, timelineId: TimelineId): InitTimelineAction => ({
  type: INIT_TIMELINE,
  payload: {
    timelineScreenId,
    timelineId,
  },
})

/* ·················································································································· */
/*  TIMELINE
/* ·················································································································· */

interface FetchTimelineAction {
  type: typeof FETCH_TIMELINE
  payload: Readonly<{ timelineScreenId: TimelineScreenId; timelineId: TimelineId }>
}

export const createFetchTimelineAction = (
  timelineScreenId: TimelineScreenId,
  timelineId: TimelineId
): FetchTimelineAction => ({
  type: FETCH_TIMELINE,
  payload: {
    timelineScreenId,
    timelineId,
  },
})

export interface ReceiveTimelineAction {
  type: typeof RECEIVE_TIMELINE
  payload: Readonly<{ timelineScreenId: TimelineScreenId; timelineId: TimelineId; timeline: TimelineSeries }>
}

export const createReceiveTimelineAction = (
  timelineScreenId: TimelineScreenId,
  timelineId: TimelineId,
  timeline: TimelineSeries
): ReceiveTimelineAction => ({
  type: RECEIVE_TIMELINE,
  payload: {
    timelineScreenId,
    timelineId,
    timeline,
  },
})

interface ErrorTimelineAction {
  type: typeof ERROR_TIMELINE
  payload: Readonly<{ timelineScreenId: TimelineScreenId; timelineId: TimelineId; error: string }>
}

export const createErrorTimelineAction = (
  timelineScreenId: TimelineScreenId,
  timelineId: TimelineId,
  error: string
): ErrorTimelineAction => ({
  type: ERROR_TIMELINE,
  payload: {
    timelineScreenId,
    timelineId,
    error,
  },
})

const restPath = process.env.REACT_APP_REST_PATH ?? ''

export const fetchTimeline = (
  bedId: BedId,
  timelineScreenId: TimelineScreenId,
  timelineId: TimelineId,
  from: number,
  to: number,
  minInterval: number
) => {
  return async (dispatch: Dispatch<FetchTimelineAction | ReceiveTimelineAction | ErrorTimelineAction>) => {
    dispatch(createFetchTimelineAction(timelineScreenId, timelineId))

    const requestInit = getCredentials()

    return fetch(
      `${restPath}/timelines/${bedId}/${timelineId}?from=${from}&to=${to}&minInterval=${minInterval}`,
      requestInit
    )
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Received invalid response')
        }
      })
      .then((fetchedTimeline: TimelineSeries) => {
        // TODO: Some basic data checks
        dispatch(createReceiveTimelineAction(timelineScreenId, timelineId, fetchedTimeline))
      })
      .catch((e) => {
        const errorMsg = 'Fetching timeline data failed'
        console.error(errorMsg, e)
        dispatch(createErrorTimelineAction(timelineScreenId, timelineId, errorMsg))
      })
  }
}
