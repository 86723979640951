import React from 'react'
import { Chip, createStyles, Divider, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { TimelineZoomFn } from '../Timeline'
import { TimelineGoControls } from './TimelineGoControls'
import { TimelineZoomButtons } from './TimelineZoomButtons'

export interface TimelineRangeControlProps {
  baseEndMillis: number
  startMillis: number
  endMillis: number
  minRange: number
  onRangeChange: TimelineZoomFn
}

const hourMillis = 1000 * 60 * 60

const DEFAULT_RANGES = [
  { label: '1h', tooltip: 'Last hour', millis: hourMillis },
  { label: '12h', tooltip: 'Last 12 hours', millis: 12 * hourMillis },
  { label: '1d', tooltip: 'Last 24 hours', millis: 24 * hourMillis },
  { label: '1w', tooltip: 'Last 7 days', millis: 7 * 24 * hourMillis },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    divider: {
      margin: theme.spacing(1, 1, 1, 2.5),
    },
  })
)

export const TimelineRangeControl = ({
  baseEndMillis,
  startMillis,
  endMillis,
  minRange,
  onRangeChange,
}: TimelineRangeControlProps) => {
  const classes = useStyles()

  const isActiveRange = (rangeMillis: number) => baseEndMillis === endMillis && endMillis - startMillis === rangeMillis

  return (
    <div className={classes.root}>
      {DEFAULT_RANGES.map((range) => (
        <Tooltip key={range.label} title={range.tooltip}>
          <span>
            <Chip
              label={range.label}
              onClick={() => {
                onRangeChange(baseEndMillis - range.millis, baseEndMillis)
              }}
              disabled={minRange > range.millis}
              color={isActiveRange(range.millis) ? 'primary' : undefined}
            />
          </span>
        </Tooltip>
      ))}
      {DEFAULT_RANGES.filter((range) => isActiveRange(range.millis)).length === 0 ? (
        <Chip label="Custom" color="primary" />
      ) : (
        <Chip label="Custom" disabled />
      )}
      <Divider flexItem orientation="vertical" className={classes.divider} />
      <TimelineGoControls
        baseEndMillis={baseEndMillis}
        startMillis={startMillis}
        endMillis={endMillis}
        onRangeChange={onRangeChange}
      />
      <Divider flexItem orientation="vertical" className={classes.divider} />
      <TimelineZoomButtons startMillis={startMillis} endMillis={endMillis} onRangeChange={onRangeChange} />
    </div>
  )
}
