import React, { ChangeEvent, useEffect, useState } from 'react'
import { YScale } from '../model'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputBaseComponentProps,
  Theme
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { default as NumberFormat, NumberFormatProps, NumberFormatValues } from 'react-number-format'
import FormHelperText from '@material-ui/core/FormHelperText'
import RestoreIcon from '@material-ui/icons/Restore'

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    minHeight: '20vh',
    display: 'grid',
    gridTemplateAreas: `'a b c' 'd d d'`,
    gridTemplateRows: '50% 50%',
    columnGap: theme.spacing(1)
  },
  // https://material-ui.com/components/text-fields/#CustomizedInputs.tsx
  textField: {
    '& label.Mui-focused': {
      color: theme.palette.text.primary
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.text.primary
    }
  }
}))

interface Props {
  readonly title: string
  readonly isOpen: boolean
  readonly originalYScale: YScale
  readonly defaultYScale: YScale
  readonly onCancel: () => void
  readonly onOk: (yScale: YScale) => void
}

export const YAxisConfigDialog = ({ title, isOpen, originalYScale, defaultYScale, onCancel, onOk }: Props) => {
  const classes = useStyles()

  const [yScale, setYScale] = useState<YScale>(originalYScale)

  useEffect(() => setYScale(originalYScale), [originalYScale])

  const isMinTextFieldValid = !isNaN(yScale.min)
  const isMaxTextFieldValid = !isNaN(yScale.max)
  const isMinMaxValid = yScale.min < yScale.max && isMinTextFieldValid && isMaxTextFieldValid
  const validationMsg = isMinMaxValid ? '' : 'Validation Error: Min must be smaller than Max'

  const onMinChange = (e: ChangeEvent<HTMLInputElement>) => {
    const min = parseFloat(e.target.value)
    setYScale(prevState => ({
      ...prevState,
      min
    }))
  }

  const onMaxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const max = parseFloat(e.target.value)
    setYScale(prevState => ({
      ...prevState,
      max
    }))
  }

  const onCancelClick = () => {
    setYScale(originalYScale)
    onCancel()
  }
  const onOkClick = () => onOk(yScale)

  const onRestoreClick = () => setYScale(defaultYScale)

  // prevent any clicks from bubbling up an re-triggering the icon button
  const onDialogClick = (e: React.MouseEvent) => e.stopPropagation()

  return (
    <Dialog open={isOpen} onClose={onCancel} fullWidth={true} onClick={onDialogClick}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form className={classes.form} noValidate={true} autoComplete="off">
          <TextField
            className={classes.textField}
            value={yScale.min}
            error={!isMinTextFieldValid}
            helperText={isMinTextFieldValid ? '' : 'Required'}
            label="Min"
            onChange={onMinChange}
            InputProps={{
              inputComponent: CustomNumberFormat as any
            }}
          />
          <TextField
            className={classes.textField}
            value={yScale.max}
            error={!isMaxTextFieldValid}
            helperText={isMaxTextFieldValid ? '' : 'Required'}
            label="Max"
            onChange={onMaxChange}
            InputProps={{
              inputComponent: CustomNumberFormat as any
            }}
          />
          <div>
            <IconButton onClick={onRestoreClick} title={'Restore Defaults'}>
              <RestoreIcon />
            </IconButton>
          </div>
          <FormHelperText error={!isMinMaxValid}>{validationMsg}</FormHelperText>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button onClick={onOkClick} disabled={!isMinMaxValid} color="primary" variant="contained">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const CustomNumberFormat = (props: InputBaseComponentProps & NumberFormatProps) => {
  const { onChange, inputRef, ...other } = props
  const onValueChange = (values: NumberFormatValues) => {
    if (onChange) {
      onChange({
        target: {
          value: values.value
        }
      } as ChangeEvent<HTMLInputElement>)
    }
  }
  return <NumberFormat decimalScale={3} getInputRef={inputRef} onValueChange={onValueChange} {...other} />
}
