import { DciState, DciStateValue, DciStatusUninitialized } from './model'
import { BedId } from '../beds/model'

export const defaultDciStatusUninitialized: DciStatusUninitialized = {
  status: 'uninitialized',
  isLoading: false,
  error: null,
}

export const defaultDciValue: DciStateValue = {
  timeline: defaultDciStatusUninitialized,
  waterfall: defaultDciStatusUninitialized,
  heatmap: defaultDciStatusUninitialized,
}

export const defaultDciState = (): DciState => new Map<BedId, DciStateValue>()
