import React, { useState } from 'react'

import { IconButton, makeStyles, Tooltip } from '@material-ui/core'
import SettingsIcon from '@material-ui/icons/Settings'
import { CustomLayerProps } from '@nivo/line'

const useStyles = makeStyles({
  settingsIconPane: {
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    backgroundColor: 'rgba(200,200,200,0.2)',
  },
})

export interface CustomYScaleLayerProps extends CustomLayerProps {
  onClick: () => void
}

export const CustomYScaleLayer = ({ innerHeight, margin, onClick }: CustomYScaleLayerProps) => {
  const classes = useStyles()

  const [hover, setHover] = useState(false)

  return (
    <foreignObject
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      x={-55}
      y={0 - (margin?.top ?? 0)}
      width={50}
      height={innerHeight + (margin?.top ?? 0) + (margin?.bottom ?? 0)}
    >
      {hover && (
        <div className={classes.settingsIconPane} style={{ visibility: hover ? 'visible' : 'hidden' }}>
          <IconButton onClick={onClick}>
            <Tooltip title={'Configure Y-Scale'}>
              <SettingsIcon />
            </Tooltip>
          </IconButton>
        </div>
      )}
    </foreignObject>
  )
}
