import TimelineIcon from '@material-ui/icons/Timeline'
import { Button, Tooltip } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import { BedId } from '../../../../beds/model'

interface Props {
  readonly bedId: BedId
}

export const TimelineScreenButton = ({ bedId }: Props) => (
  <Tooltip title={'Open timelines screen'}>
    <Button
      variant="outlined"
      endIcon={<TimelineIcon />}
      style={{ height: 40 }}
      component={Link}
      to={`/bed/${bedId}/timelines`}
    >
      {'Timelines'}
    </Button>
  </Tooltip>
)
