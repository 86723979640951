import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Loader } from '../../shared/components/Loader'

const useStyles = makeStyles(() => ({
  container: ({ width, height }: { width: number; height: number; backgroundColor?: string }) => ({
    width,
    height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
  }),
  background: ({ backgroundColor }: { width: number; height: number; backgroundColor?: string }) => ({
    width: '100%',
    height: '100%',
    backgroundColor,
    opacity: 0.5,
    position: 'absolute',
  }),
}))

export interface TimelineLoaderProps {
  width: number
  height: number
  backgroundColor?: string
}

export const TimelineLoader = ({ width, height, backgroundColor }: TimelineLoaderProps) => {
  const classes = useStyles({ width, height, backgroundColor })
  const spinnerDimension = 40

  return (
    <div className={classes.container}>
      <div className={classes.background}></div>
      <Loader size={spinnerDimension} />
    </div>
  )
}
