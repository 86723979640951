import { combineReducers, Reducer } from 'redux'
import { LiveDataSocketsAction, LiveDataValuesAction } from './actions'
import { LiveDataState, LiveDataSocketsState, LiveDataValuesState } from './model'
import { ADD_SOCKET, ADD_VALUE, FREE_MEMORY, REMOVE_SOCKET } from './actionTypes'
import { defaultLiveDataState } from './defaults'
import { findSlidingTimeWindowStartIndex } from '../../signals/components/shared'

export const liveDataSocketsReducer: Reducer<LiveDataSocketsState, LiveDataSocketsAction> = (
  state: LiveDataSocketsState = defaultLiveDataState().sockets,
  action: LiveDataSocketsAction
): LiveDataSocketsState => {
  switch (action.type) {
    case ADD_SOCKET:
      return [...state, action.socket]
    case REMOVE_SOCKET:
      return state.filter((ws) => ws.url !== action.streamUrl)
    default:
      return state
  }
}

export const liveDataValuesReducer: Reducer<LiveDataValuesState, LiveDataValuesAction | LiveDataSocketsAction> = (
  state: LiveDataValuesState = defaultLiveDataState().valuesByStreamUrl,
  action: LiveDataValuesAction | LiveDataSocketsAction
): LiveDataValuesState => {
  switch (action.type) {
    case REMOVE_SOCKET:
      const valuesByStreamUrls = new Map(state)

      valuesByStreamUrls.delete(action.streamUrl)

      return valuesByStreamUrls
    case ADD_VALUE:
      const newState = new Map(state)

      const values = newState.get(action.streamUrl) ?? []
      const isSorted = values.length === 0 || values[values.length - 1].timestamp < action.value.timestamp

      return newState.set(
        action.streamUrl,
        isSorted ? [...values, action.value] : [...values, action.value].sort((v1, v2) => v1.timestamp - v2.timestamp)
      )
    case FREE_MEMORY:
      const freedValuesByStreamUrls = new Map(state)

      freedValuesByStreamUrls.forEach((values, key) => {
        const slidingTimeWindowStartIndex = findSlidingTimeWindowStartIndex(values, action.now)
        freedValuesByStreamUrls.set(key, values.slice(slidingTimeWindowStartIndex))
      })

      return freedValuesByStreamUrls
    default:
      return state
  }
}

export const liveDataRootReducer = combineReducers<LiveDataState>({
  sockets: liveDataSocketsReducer,
  valuesByStreamUrl: liveDataValuesReducer,
})
