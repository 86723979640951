import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { HeatMap } from '@nivo/heatmap'
import AutoSizer from 'react-virtualized-auto-sizer'
import { DciHeatmapInitialized, DciStatusUninitialized } from '../../../dci/model'

const useStyles = makeStyles({
  root: { display: 'grid', width: '100%', height: '100%' },
})

interface DciHeatmapChartProps {
  data: DciHeatmapInitialized | DciStatusUninitialized
}

export const DciHeatmapChart = ({ data }: DciHeatmapChartProps) => {
  const classes = useStyles()

  if (data.status === 'uninitialized') {
    return <p>Loading…</p>
  }

  const heatmapData = data.signals.map((signal) => {
    const heatmapValue: any = {
      signal: signal.label,
    }

    signal.values.forEach((signalValue) => {
      heatmapValue[`${signalValue.timestamp}`] = signalValue.contribution
    })

    return heatmapValue
  })

  return (
    <div className={classes.root}>
      <AutoSizer>
        {({ width, height }) => (
          <HeatMap
            // @ts-ignore
            data={heatmapData}
            // @ts-ignore
            keys={data.signals[0].values.map((value) => `${value.timestamp}`)}
            indexBy="signal"
            height={height}
            width={width}
            animate={false}
            enableLabels={false}
            cellOpacity={1}
            cellHoverOthersOpacity={0.8}
            theme={{
              tooltip: {
                container: {
                  color: 'black',
                },
              },
            }}
          />
        )}
      </AutoSizer>
    </div>
  )
}
