import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { IsolationTile } from './IsolationTile'
import { Bed, BedId } from '../../model'
import { BrainTile } from '../../containers/tiles/BrainTile'
import { VirusTile } from '../../containers/tiles/VirusTile'
import { LungTile } from '../../containers/tiles/LungTile'
import { Theme } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    width: '100%',
    height: '100%',
    gridTemplateColumns: 'auto auto auto auto',
    justifyItems: 'start',
    justifyContent: 'start',
    alignItems: 'end',
    gridGap: theme.spacing(1),
  },
}))

interface Props {
  readonly bed: Bed
  readonly onStartIsolationStatusEdit: (bedId: BedId) => void
}

export const InfoTiles = ({ bed, onStartIsolationStatusEdit }: Props) => {
  const classes = useStyles()
  const variant = 'bed-card'

  const bedId = bed.id
  return (
    <div className={classes.root}>
      <IsolationTile
        bedId={bedId}
        bedIsolation={bed.isolationState}
        bedIsolationTimestamp={bed.isolationTimestamp}
        variant={variant}
        onClick={() => onStartIsolationStatusEdit(bedId)}
      />
      <VirusTile bed={bed} />
      <LungTile bed={bed} />
      <BrainTile bed={bed} />
    </div>
  )
}
