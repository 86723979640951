export const SET_TIME_RANGE = 'timelines_screen/SET_TIME_RANGE'
export const RESET_TIME_RANGE = 'timelines_screen/RESET_TIME_RANGE'

export const FETCH_TIMELINES = 'timelines_screen/FETCH'
export const RECEIVE_TIMELINES = 'timelines_screen/RECEIVE'
export const ERROR_TIMELINES = 'timelines_screen/ERROR'
export const SET_TIMELINES_ANIMATE = 'timelines_screen/SET_TIMELINES_ANIMATE'

export const SET_SELECTED_TIMELINES = 'timelines_screen/SET_SELECTED_TIMELINES'

export const SET_CURSOR_POSITION = 'timelines_screen/SET_CURSOR_POSITION'
export const RESET_CURSOR_POSITION = 'timelines_screen/RESET_CURSOR_POSITION'

export const SET_TIMELINE_CONFIG_YSCALE = 'timelines_screen/SET_TIMELINE_CONFIG_YSCALE'
export const RESET_TIMELINE_CONFIG_YSCALE = 'timelines_screen/RESET_TIMELINE_CONFIG_YSCALE'
