import * as React from 'react'
import { VideoBlurSwitch as VideoBlurSwitchComponent } from '../components/VideoBlurSwitch'
import { VideoBlurMode } from '../model'
import { updateVideoBlurMode, UpdateVideoBlurModeAction } from '../actions'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import { useCockpitState } from '../../store/cockpit/useCockpitState'

export const VideoBlurSwitch = () => {
  const mode = useCockpitState<VideoBlurMode>((s) => s.ui.activeUser.settings.videoBlurMode)
  const dispatch = useCockpitDispatch<UpdateVideoBlurModeAction>()
  const onVideoBlurModeChange = (mode: VideoBlurMode) => dispatch(updateVideoBlurMode(mode))
  return <VideoBlurSwitchComponent mode={mode} onVideoBlurModeChange={onVideoBlurModeChange} />
}
