import React from 'react'
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles'
import { Collapse, Divider, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { NetworkCheck, ShowChart } from '@material-ui/icons'

import { BedSignalConfigControl } from '../../../beds/containers/BedSignalConfigControl'
import { ToolbarItem } from './toolbar/ToolbarItem'
import { VideoControl } from './toolbar/VideoControl'
import { formatDelay } from '../../../signals/components/shared'
import { TimelineScreenButton } from './toolbar/TimelineScreenButton'
import { Bed } from '../../../beds/model'
import { WebSocketSignalCanvas } from '../../../signals/containers/WebSocketSignalCanvas'
import { LiveVideoStream } from '../../../video/containers/LiveVideoStream'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    width: '100%',
    height: '100%',
    gridTemplateRows: 'auto 1fr',
  },
  toolbar: {
    display: 'grid',
    gridTemplateColumns: 'repeat(6, auto) 1fr',
    alignItems: 'center',
    justifyItems: 'left',
    justifyContent: 'stretch',
    backgroundColor: 'rgba(200,200,200,0.2)',
    padding: theme.spacing(1, 2),
  },
  toolbarRight: {
    justifySelf: 'end',
  },
  divider: {
    margin: theme.spacing(0, 2),
  },
  contents: {
    position: 'relative',
  },
  layered: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}))

interface Props {
  readonly bed: Bed
  readonly isPinned: boolean
  readonly showVideoLayer: boolean
  readonly showSignalsLayer: boolean
  readonly contrastLayerOpacity: number
  readonly delayCorrectionEnabled: boolean
  readonly delayCorrectionInitializing: boolean
  readonly currentNetworkDelay: number
  readonly onSelectVideoLayer: (showVideoLayer: boolean) => void
  readonly onSelectSignalsLayer: (showSignalsLayer: boolean) => void
  readonly onContrastLayerOpacity: (value: number) => void
  readonly onToggleDelayCorrection: (enabled: boolean) => void
}

export const CenterCard = ({
  bed,
  isPinned,
  showVideoLayer,
  showSignalsLayer,
  contrastLayerOpacity,
  delayCorrectionEnabled,
  delayCorrectionInitializing,
  currentNetworkDelay,
  onSelectVideoLayer,
  onSelectSignalsLayer,
  onContrastLayerOpacity,
  onToggleDelayCorrection,
}: Props) => {
  const classes = useStyles()

  const onVideoCheckboxChange = (event: React.MouseEvent, checked: boolean) => onSelectVideoLayer(checked)

  const onSignalsCheckboxChange = (event: React.MouseEvent, checked: boolean) => onSelectSignalsLayer(checked)

  const onContrastLayerOpacityChange = (event: React.ChangeEvent<{}>, value: number | number[]) =>
    onContrastLayerOpacity((value as number) / 100)

  const onDelayCorrectionCheckboxChange = (event: React.MouseEvent, checked: boolean) =>
    onToggleDelayCorrection(checked)

  return (
    <Card className={classes.root}>
      <Collapse in={isPinned}>
        <div className={classes.toolbar}>
          <VideoControl
            showVideo={showVideoLayer}
            onToggleShowVideo={onVideoCheckboxChange}
            contrast={contrastLayerOpacity}
            onContrastChange={onContrastLayerOpacityChange}
          />
          <Divider className={classes.divider} orientation={'vertical'} />
          <ToolbarItem
            content={<ShowChart />}
            tooltip={<Typography variant="inherit">{showSignalsLayer ? 'Hide Signals' : 'Show Signals'}</Typography>}
            onClick={(e) => {
              onSignalsCheckboxChange(e, !showSignalsLayer)
            }}
            active={showSignalsLayer}
            disabled={false}
          />
          <BedSignalConfigControl bed={bed} disabled={!showSignalsLayer} />
          <Divider className={classes.divider} orientation={'vertical'} />
          <ToolbarItem
            content={<NetworkCheck />}
            tooltip={
              <Typography variant="inherit">{`${
                delayCorrectionEnabled ? 'Disable' : 'Enable'
              } network delay correction (+${formatDelay(currentNetworkDelay)})`}</Typography>
            }
            onClick={(e) => {
              onDelayCorrectionCheckboxChange(e, !delayCorrectionEnabled)
            }}
            disabled={delayCorrectionInitializing}
            active={delayCorrectionEnabled}
          />
          <div className={classes.toolbarRight}>
            <TimelineScreenButton bedId={bed.id} />
          </div>
        </div>
      </Collapse>
      <div className={classes.contents}>
        {showVideoLayer && <VideoLayer bed={bed} />}
        {showVideoLayer && <ContrastLayer opacity={contrastLayerOpacity} />}
        {showSignalsLayer && <SignalsLayer bed={bed} />}
      </div>
    </Card>
  )
}

const VideoLayer = ({ bed }: { bed: Bed }) => {
  const classes = useStyles()
  return (
    <div className={classes.layered} style={{ zIndex: 0 }}>
      <LiveVideoStream bed={bed} />
    </div>
  )
}

const ContrastLayer = ({ opacity }: { opacity: number }) => {
  const classes = useStyles()
  const theme = useTheme()
  const backgroundColor = theme.palette.type === 'light' ? 'white' : 'black'
  return <div className={classes.layered} style={{ zIndex: 1, backgroundColor, opacity }} />
}

const SignalsLayer = ({ bed }: { bed: Bed }) => {
  const classes = useStyles()
  return (
    <div className={classes.layered} style={{ zIndex: 2 }}>
      <WebSocketSignalCanvas bed={bed} />
    </div>
  )
}
