import { createTheme, ThemeOptions } from '@material-ui/core/styles'

const options: ThemeOptions = {
  props: {
    MuiGrid: {
      //spacing: 8,
    },
  },
}

export const lightTheme = createTheme({
  ...options,
  palette: {
    type: 'light',
  },
})

export const darkTheme = createTheme({
  ...options,
  palette: {
    type: 'dark',
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: options.palette?.background?.default,
      },
    },
  },
})
