export const UPDATE_SIGNAL_CONFIG_DIALOG_STATE = 'users/UPDATE_SIGNAL_CONFIG_DIALOG_STATE'
export const UPDATE_YAXIS_CONFIG_DIALOG_STATE = 'users/UPDATE_YAXIS_CONFIG_DIALOG_STATE'
export const UPDATE_SELECTED_SIGNALS = 'users/UPDATE_SELECTED_SIGNALS'
export const UPDATE_VIDEO_LAYER_VISIBILITY = 'users/UPDATE_VIDEO_LAYER_VISIBILITY'
export const UPDATE_SIGNALS_LAYER_VISIBILITY = 'users/UPDATE_SIGNALS_LAYER_VISIBILITY'
export const UPDATE_CONTRAST_LAYER_OPACITY = 'users/UPDATE_CONTRAST_LAYER_OPACITY'
export const UPDATE_DIVIDER_POSITION = 'users/UPDATE_DIVIDER_POSITION'
export const UPDATE_SIGNAL_SETTINGS_YSCALE = 'users/UPDATE_SIGNAL_SETTINGS_YSCALE'
export const SET_XAXIS_OFFSET_CORRECTION_ENABLED = 'users/SET_XAXIS_OFFSET_CORRECTION_ENABLED'
export const SET_XAXIS_OFFSET_CORRECTION_DELAY = 'users/SET_XAXIS_OFFSET_CORRECTION_DELAY'
