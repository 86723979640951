import React, { useCallback, useState } from 'react'
import { Grid, Paper, Theme, Typography } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Timeline } from '../../../timeline/containers/Timeline'
import { TimelineId } from '../../../timeline/model'
import { Domain, InteractionMode } from 'react-svg-timeline'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Add } from '@material-ui/icons'
import Button from '@material-ui/core/Button'
import { TimelineRangeControl } from '../../../timeline/components/timeline-range-control/TimelineRangeControl'
import { Timeline as TimelineType, TimelineScreenId, TimeRangeNone } from '../model'
import { TimelinesSelectionDialog } from './TimelinesSelectionDialog'
import { TimelineRangeDateTimePicker } from '../../../timeline/components/TimelineRangeDateTimePicker'
import { BedId } from '../../../beds/model'
// import { useInterval } from '../../../shared'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
    },
    gridContainer: {
      height: '100%',
    },
    gridEnclosingItem: {
      flexBasis: 'auto',
    },
    gridTimelineItem: { flexBasis: '100%', maxHeight: '150px', minHeight: '76px' },
    paper: {
      padding: theme.spacing(0.5, 2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    timelinePaper: {
      height: '100%',
    },
    empty: {
      padding: theme.spacing(2),
    },
    emptyText: {
      marginBottom: theme.spacing(3),
    },
  })
)

export interface TimelinesDetailScreenProps {
  readonly bedId: BedId
  readonly timelineScreenId: TimelineScreenId
  readonly availableTimelines: ReadonlyArray<TimelineType>
  readonly selectedTimelines: ReadonlyArray<TimelineId>
  readonly timeRange: Domain
  readonly onRangeChange: (range: Domain) => void
  readonly onResetRange: () => void
  readonly onCursorPositionChange: (cursorPosition: number) => void
  readonly onResetCursorPosition: () => void
  readonly animate: boolean
  readonly onAnimateChange: (animate: boolean) => void
  readonly onSelectedTimelinesChange: (selectedSignals: ReadonlyArray<TimelineId>) => void
}

export const TimelinesDetailScreen = ({
  bedId,
  timelineScreenId,
  availableTimelines,
  selectedTimelines,
  timeRange,
  onRangeChange,
  onResetRange,
  animate,
  onAnimateChange,
  onSelectedTimelinesChange,
  onCursorPositionChange,
  onResetCursorPosition,
}: TimelinesDetailScreenProps) => {
  const classes = useStyles()

  const [now] = useState(Date.now())
  const [liveRange, setLiveRange] = useState<number>(1000 * 60 * 60 * 24) // Default 1d

  const minInterval =
    selectedTimelines.length > 0
      ? Math.min(
          ...availableTimelines
            .filter((timeline) => selectedTimelines.find((selectedTimelineId) => selectedTimelineId === timeline.id))
            .map((timeline) => timeline.options.interval.min)
        )
      : null

  // Update [now] every [minInterval] (at most every 60000ms) of selected Signals
  // to keep data up to date
  // TODO: Ensure that we don't update now when user is interacting (in a performant way, just maintaining a state here is too slow)
  // useInterval(() => {
  //   setNow(Date.now())
  // }, Math.max(minInterval ?? 0, 60000))

  const [showTimelineSelectionDialog, setShowTimelineSelectionDialog] = useState<boolean>(false)

  const onZoomRangeChange = useCallback(
    (fromMillis: number, toMillis: number) => onRangeChange([fromMillis, toMillis]),
    [onRangeChange]
  )

  const onLiveRangeChange = useCallback(
    (fromMillis: number, toMillis: number) => {
      if (toMillis === now) {
        onResetRange()
        setLiveRange(toMillis - fromMillis)
      } else {
        onZoomRangeChange(fromMillis, toMillis)
      }
    },
    [onResetRange, setLiveRange, now, onZoomRangeChange]
  )

  const onInteractionModeChange = useCallback(
    (interactionMode: InteractionMode) => {
      onAnimateChange(interactionMode.type !== 'panning')

      if (interactionMode.type !== 'hover') {
        onResetCursorPosition()
      }
    },
    [onAnimateChange, onResetCursorPosition]
  )

  const zoomRange = timeRange !== TimeRangeNone ? timeRange : [now - liveRange, now]

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        justifyContent="flex-start"
        spacing={1}
        className={classes.gridContainer}
      >
        <Grid item xs={12} className={classes.gridEnclosingItem}>
          <Grid container justifyContent="space-between" alignItems="center" spacing={0}>
            <Grid item xs="auto">
              <Grid container justifyContent="flex-start" alignItems="center" spacing={3}>
                <Grid item xs="auto">
                  <TimelineRangeControl
                    baseEndMillis={now}
                    startMillis={zoomRange[0]}
                    endMillis={zoomRange[1]}
                    minRange={minInterval ?? 0}
                    onRangeChange={onLiveRangeChange}
                  />
                </Grid>
                <Grid item xs="auto">
                  <TimelineRangeDateTimePicker
                    fromMillis={zoomRange[0]}
                    toMillis={zoomRange[1]}
                    onTimeRangeChange={onZoomRangeChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              {selectedTimelines.length > 0 && (
                <Button
                  variant="text"
                  endIcon={<Add />}
                  onClick={() => {
                    setShowTimelineSelectionDialog(true)
                  }}
                >
                  Select Timelines
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
        {selectedTimelines
          .map((selectedTimelineId) =>
            availableTimelines.find((availableTimeline) => selectedTimelineId === availableTimeline.id)
          )
          .map(
            (selectedTimeline) =>
              selectedTimeline && (
                <Grid key={selectedTimeline.id} item xs={12} className={classes.gridTimelineItem}>
                  <Paper className={`${classes.paper} ${classes.timelinePaper}`}>
                    <AutoSizer key={`${selectedTimeline.id}_${selectedTimelines.length}`}>
                      {({ width, height }) => (
                        <Timeline
                          bedId={bedId}
                          timelineScreenId={timelineScreenId}
                          timelineId={selectedTimeline.id}
                          fromMillis={zoomRange[0]}
                          toMillis={zoomRange[1]}
                          curve={selectedTimeline.options.curve}
                          color={selectedTimeline.options.color}
                          onRangeChange={onZoomRangeChange}
                          onCursorMove={onCursorPositionChange}
                          width={width}
                          height={height}
                          animate={animate}
                          onInteractionModeChange={onInteractionModeChange}
                        />
                      )}
                    </AutoSizer>
                  </Paper>
                </Grid>
              )
          )}
        <Grid item xs={12} className={classes.gridEnclosingItem}>
          <Paper className={classes.paper}>
            <div className={selectedTimelines.length === 0 ? classes.empty : ''}>
              {selectedTimelines.length === 0 && (
                <Typography variant="body1" className={classes.emptyText}>
                  No timelines selected for this bed.
                </Typography>
              )}
              <Button
                variant={selectedTimelines.length === 0 ? 'contained' : 'text'}
                color={selectedTimelines.length === 0 ? 'primary' : 'inherit'}
                size="large"
                endIcon={<Add />}
                onClick={() => {
                  setShowTimelineSelectionDialog(true)
                }}
              >
                {selectedTimelines.length < 1 ? 'Add Timelines' : 'Select Timelines'}
              </Button>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <TimelinesSelectionDialog
        key={`${bedId}-${timelineScreenId}-dialog`}
        title="Select Timelines"
        isOpen={showTimelineSelectionDialog}
        availableTimelines={availableTimelines}
        selectedTimelines={selectedTimelines}
        onCancel={() => {
          setShowTimelineSelectionDialog(false)
        }}
        onTimelineSelectionChange={(selectedTimelineIds) => {
          onSelectedTimelinesChange(selectedTimelineIds)
          setShowTimelineSelectionDialog(false)
        }}
      />
    </div>
  )
}
