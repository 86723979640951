import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { BedIsolation } from '../model'
import { Theme } from '@material-ui/core'
import { green, amber, red, blue } from '@material-ui/core/colors'

export interface BedIsolationDisplay {
  bedIsolation: BedIsolation
  testStatus: string
  isolationStatus: string
  borderColor: string
  borderStyle?: string
}

export const getBedIsolationDisplay = (bedIsolation: BedIsolation, theme: Theme): BedIsolationDisplay => {
  switch (bedIsolation) {
    case BedIsolation.Vacant:
      return {
        bedIsolation,
        testStatus: 'Vacant',
        isolationStatus: '',
        borderColor: 'transparent',
      }
    case BedIsolation.Vaccinated:
      return {
        bedIsolation,
        testStatus: 'Vaccinated',
        isolationStatus: '',
        borderColor: blue[300],
      }
    case BedIsolation.TestedResultPending:
      return {
        bedIsolation,
        testStatus: 'Test result pending',
        isolationStatus: '',
        borderColor: theme.palette.text.secondary, // white in dark mode
      }
    case BedIsolation.TestedSARSCoV2Negative:
      return {
        bedIsolation,
        testStatus: 'SARS-CoV-2 negative',
        isolationStatus: '',
        borderColor: green[500],
      }
    case BedIsolation.TestedSARSCoV2NegativeIsolated:
      return {
        bedIsolation,
        testStatus: 'SARS-CoV-2 negative',
        isolationStatus: 'Isolated (other reason)',
        borderColor: amber[500],
      }
    case BedIsolation.TestedSARSCoV2Positive:
      return {
        bedIsolation,
        testStatus: 'SARS-CoV-2 positive',
        isolationStatus: 'Isolated',
        borderColor: red[500],
      }
  }
}

export const bedCardStyle: CSSProperties = {
  display: 'grid',
  height: '100%',
  gridTemplateRows: '1fr auto', // content & actions
}
