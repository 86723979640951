import React from 'react'
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddCircleOutline'
import { OptionId, OptionInfo } from './model'
import { useStyles } from './styles'
import { useState } from 'react'

export interface AvailableOptionsListProps {
  readonly options: ReadonlyArray<OptionInfo>
  readonly onOptionClick: (optionId: OptionId) => () => void
}

export const AvailableOptionsList = ({ options, onOptionClick }: AvailableOptionsListProps) => {
  const classes = useStyles()

  const [filter, setFilter] = useState('')

  const filteredOptions = options.filter((o) => (filter ? o.label.toLowerCase().includes(filter.toLowerCase()) : true))

  return (
    <div className={classes.listContainer}>
      <Typography className={classes.listTitle} variant={'h6'}>
        Available
      </Typography>
      {options.length > 6 && (
        <div className={classes.listSearch}>
          <TextField
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value)
            }}
            label="Filter"
          />
        </div>
      )}
      {options.length > 0 ? (
        <List className={classes.list} dense={true}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <ListItem
                key={option.id}
                button={true}
                onClick={onOptionClick(option.id)}
                classes={{ secondaryAction: classes.listItemWithAction }}
              >
                <ListItemText primary={option.label} classes={{ primary: classes.listLabel }} />
                <ListItemSecondaryAction className={classes.listAction}>
                  <IconButton onClick={onOptionClick(option.id)}>
                    <AddIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary" className={classes.emptyText}>
              No options match your filter or all selected.
            </Typography>
          )}
        </List>
      ) : (
        <Typography variant="body2" color="textSecondary" className={classes.emptyText}>
          No options available or all selected.
        </Typography>
      )}
    </div>
  )
}
