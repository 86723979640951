import React from 'react'
import { makeStyles, Typography, Theme } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

const useStyles = makeStyles((theme: Theme) => ({
  root: ({ top, left }: { top: number; left: number }) => ({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    alignItems: 'center',
    gridColumnGap: theme.spacing(1),
    position: 'absolute',
    top,
    right: `calc(100% - ${left}px)`,
  }),
}))

export interface TimelineLoaderProps {
  msg: string
  position: { top: number; left: number }
}

export const TimelineError = ({ msg, position }: TimelineLoaderProps) => {
  const classes = useStyles({ top: position.top, left: position.left })

  return (
    <div className={classes.root}>
      <Typography variant="overline" color="error">
        {msg}
      </Typography>
      <ErrorIcon color="error" />
    </div>
  )
}
