import * as React from 'react'
import { Theme, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr auto',
    justifyItems: 'center',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  icon: {
    transform: 'scale(4)',
    color: theme.palette.text.secondary
  }
}))

export const LiveVideoMissing = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <ErrorIcon className={classes.icon} />
      <Typography color="textSecondary" variant="body1">
        {'No video'}
      </Typography>
    </div>
  )
}
