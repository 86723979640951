import * as React from 'react'
import { WebSocketSignalCanvas as WebSocketSignalPaneComponent } from '../components/WebSocketSignalCanvas'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import { useUpdatedXAxisOffsetMillis } from '../hooks/useUpdatedXAxisOffsetMillis'
import { Bed } from '../../beds/model'
import { useIsPinned, useSelectedSignalConfigs } from '../../screens/overview/selectors'
import { useBedSettings } from '../../users/selectors'
import { updateDividerPosition } from '../../users/actions'

interface Props {
  readonly bed: Bed
}

export const WebSocketSignalCanvas = ({ bed }: Props) => {
  const signalConfigs = useSelectedSignalConfigs(bed)
  const signalSettings = useBedSettings(bed.id).signalSettings
  const isPinned = useIsPinned(bed.id)
  const dividerPosition = useBedSettings(bed.id).dividerPosition
  const dispatch = useCockpitDispatch()
  const onUpdateDividerPosition = (dividerPosition: number) => dispatch(updateDividerPosition(bed.id, dividerPosition))
  const xAxisOffset = useUpdatedXAxisOffsetMillis(
    bed.id,
    signalConfigs.map((signal) => signal._id)
  )

  return (
    <WebSocketSignalPaneComponent
      signalConfigs={signalConfigs}
      signalSettings={signalSettings}
      isPinned={isPinned}
      dividerPosition={dividerPosition}
      xAxisOffset={xAxisOffset}
      onUpdateDividerPosition={onUpdateDividerPosition}
    />
  )
}
