import { UPDATE_BED_ISOLATION, UPDATE_BEDS } from './actionTypes'
import { Bed, BedConfig, BedId, BedIsolation, BedNone, Beds } from './model'
import { Dispatch } from 'redux'
import { CockpitState } from '../store/cockpit/model'
import { getCredentials } from '../shared/credentials'

export type BedsAction = UpdateBedIsolationAction | UpdateBedsAction

interface UpdateBedIsolationAction {
  readonly type: typeof UPDATE_BED_ISOLATION
  readonly bedId: BedId
  readonly isolation: BedIsolation
}

export const updateBedIsolation = (bedId: BedId, isolation: BedIsolation): UpdateBedIsolationAction => ({
  type: UPDATE_BED_ISOLATION,
  bedId,
  isolation,
})

/* ·················································································································· */
/*  REST API
/* ·················································································································· */

const updateErrorMessage = 'Possibly outdated bed information'

interface UpdateBedsAction {
  type: typeof UPDATE_BEDS
  beds: Beds
}

export const updateBeds = (beds: Beds): UpdateBedsAction => ({
  type: UPDATE_BEDS,
  beds,
})

export const createInitializedBed = (fetchedBed: any, currentBed: BedConfig): Bed => {
  return {
    ...currentBed,
    ...fetchedBed,
    type: 'initialized-bed',
    updateError: undefined,
  }
}

const restPath = process.env.REACT_APP_REST_PATH ?? ''

export const refreshBeds = () => {
  return async (dispatch: Dispatch<UpdateBedsAction>, getState: () => CockpitState) => {
    const requestInit = getCredentials()
    return fetch(`${restPath}/beds`, requestInit)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          return []
        }
      })
      .then((fetchedBeds: ReadonlyArray<any>) => {
        // TODO: Refactor this to be middleware logic
        const bedEditMode = getState().ui.activeUser.settings.screen.overview.bedEditMode
        if (bedEditMode.type === 'read-only') {
          const currentBeds: ReadonlyArray<BedConfig> = getState().entities.beds.allBeds

          if (fetchedBeds.length > currentBeds.length) {
            // missing beds will be handled below
            console.warn('Server returned more beds than configured in UI')
          }

          const allBeds = currentBeds.map<Bed>((currentBed: BedConfig) => {
            const fetchedBed = fetchedBeds.find((fetchedBed) => fetchedBed['number'] === currentBed.id)
            if (fetchedBed) {
              return createInitializedBed(fetchedBed, currentBed)
            } else {
              console.warn(`Bed ${currentBed.id} missing in server response`)
              return {
                ...BedNone,
                ...currentBed, // no new bed information, preserve whatever we already have
                updateError: updateErrorMessage,
                type: 'initialized-bed',
              }
            }
          })
          const updateRequired = JSON.stringify(currentBeds) !== JSON.stringify(allBeds)
          if (updateRequired) {
            dispatch(
              updateBeds({
                type: 'initialized-beds',
                allBeds,
              })
            )
          }
        }
      })
      .catch((e) => {
        const msg = 'Loading bed information failed'
        console.error(msg, e)
        const beds = getState().entities.beds
        if (beds.type === 'uninitialized-beds') {
          dispatch(
            updateBeds({
              ...beds,
              initializationError: msg,
            })
          )
        } else {
          const allBeds = beds.allBeds.map((b: Bed) => ({
            ...b,
            updateError: updateErrorMessage,
          }))
          dispatch(
            updateBeds({
              ...beds,
              allBeds,
            })
          )
        }
      })
  }
}

export const saveBedIsolation = (bedId: BedId, isolation: BedIsolation) => {
  return async (dispatch: Dispatch<UpdateBedIsolationAction>, _getState: () => CockpitState) => {
    const method = { method: 'PUT' }
    const credentials = getCredentials()
    const headers = {
      headers: {
        'Content-Type': 'application/json',
      },
    }
    const body = {
      body: JSON.stringify({
        isolationState: isolation,
      }),
    }
    const requestInit = {
      ...method,
      ...credentials,
      ...headers,
      ...body,
    }
    return fetch(`${restPath}/beds/${bedId}/isolationState`, requestInit)
      .then((response) => {
        if (response.ok) {
          dispatch(updateBedIsolation(bedId, isolation))
        } else {
          console.error('Error', response)
        }
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }
}
