import React from 'react'
import { BedSignalConfigControl as BedSignalConfigControlComponent } from '../components/BedSignalConfigControl'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import { Bed } from '../model'
import { showSignalConfigDialog, UpdateSignalConfigDialogStateAction } from '../../users/actions'

interface Props {
  readonly bed: Bed
  readonly disabled: boolean
}

export const BedSignalConfigControl = ({ bed, disabled }: Props) => {
  const dispatch = useCockpitDispatch<UpdateSignalConfigDialogStateAction>()
  const onShowSignalConfigDialog = () => dispatch(showSignalConfigDialog(bed.id))

  return (
    <BedSignalConfigControlComponent
      bed={bed}
      disabled={disabled}
      onShowSignalConfigDialog={onShowSignalConfigDialog}
    />
  )
}
