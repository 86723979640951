import { useCallback, useEffect, useState } from 'react'

import { createShaders, RenderingInfo } from '../components/webgl-rendering'

export const useWebGLRenderingInfo = (): [RenderingInfo | undefined, (canvasElement: HTMLCanvasElement) => void] => {
  const [renderingInfo, setRenderingInfo] = useState<RenderingInfo>()

  const canvasRef = useCallback(
    (canvasElement: HTMLCanvasElement) => {
      if (canvasElement) {
        console.log('Create WebGL Rendering info')

        const gl: WebGLRenderingContext = canvasElement.getContext('webgl')!
        const shaders: WebGLProgram = createShaders(gl)
        gl.useProgram(shaders)

        const canvas: HTMLCanvasElement = canvasElement

        setRenderingInfo({ canvas, gl, shaders })
      }
    },
    [setRenderingInfo]
  )

  // Create a WebGL Buffer once the WebGLRenderingContext is available
  // and dispose the buffer on unmount to free up memory
  useEffect(() => {
    if (renderingInfo && renderingInfo.gl && renderingInfo.glBuffer === undefined) {
      // Create a WebGL buffer
      console.log('Creating WebGL Buffer')
      const positionBuffer = renderingInfo.gl.createBuffer()

      if (!positionBuffer) {
        throw new Error('Could not create WebGL Buffer!')
      }

      // Bind that buffer to the binding point
      renderingInfo.gl.bindBuffer(renderingInfo.gl.ARRAY_BUFFER, positionBuffer)

      setRenderingInfo({ ...renderingInfo, glBuffer: positionBuffer })
    }

    if (renderingInfo && renderingInfo.gl && renderingInfo.glBuffer !== undefined) {
      return () => {
        console.log('Deleting WebGL Buffer')
        renderingInfo.gl.deleteBuffer(renderingInfo.glBuffer!)
      }
    }
  }, [renderingInfo, setRenderingInfo])

  return [renderingInfo, canvasRef]
}
