import { Theme, Tooltip } from '@material-ui/core'
import HotelBedIcon from '@material-ui/icons/Hotel'
import * as React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import { Bed } from '../model'
import { cn } from '../../shared/utils'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: 0,
    margin: theme.spacing(1),
    alignSelf: 'center',
    justifySelf: 'right',
  },
  bedFlipped: {
    transform: 'scaleX(-1)',
  },
}))

interface Props {
  readonly bed: Bed
}

export const BedIcon = ({ bed }: Props) => {
  const classes = useStyles()
  const { direction, updateError } = bed
  if (updateError) {
    return (
      <Tooltip title={updateError}>
        <ErrorIcon className={classes.icon} />
      </Tooltip>
    )
  } else {
    const className = direction === 'left' ? classes.icon : cn(classes.icon, classes.bedFlipped)
    return <HotelBedIcon className={className} />
  }
}
