import React, { useCallback } from 'react'
import { CustomLayerProps } from '@nivo/line'
import { useTooltip, TooltipAnchor } from '@nivo/tooltip'
import { TimelinePoint, DataPoint } from './TimelinePoint'
import { TimelinePointTooltip } from './TimelinePointTooltip'
import { DEFAULT_POINTBORDER_SIZE, DEFAULT_POINT_SIZE } from '../../Timeline'

export interface PointsLayerProps extends CustomLayerProps {}

const getTooltipPosition = (containerHeight: number, containerWidth: number, x: number, y: number): TooltipAnchor => {
  if (x <= containerWidth / 4) {
    return 'right'
  } else if (x >= (containerWidth / 4) * 3) {
    return 'left'
  } else if (y <= containerHeight / 3) {
    return 'bottom'
  } else {
    return 'top'
  }
}

export const PointsLayer = ({
  points,
  margin,
  pointSize,
  pointBorderWidth,
  innerHeight,
  innerWidth,
  isInteractive,
}: PointsLayerProps) => {
  const { showTooltipAt, hideTooltip } = useTooltip()

  const size = pointSize ?? DEFAULT_POINT_SIZE
  const borderWidth = pointBorderWidth ?? DEFAULT_POINTBORDER_SIZE

  const handleMouseEnter = useCallback(
    (point: DataPoint) => {
      if (isInteractive) {
        const x = point.x + (margin?.left ?? 0)
        const y = point.y + (margin?.top ?? 0)

        showTooltipAt(
          <TimelinePointTooltip
            xLabel={point.data.xFormatted.toString()}
            yLabel={point.data.yFormatted.toString()}
            minLabel={point.data.min?.toString()}
            maxLabel={point.data.max?.toString()}
          ></TimelinePointTooltip>,
          [x, y],
          getTooltipPosition(innerHeight, innerWidth, x, y)
        )
      }
    },
    [showTooltipAt, margin, innerHeight, innerWidth, isInteractive]
  )

  const handleMouseLeave = useCallback(() => {
    if (isInteractive) hideTooltip()
  }, [hideTooltip, isInteractive])

  // Points are costly to display, therefore only show them when necessary:
  // a) Sufficiently zoomed in (size >= 5)
  // b) <= 350 points to display
  if (points.length > 350 || size < 5) {
    return null
  }

  return (
    <>
      {points.map((point, idx) => (
        <TimelinePoint
          key={`${idx}-${(point as unknown as DataPoint).data.timestamp}`}
          point={point as unknown as DataPoint}
          size={size}
          borderWidth={borderWidth}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        ></TimelinePoint>
      ))}
    </>
  )
}
