import { useCockpitState } from '../store/cockpit/useCockpitState'
import { DetailScreenType } from './model'
import { matchPath } from 'react-router-dom'
import { Bed, BedNone } from '../beds/model'

/**
 * Active means hovered (precedence) or pinned
 */
export const useActiveBed = () =>
  useCockpitState<Bed>((s) => {
    const beds = s.entities.beds
    if (beds.type === 'uninitialized-beds') {
      return BedNone
    } else {
      const bedEditMode = s.ui.activeUser.settings.screen.overview.bedEditMode
      if (bedEditMode.type === 'read-only' && bedEditMode.bedInteraction.type !== 'none') {
        const activeBed = bedEditMode.bedInteraction.bedId
        return beds.allBeds.find((b: Bed) => b.id === activeBed) ?? BedNone
      } else {
        return BedNone
      }
    }
  })

export const useRoutedBed = () => {
  return useCockpitState<Bed>((s) => {
    const match = matchPath({ path: '/bed/:bedNumber/*' }, window.location.pathname)
    if (match?.params) {
      const { bedNumber } = match.params
      const beds = s.entities.beds
      if (beds.type !== 'uninitialized-beds') {
        return beds.allBeds.find((b) => b.number === Number(bedNumber)) ?? BedNone
      }
    }
    return BedNone
  })
}

export const useActiveDetailScreen = (): DetailScreenType => {
  const match = matchPath({ path: '/bed/:bedNumber/:detailScreen/*' }, window.location.pathname)
  const detailMatch = matchPath({ path: '/bed/:bedNumber/:detailScreen/:tile' }, window.location.pathname)

  if (match?.params.detailScreen === 'dci') {
    return 'dci'
  } else if (match?.params.detailScreen === 'timelines') {
    return 'timelines'
  } else if (match?.params.detailScreen === 'detail') {
    return `detail/${detailMatch?.params.tile}` as DetailScreenType
  } else {
    throw new Error('Unknown detail screen location')
  }
}
