import React from 'react'
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { makeStyles } from '@material-ui/styles'
import AutoSizer from 'react-virtualized-auto-sizer'
import { DciStatusUninitialized, DciTimelineInitialized } from '../../../dci/model'

const useStyles = makeStyles({
  root: { display: 'grid', width: '100%', height: '100%' },
})

interface DciTimelineProps {
  data: DciTimelineInitialized | DciStatusUninitialized
}

export const DciTimelineChart = ({ data }: DciTimelineProps) => {
  const classes = useStyles()

  if (data.status === 'uninitialized') {
    return <p>Loading…</p>
  }

  const options = {
    chart: {
      zoomType: 'x', // TODO: Zoom reset does not work
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: false,
    },
    plotOptions: {
      series: {
        type: 'timeline',
        pointStart: data.timeline[0].timestamp,
        pointInterval: data.timeline[1].timestamp - data.timeline[0].timestamp,
      },
    },
    series: [
      {
        name: 'Dynamic',
        data: data.timeline.map((timeframe) => timeframe.dynamic),
        opacity: 0.5,
      },
      {
        name: 'Voted',
        data: data.timeline.map((timeframe) => timeframe.voted),
        color: 'red',
      },
    ],
    yAxis: {
      plotBands: [
        {
          color: 'lightpink',
          from: 0,
          to: data.static,
        },
      ],
    },
  }

  return (
    <AutoSizer>
      {({ height, width }) => {
        if (height <= 0) {
          return null
        }

        return (
          <div className={classes.root} style={{ height, width }}>
            <HighchartsReact
              constructorType={'stockChart'}
              highcharts={Highcharts}
              options={{ ...options, chart: { ...options.chart, height, width } }}
            />
          </div>
        )
      }}
    </AutoSizer>
  )
}
