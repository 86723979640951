import React from 'react'
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/RemoveCircleOutline'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle } from 'react-beautiful-dnd'
import { borderStyle, useStyles } from './styles'
import { AvailableOptionsListProps } from './AvailableOptionsList'

type SelectedOptionsListProps = AvailableOptionsListProps & {
  readonly onDragAndDrop: (startIndex: number, endIndex: number) => void
}

export const SelectedOptionsList = ({ options, onOptionClick, onDragAndDrop }: SelectedOptionsListProps) => {
  const classes = useStyles()

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    onDragAndDrop(result.source.index, result.destination.index)
  }

  const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle) => ({
    ...draggableStyle,
    ...(isDragging && borderStyle),
  })

  return (
    <div className={classes.listContainer}>
      <Typography className={classes.listTitle} variant={'h6'}>
        Selected
      </Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={'droppable'}>
          {(providedDroppable, snapshotDroppable) => (
            <div ref={providedDroppable.innerRef}>
              {options.length > 0 ? (
                <List className={classes.list} dense={true}>
                  {options.map((option, index) => {
                    const isDragging = snapshotDroppable.isDraggingOver || snapshotDroppable.isUsingPlaceholder
                    return (
                      <Draggable key={option.id} draggableId={option.id} index={index}>
                        {(providedDraggable, snapshotDraggable) => {
                          return (
                            <ListItem
                              ref={providedDraggable.innerRef}
                              key={option.id}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                              style={getItemStyle(snapshotDraggable.isDragging, providedDraggable.draggableProps.style)}
                              classes={{ secondaryAction: classes.listItemWithAction }}
                            >
                              {options.length > 1 && (
                                <ListItemIcon className={classes.listDragIcon}>
                                  <DragHandleIcon />
                                </ListItemIcon>
                              )}
                              <ListItemText primary={option.label} classes={{ primary: classes.listLabel }} />
                              <ListItemSecondaryAction hidden={isDragging} className={classes.listAction}>
                                <IconButton onClick={onOptionClick(option.id)}>
                                  <RemoveIcon />
                                </IconButton>
                              </ListItemSecondaryAction>
                            </ListItem>
                          )
                        }}
                      </Draggable>
                    )
                  })}
                  {providedDroppable.placeholder}
                </List>
              ) : (
                <Typography variant="body2" color="textSecondary" className={classes.emptyText}>
                  Nothing selected.
                </Typography>
              )}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  )
}
