import { Grid, Paper, Theme, Typography } from '@material-ui/core'
import React from 'react'
import { CurrentProbabilityInfo } from '../../../beds/model'
import { ProbabilityInfoTileContents } from '../../../beds/components/tiles/ProbabilityInfoTileContents'
import { makeStyles } from '@material-ui/styles'

export const PROBABILITY_SUMMARY_TILE_SIZE = 150

const useStyles = makeStyles((theme: Theme) => ({
  title: {
    marginTop: theme.spacing(2),
  },
  paper: {
    minHeight: PROBABILITY_SUMMARY_TILE_SIZE,
    maxHeight: PROBABILITY_SUMMARY_TILE_SIZE,
    width: PROBABILITY_SUMMARY_TILE_SIZE,
    textAlign: 'center',
    color: theme.palette.text.secondary,
    padding: theme.spacing(1),
    display: 'grid',
    gridTemplateRows: '1fr auto auto',
  },
  probabilities: {
    fontSize: 12, // mimic timeline marker
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    justifyItems: 'start',
    gridColumnGap: theme.spacing(1),
    lineHeight: 1,
  },
  centerVertically: {
    height: '100%',
    display: 'grid',
    alignItems: 'center',
  },
}))

export interface Props {
  readonly currentProbabilityInfo?: CurrentProbabilityInfo
}

export const ProbabilitiesSummaryTile = ({ currentProbabilityInfo }: Props) => {
  const classes = useStyles()
  const hasSingleProbabilityOnly =
    currentProbabilityInfo?.static === undefined || currentProbabilityInfo?.dynamic === undefined
  return (
    <Grid item xs="auto">
      <Typography variant="h5" className={classes.title}>
        &nbsp;
      </Typography>
      <Paper className={classes.paper}>
        <div className={hasSingleProbabilityOnly ? classes.centerVertically : ''}>
          {currentProbabilityInfo && (
            <ProbabilityInfoTileContents
              infoTileType={currentProbabilityInfo.type}
              probability={currentProbabilityInfo.probability}
              showAlarm={!!currentProbabilityInfo.showAlarm}
              isXL={true}
            />
          )}
        </div>
        {currentProbabilityInfo &&
          currentProbabilityInfo.dynamic !== undefined &&
          currentProbabilityInfo.static !== undefined && (
            <Typography className={classes.probabilities}>
              <span>{currentProbabilityInfo.dynamic.toFixed(2)}</span>
              <span>Dynamic</span>
              <span>{currentProbabilityInfo.static.toFixed(2)}</span>
              <span>Static</span>
            </Typography>
          )}
      </Paper>
    </Grid>
  )
}
