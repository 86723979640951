import { ProbabilityIndicator } from './ProbabilityIndicator'
import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import { Theme, Typography } from '@material-ui/core'
import { BED_CARD_INFO_TILE_SIZE } from './InfoTile'
import { INFO_TILE_ICON_SIZE, INFO_TILE_LABEL_FONT_SIZE } from './shared'
import { InfoTileType } from '../../model'
import { BrainIcon } from '../../../shared/components/BrainIcon'
import { LungIcon } from '../../../shared/components/LungIcon'
import { CoronaVirusIcon } from '../../../shared/components/CoronaVirusIcon'

const EMPTY_LABEL = '\u00A0' // pseudo empty to block vertical space

const XL_SIZE = 100

const size = (theme: Theme, isXL: boolean = false) => (isXL ? XL_SIZE : BED_CARD_INFO_TILE_SIZE) - theme.spacing(2)

const infoTileLabel = (infoTileType: InfoTileType) => {
  switch (infoTileType) {
    case 'brain':
      return 'DCI'
    case 'virus':
      return 'COVID-19'
    case 'lung':
      return 'ALI' // Acute Lung Injury
    default:
      return ''
  }
}

const infoTileIcon = (infoTileType: InfoTileType, isXL: boolean) => {
  const SIZE_FACTOR = isXL ? 2 : 1
  switch (infoTileType) {
    case 'brain':
      return <BrainIcon size={SIZE_FACTOR * 0.9 * INFO_TILE_ICON_SIZE} />
    case 'virus':
      return <CoronaVirusIcon size={SIZE_FACTOR * INFO_TILE_ICON_SIZE} />
    case 'lung':
      return <LungIcon size={SIZE_FACTOR * INFO_TILE_ICON_SIZE} />
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  tile: ({ isXL }: Props) => ({
    display: 'grid',
    width: size(theme, isXL),
    height: size(theme, isXL),
    gridTemplateColumns: `20% 80%`,
  }),
  left: ({ isXL }: Props) => ({
    maxHeight: size(theme, isXL),
    height: '100%',
  }),
  right: ({ isXL }: Props) => ({
    display: 'grid',
    height: '100%',
    maxHeight: size(theme, isXL),
    justifyItems: 'left',
    gridTemplateRows: '1fr auto auto',
    paddingLeft: theme.spacing(0.25),
  }),
  icon: {
    justifySelf: 'end',
  },
  label: ({ isXL }: Props) => ({
    marginTop: isXL ? -9 : -4,
    fontSize: (isXL ? 2 : 1) * INFO_TILE_LABEL_FONT_SIZE,
    textTransform: 'uppercase',
  }),
  value: ({ isXL }: Props) => ({
    fontSize: 0.3 * size(theme, isXL),
    // pragmatically align numbers to text baseline
    marginTop: -5,
    marginBottom: -3,
    alignSelf: 'end',
  }),
}))

export interface Props {
  readonly infoTileType: InfoTileType
  readonly probability: number | undefined
  readonly showAlarm: boolean
  readonly isXL?: boolean
}

export const ProbabilityInfoTileContents = (props: Props) => {
  const { infoTileType, probability, showAlarm, isXL = false } = props
  const classes = useStyles(props)
  const isMissingProbability = probability === undefined || isNaN(probability)
  const value = isMissingProbability ? EMPTY_LABEL : probability!.toFixed(2)
  return (
    <div className={classes.tile}>
      <div className={classes.left}>
        <ProbabilityIndicator probability={probability} showAlarm={showAlarm} />
      </div>
      <div>
        <div className={classes.right}>
          <div className={classes.icon}>{infoTileIcon(infoTileType, isXL)}</div>
          <Typography className={classes.label}>{infoTileLabel(infoTileType)}</Typography>
          <Typography className={classes.value}>{value}</Typography>
        </div>
      </div>
    </div>
  )
}
