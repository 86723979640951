import { applyMiddleware, createStore, Store } from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import { CockpitAction } from './actions'
import { CockpitState } from './model'
import { cockpitRootReducer } from './reducer'
import { initDciBedMiddleware } from '../../dci/middleware'
import { initTimelineMiddleware } from '../../timeline/middleware'
import { initSelectedTimelinesMiddleware } from '../../screens/timelines/middleware'

const createCockpitStore = (withLogger: boolean = false): Store<CockpitState, CockpitAction> => {
  const middlewares = [thunk, initDciBedMiddleware, initTimelineMiddleware, initSelectedTimelinesMiddleware]
  const middleware = withLogger ? applyMiddleware(...middlewares, createLogger()) : applyMiddleware(...middlewares)
  return createStore(cockpitRootReducer, middleware)
}

export default createCockpitStore
