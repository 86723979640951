import React from 'react'
import { makeStyles, Theme, Typography } from '@material-ui/core'
import ErrorIcon from '@material-ui/icons/Error'

export interface ErrorProps {
  size?: 'large' | 'default' | 'small'
  label?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  centered: {
    width: '100%',
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
  withLabel: {
    alignSelf: 'flex-end',
  },
  label: {
    alignSelf: 'flex-start',
    paddingTop: theme.spacing(2),
  },
}))

export const Error = ({ size = 'default', label }: ErrorProps) => {
  const classes = useStyles()

  return (
    <div className={classes.centered}>
      <ErrorIcon className={label ? classes.withLabel : ''} color="error" fontSize={size} />
      {label && (
        <Typography color="error" className={classes.label} align={'center'}>
          {label}
        </Typography>
      )}
    </div>
  )
}
