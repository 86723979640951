import React from 'react'
import { BedIdNone } from '../../beds/model'
import { ToolbarCenterOverview } from '../components/CockpitNavigation'
import { useActiveDetailScreen, useRoutedBed } from '../../screens/selectors'

export const ToolbarCenterDetail = () => {
  const activeBed = useRoutedBed()
  const activeDetailScreen = useActiveDetailScreen()

  return (
    <ToolbarCenterOverview
      title={activeBed.id !== BedIdNone ? `${activeBed.label}: ${activeDetailScreen.toUpperCase()}` : ''}
    />
  )
}
