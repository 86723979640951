import { useTheme } from '@material-ui/core/styles'

interface Props {
  readonly size: number
}

// Adapted from https://upload.wikimedia.org/wikipedia/commons/c/c2/Coronavirus_icon.svg
export const CoronaVirusIcon = ({ size }: Props) => {
  const theme = useTheme()
  const color = theme.palette.text.primary
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="30, 30, 120, 120">
      <g id="Layer_1">
        <g>
          <g>
            <path
              d="M89.553,132.112 C66.909,132.112 48.553,113.756 48.553,91.112 C48.553,68.468 66.909,50.112 89.553,50.112 C112.197,50.112 130.553,68.468 130.553,91.112 C130.553,113.756 112.197,132.112 89.553,132.112 z"
              fill="transparent"
            />
            <path
              d="M89.553,132.112 C66.909,132.112 48.553,113.756 48.553,91.112 C48.553,68.468 66.909,50.112 89.553,50.112 C112.197,50.112 130.553,68.468 130.553,91.112 C130.553,113.756 112.197,132.112 89.553,132.112 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="7"
            />
          </g>
          <g>
            <path
              d="M84.563,32.42 L84.563,32.42 C86.699,32.233 88.583,33.814 88.77,35.95 L89.773,47.417 C89.96,49.553 88.38,51.437 86.243,51.624 L86.243,51.624 C84.106,51.811 82.222,50.23 82.035,48.094 L81.032,36.627 C80.845,34.491 82.426,32.607 84.563,32.42 z"
              fill={color}
            />
            <path
              d="M84.563,32.42 L84.563,32.42 C86.699,32.233 88.583,33.814 88.77,35.95 L89.773,47.417 C89.96,49.553 88.38,51.437 86.243,51.624 L86.243,51.624 C84.106,51.811 82.222,50.23 82.035,48.094 L81.032,36.627 C80.845,34.491 82.426,32.607 84.563,32.42 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M117.487,39.308 L117.487,39.308 C119.431,40.214 120.272,42.525 119.365,44.469 L114.501,54.9 C113.594,56.844 111.284,57.685 109.34,56.779 L109.34,56.779 C107.396,55.872 106.555,53.561 107.461,51.618 L112.326,41.186 C113.232,39.242 115.543,38.401 117.487,39.308 z"
              fill={color}
            />
            <path
              d="M117.487,39.308 L117.487,39.308 C119.431,40.214 120.272,42.525 119.365,44.469 L114.501,54.9 C113.594,56.844 111.284,57.685 109.34,56.779 L109.34,56.779 C107.396,55.872 106.555,53.561 107.461,51.618 L112.326,41.186 C113.232,39.242 115.543,38.401 117.487,39.308 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M141.182,60.641 L141.182,60.641 C142.413,62.398 141.986,64.82 140.229,66.05 L130.8,72.652 C129.043,73.882 126.622,73.455 125.391,71.698 L125.391,71.698 C124.161,69.941 124.588,67.519 126.345,66.289 L135.774,59.687 C137.531,58.457 139.952,58.884 141.182,60.641 z"
              fill={color}
            />
            <path
              d="M141.182,60.641 L141.182,60.641 C142.413,62.398 141.986,64.82 140.229,66.05 L130.8,72.652 C129.043,73.882 126.622,73.455 125.391,71.698 L125.391,71.698 C124.161,69.941 124.588,67.519 126.345,66.289 L135.774,59.687 C137.531,58.457 139.952,58.884 141.182,60.641 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M149.015,94.883 L149.015,94.883 C148.828,97.02 146.945,98.6 144.808,98.414 L133.342,97.41 C131.205,97.223 129.624,95.34 129.811,93.203 L129.811,93.203 C129.998,91.066 131.882,89.486 134.019,89.673 L145.485,90.676 C147.622,90.863 149.202,92.746 149.015,94.883 z"
              fill={color}
            />
            <path
              d="M149.015,94.883 L149.015,94.883 C148.828,97.02 146.945,98.6 144.808,98.414 L133.342,97.41 C131.205,97.223 129.624,95.34 129.811,93.203 L129.811,93.203 C129.998,91.066 131.882,89.486 134.019,89.673 L145.485,90.676 C147.622,90.863 149.202,92.746 149.015,94.883 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M122.518,113.515 L122.518,113.515 C123.748,111.758 126.17,111.331 127.927,112.561 L137.355,119.163 C139.112,120.393 139.539,122.815 138.309,124.572 L138.309,124.572 C137.079,126.329 134.657,126.756 132.9,125.525 L123.471,118.923 C121.714,117.693 121.287,115.272 122.518,113.515 z"
              fill={color}
            />
            <path
              d="M122.518,113.515 L122.518,113.515 C123.748,111.758 126.17,111.331 127.927,112.561 L137.355,119.163 C139.112,120.393 139.539,122.815 138.309,124.572 L138.309,124.572 C137.079,126.329 134.657,126.756 132.9,125.525 L123.471,118.923 C121.714,117.693 121.287,115.272 122.518,113.515 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M105.117,126.986 L105.117,126.986 C107.132,126.252 109.361,127.291 110.094,129.307 L114.031,140.123 C114.765,142.138 113.725,144.367 111.71,145.1 L111.71,145.1 C109.694,145.834 107.466,144.795 106.732,142.779 L102.795,131.963 C102.062,129.948 103.101,127.719 105.117,126.986 z"
              fill={color}
            />
            <path
              d="M105.117,126.986 L105.117,126.986 C107.132,126.252 109.361,127.291 110.094,129.307 L114.031,140.123 C114.765,142.138 113.725,144.367 111.71,145.1 L111.71,145.1 C109.694,145.834 107.466,144.795 106.732,142.779 L102.795,131.963 C102.062,129.948 103.101,127.719 105.117,126.986 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M82.087,129.551 L82.087,129.551 C84.199,129.923 85.61,131.938 85.237,134.05 L83.239,145.385 C82.866,147.497 80.852,148.908 78.74,148.535 L78.74,148.535 C76.627,148.163 75.217,146.149 75.589,144.036 L77.588,132.701 C77.96,130.589 79.975,129.178 82.087,129.551 z"
              fill={color}
            />
            <path
              d="M82.087,129.551 L82.087,129.551 C84.199,129.923 85.61,131.938 85.237,134.05 L83.239,145.385 C82.866,147.497 80.852,148.908 78.74,148.535 L78.74,148.535 C76.627,148.163 75.217,146.149 75.589,144.036 L77.588,132.701 C77.96,130.589 79.975,129.178 82.087,129.551 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M62.229,120.228 L62.229,120.228 C63.745,121.744 63.745,124.203 62.229,125.72 L54.09,133.859 C52.573,135.375 50.114,135.375 48.598,133.859 L48.598,133.859 C47.081,132.342 47.081,129.883 48.598,128.366 L56.736,120.228 C58.253,118.711 60.712,118.711 62.229,120.228 z"
              fill={color}
            />
            <path
              d="M62.229,120.228 L62.229,120.228 C63.745,121.744 63.745,124.203 62.229,125.72 L54.09,133.859 C52.573,135.375 50.114,135.375 48.598,133.859 L48.598,133.859 C47.081,132.342 47.081,129.883 48.598,128.366 L56.736,120.228 C58.253,118.711 60.712,118.711 62.229,120.228 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M50.723,99.548 L50.723,99.548 C51.279,101.62 50.049,103.75 47.977,104.305 L36.86,107.284 C34.788,107.839 32.658,106.61 32.103,104.538 L32.103,104.538 C31.548,102.466 32.777,100.336 34.849,99.781 L45.967,96.802 C48.039,96.247 50.168,97.477 50.723,99.548 z"
              fill={color}
            />
            <path
              d="M50.723,99.548 L50.723,99.548 C51.279,101.62 50.049,103.75 47.977,104.305 L36.86,107.284 C34.788,107.839 32.658,106.61 32.103,104.538 L32.103,104.538 C31.548,102.466 32.777,100.336 34.849,99.781 L45.967,96.802 C48.039,96.247 50.168,97.477 50.723,99.548 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M51.471,78.34 L51.471,78.34 C50.737,80.355 48.508,81.394 46.493,80.661 L35.677,76.724 C33.662,75.991 32.622,73.762 33.356,71.746 L33.356,71.746 C34.089,69.731 36.318,68.692 38.334,69.425 L49.149,73.362 C51.165,74.096 52.204,76.324 51.471,78.34 z"
              fill={color}
            />
            <path
              d="M51.471,78.34 L51.471,78.34 C50.737,80.355 48.508,81.394 46.493,80.661 L35.677,76.724 C33.662,75.991 32.622,73.762 33.356,71.746 L33.356,71.746 C34.089,69.731 36.318,68.692 38.334,69.425 L49.149,73.362 C51.165,74.096 52.204,76.324 51.471,78.34 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
          <g>
            <path
              d="M52.218,44.659 L52.218,44.659 C53.861,43.281 56.31,43.495 57.689,45.138 L65.088,53.955 C66.466,55.598 66.252,58.048 64.609,59.427 L64.609,59.427 C62.966,60.805 60.516,60.591 59.137,58.948 L51.739,50.131 C50.36,48.488 50.575,46.038 52.218,44.659 z"
              fill={color}
            />
            <path
              d="M52.218,44.659 L52.218,44.659 C53.861,43.281 56.31,43.495 57.689,45.138 L65.088,53.955 C66.466,55.598 66.252,58.048 64.609,59.427 L64.609,59.427 C62.966,60.805 60.516,60.591 59.137,58.948 L51.739,50.131 C50.36,48.488 50.575,46.038 52.218,44.659 z"
              fillOpacity="0"
              stroke={color}
              strokeWidth="1"
            />
          </g>
        </g>
        <path
          d="M86.911,69.8 C83.873,69.8 81.411,67.338 81.411,64.3 C81.411,61.262 83.873,58.8 86.911,58.8 C89.948,58.8 92.411,61.262 92.411,64.3 C92.411,67.338 89.948,69.8 86.911,69.8 z"
          fill={color}
        />
        <path
          d="M124.247,88.947 C121.21,88.947 118.747,86.485 118.747,83.447 C118.747,80.41 121.21,77.947 124.247,77.947 C127.285,77.947 129.747,80.41 129.747,83.447 C129.747,86.485 127.285,88.947 124.247,88.947 z"
          fill={color}
        />
        <path
          d="M72.079,127.726 C69.041,127.726 66.579,125.264 66.579,122.226 C66.579,119.189 69.041,116.726 72.079,116.726 C75.117,116.726 77.579,119.189 77.579,122.226 C77.579,125.264 75.117,127.726 72.079,127.726 z"
          fill={color}
        />
        <path
          d="M57.679,78.463 C54.641,78.463 52.179,76.001 52.179,72.963 C52.179,69.926 54.641,67.463 57.679,67.463 C60.717,67.463 63.179,69.926 63.179,72.963 C63.179,76.001 60.717,78.463 57.679,78.463 z"
          fill={color}
        />
        <path
          d="M109.847,124.695 C106.81,124.695 104.347,122.232 104.347,119.195 C104.347,116.157 106.81,113.695 109.847,113.695 C112.885,113.695 115.347,116.157 115.347,119.195 C115.347,122.232 112.885,124.695 109.847,124.695 z"
          fill={color}
        />
        <path
          d="M104.037,81.368 C100.999,81.368 98.537,78.906 98.537,75.868 C98.537,72.831 100.999,70.368 104.037,70.368 C107.074,70.368 109.537,72.831 109.537,75.868 C109.537,78.906 107.074,81.368 104.037,81.368 z"
          fill={color}
        />
        <path
          d="M76.879,87.179 C73.841,87.179 71.379,84.717 71.379,81.679 C71.379,78.641 73.841,76.179 76.879,76.179 C79.917,76.179 82.379,78.641 82.379,81.679 C82.379,84.717 79.917,87.179 76.879,87.179 z"
          fill={color}
        />
        <path
          d="M86.858,113.832 C83.82,113.832 81.358,111.369 81.358,108.332 C81.358,105.294 83.82,102.832 86.858,102.832 C89.895,102.832 92.358,105.294 92.358,108.332 C92.358,111.369 89.895,113.832 86.858,113.832 z"
          fill={color}
        />
        <path
          d="M104.289,102.842 C101.252,102.842 98.789,100.38 98.789,97.342 C98.789,94.305 101.252,91.842 104.289,91.842 C107.327,91.842 109.789,94.305 109.789,97.342 C109.789,100.38 107.327,102.842 104.289,102.842 z"
          fill={color}
        />
        <path
          d="M63.616,101.326 C60.578,101.326 58.116,98.864 58.116,95.826 C58.116,92.789 60.578,90.326 63.616,90.326 C66.653,90.326 69.116,92.789 69.116,95.826 C69.116,98.864 66.653,101.326 63.616,101.326 z"
          fill={color}
        />
        <path
          d="M105.047,63.053 C102.01,63.053 99.547,60.59 99.547,57.553 C99.547,54.515 102.01,52.053 105.047,52.053 C108.085,52.053 110.547,54.515 110.547,57.553 C110.547,60.59 108.085,63.053 105.047,63.053 z"
          fill={color}
        />
      </g>
    </svg>
  )
}
