import { useEffect, useState } from 'react'

import { ticks } from 'd3-array'

import { useXAxisOffsetCorrection } from '../selectors'
import { SignalId, TimestampedValue } from '../model'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import { BedId } from '../../beds/model'
import { useInterval } from '../../shared/useInterval'
import { setXAxisOffsetCorrectionDelay } from '../../users/actions'
import { delayUninitialized, XAxisOffsetCorrection } from '../../users/model'
import { useLiveDataStateStore } from '../../store/live/useLiveDataState'

const fastRefreshRate = 1000
const defaultRefreshRate = 60000

export const getAboutTenSamples = <T>(arr: ReadonlyArray<T>): ReadonlyArray<T> => {
  if (arr.length <= 10) {
    return arr
  } else {
    const samples: Array<T> = []

    const sampleIndexes = ticks(0, arr.length - 1, 10)

    sampleIndexes.forEach((sampleIndex) => {
      samples.push(arr[sampleIndex])
    })

    return samples
  }
}

export const useUpdatedXAxisOffsetMillis = (
  bedId: BedId,
  selectedSignals: ReadonlyArray<SignalId>
): XAxisOffsetCorrection => {
  const currentXAxisOffset = useXAxisOffsetCorrection(bedId)

  const [refreshInterval, setRefreshInterval] = useState<number>(fastRefreshRate)

  const store = useLiveDataStateStore()

  const cockpitDispatch = useCockpitDispatch()

  const comparableSignals = JSON.stringify(selectedSignals)

  // Set a higher refresh rate when the bed changes
  // and there is no previous delay
  useEffect(() => {
    if (currentXAxisOffset.currentDelay === delayUninitialized) {
      setRefreshInterval(fastRefreshRate)
    }
  }, [bedId, currentXAxisOffset])

  // Set a higher refresh rate when selected signals change
  useEffect(() => {
    setRefreshInterval(fastRefreshRate)
  }, [comparableSignals])

  // Reset higher refresh rate after 3 refreshes
  useEffect(() => {
    if (refreshInterval === fastRefreshRate) {
      const resetTimeout = setTimeout(() => {
        setRefreshInterval(defaultRefreshRate)
      }, 3 * fastRefreshRate)

      return () => {
        clearTimeout(resetTimeout)
      }
    }
  }, [bedId, comparableSignals, refreshInterval])

  useInterval(() => {
    let delays: number[] = [0]

    // Take 10 sample delays from each signal
    store.getState().valuesByStreamUrl.forEach((values) => {
      delays = [...delays, ...getAboutTenSamples<TimestampedValue>(values).map((value) => value.delay)]
    })

    cockpitDispatch(setXAxisOffsetCorrectionDelay(bedId, Math.max(...delays)))
  }, refreshInterval)

  return currentXAxisOffset
}
