import { useCockpitState } from '../../store/cockpit/useCockpitState'
import { BedEditMode } from './model'
import { Bed, BedId } from '../../beds/model'
import { SignalConfig, SignalId } from '../../signals/model'
import { getBedSettings } from '../../users/selectors'

export const useIsHovered = (bedId: BedId) =>
  useCockpitState<boolean>((s) => {
    const bedEditMode = s.ui.activeUser.settings.screen.overview.bedEditMode
    return (
      bedEditMode.type === 'read-only' &&
      bedEditMode.bedInteraction.type === 'hovered' &&
      bedEditMode.bedInteraction.bedId === bedId
    )
  })

export const useIsPinned = (bedId: BedId) =>
  useCockpitState<boolean>((s) => {
    const bedEditMode = s.ui.activeUser.settings.screen.overview.bedEditMode
    return (
      bedEditMode.type === 'read-only' &&
      bedEditMode.bedInteraction.type === 'pinned' &&
      bedEditMode.bedInteraction.bedId === bedId
    )
  })

export const useSelectedSignalIds = (bed: Bed): ReadonlyArray<SignalId> =>
  useCockpitState<ReadonlyArray<SignalId>>((s) => {
    const selectedSignals = getBedSettings(s.ui.activeUser.settings.bedSettings, bed.id).selectedSignals
    return selectedSignals === 'uninitialized' ? bed.signals.selectedSignals : selectedSignals
  })

export const useSelectedSignalConfigs = (bed: Bed): ReadonlyArray<SignalConfig> => {
  const selectedSignalIds = useSelectedSignalIds(bed)
  return selectedSignalIds.flatMap((id) => bed.signals.availableSignals.find((s) => s._id === id) ?? [])
}

export const useBedEditMode = () =>
  useCockpitState<BedEditMode>((s) => s.ui.activeUser.settings.screen.overview.bedEditMode)
