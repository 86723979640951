import * as React from 'react'
import { Switch, Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { VideoBlurMode } from '../model'
import VideoCameraIcon from '@material-ui/icons/Videocam'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr auto',
    alignItems: 'center'
  },
  icon: {
    margin: theme.spacing(1)
  }
}))

type Props = Readonly<{
  mode: VideoBlurMode
  onVideoBlurModeChange: (mode: VideoBlurMode) => void
}>

export const VideoBlurSwitch = ({ mode, onVideoBlurModeChange }: Props) => {
  const classes = useStyles()
  const checked = mode === 'sharp'
  const onChange = () => (checked ? onVideoBlurModeChange('blurred') : onVideoBlurModeChange('sharp'))
  return (
    <div className={classes.root}>
      <VideoCameraIcon className={classes.icon} />
      Blurred
      <Switch checked={checked} onChange={onChange} color={'default'} />
      Sharp
    </div>
  )
}
