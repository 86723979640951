import { UPDATE_VIDEO_BLUR_MODE } from './actionTypes'
import { VideoBlurMode } from './model'

export type VideoAction = UpdateVideoBlurModeAction

export interface UpdateVideoBlurModeAction {
  type: typeof UPDATE_VIDEO_BLUR_MODE
  mode: VideoBlurMode
}

export const updateVideoBlurMode = (mode: VideoBlurMode): UpdateVideoBlurModeAction => ({
  type: UPDATE_VIDEO_BLUR_MODE,
  mode
})
