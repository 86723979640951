import React from 'react'
import { VirusTile as VirusTileComponent } from '../../components/tiles/VirusTile'
import { Bed } from '../../model'

interface Props {
  readonly bed: Bed
}

export const VirusTile = ({ bed }: Props) => {
  const bedId = bed.id
  return <VirusTileComponent bedId={bedId} {...bed.infoTiles?.virus} />
}
