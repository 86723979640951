import { OverviewScreenAction } from './actions'
import { UPDATE_BED_EDIT_MODE } from './actionTypes'
import { OverviewScreenState } from './model'
import { defaultOverviewScreen } from './defaults'

export const overviewScreenReducer = (
  state: OverviewScreenState = defaultOverviewScreen,
  action: OverviewScreenAction
): OverviewScreenState => {
  switch (action.type) {
    case UPDATE_BED_EDIT_MODE:
      return {
        ...state,
        bedEditMode: action.bedEditMode,
      }
    default:
      return state
  }
}
