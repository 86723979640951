import React from 'react'
import { Datum, Point } from '@nivo/line'
import { TimelineDatum } from '../../model'
import { PointItem } from '../shared/PointItem'

export type DataPoint = Point & { data: Datum & TimelineDatum }

export interface TimelinePointProps {
  point: DataPoint
  size: number
  borderWidth: number
  onClick?: (point: DataPoint, e: React.MouseEvent<SVGCircleElement, MouseEvent>) => void
  onMouseEnter?: (point: DataPoint, e: React.MouseEvent<SVGCircleElement, MouseEvent>) => void
  onMouseLeave?: (point: DataPoint, e: React.MouseEvent<SVGCircleElement, MouseEvent>) => void
}

export const TimelinePoint = ({
  point,
  size,
  borderWidth,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: TimelinePointProps) => {
  return (
    <PointItem
      x={point.x}
      y={point.y}
      size={size}
      color={point.color}
      borderColor={point.borderColor}
      borderWidth={borderWidth}
      onClick={(e) => {
        onClick && onClick(point, e)
      }}
      onMouseEnter={(e) => {
        onMouseEnter && onMouseEnter(point, e)
      }}
      onMouseLeave={(e) => {
        onMouseLeave && onMouseLeave(point, e)
      }}
    />
  )
}
