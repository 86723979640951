import React from 'react'
import { Card, CardContent, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'grid',
    justifyItems: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  content: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr',
  },
  icon: {
    fontSize: 40,
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  text: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: theme.spacing(1),
    gridTemplateRows: 'auto auto',
  },
}))

export const CenterCardPlaceholder = () => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <EmojiObjectsIcon className={classes.icon} />
        <Typography className={classes.text} color="textSecondary" variant="body1" component={'div'}>
          <b>Mouse Hover:</b>
          <div>Place mouse over any bed to display video and live data streams</div>
          <b>Mouse Click:</b>
          <div>Pin any bed for permanent display and to access its settings</div>
        </Typography>
      </CardContent>
    </Card>
  )
}
