import {
  TimeRangeNone,
  TimelinesScreenState,
  TimelinesScreenStateValue,
  TimelinesSelectionValue,
  CursorPositionNone,
  TimelineConfigs,
  TimelineScreenId,
} from './model'

export const defaultTimelinesScreenTimelinesSelectionValue: TimelinesSelectionValue = {
  status: 'uninitialized',
  isLoading: false,
  error: null,
}

export const defaultTimelinesScreenStateValue: TimelinesScreenStateValue = {
  timeRange: TimeRangeNone,
  cursorPosition: CursorPositionNone,
  timelines: defaultTimelinesScreenTimelinesSelectionValue,
  timelineConfigs: new Map() as TimelineConfigs,
  animate: true,
}

export const defaultTimelinesScreenState = (): TimelinesScreenState =>
  new Map<TimelineScreenId, TimelinesScreenStateValue>()
