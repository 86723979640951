import DarkThemeIcon from '@material-ui/icons/Brightness2'
import LightThemeIcon from '@material-ui/icons/WbSunny'
import * as React from 'react'
import { Switch, Theme } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    alignItems: 'center'
  },
  icon: {
    margin: theme.spacing(1)
  }
}))

type Props = Readonly<{
  onToggleTheme: () => void
}>

export const ThemeSwitch = ({ onToggleTheme }: Props) => {
  const theme: Theme = useTheme()
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <LightThemeIcon className={classes.icon} />
      <Switch checked={theme.palette.type === 'dark'} onChange={onToggleTheme} color={'default'} />
      <DarkThemeIcon className={classes.icon} />
    </div>
  )
}
