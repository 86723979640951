import { VideoBlurMode } from './model'
import { VideoAction } from './actions'
import { UPDATE_VIDEO_BLUR_MODE } from './actionTypes'
import { defaultVideoBlurMode } from './defaults'

export const videoReducer = (state: VideoBlurMode = defaultVideoBlurMode, action: VideoAction): VideoBlurMode => {
  switch (action.type) {
    case UPDATE_VIDEO_BLUR_MODE:
      return action.mode
    default:
      return state
  }
}
