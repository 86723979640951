import { combineReducers } from 'redux'

import { ScreensState } from './model'
import { overviewScreenReducer } from './overview/reducer'
import { timelinesScreenReducer } from './timelines/reducer'

export const screensRootReducer = combineReducers<ScreensState>({
  overview: overviewScreenReducer,
  timelines: timelinesScreenReducer,
})
