import { useEffect } from 'react'
import { useCockpitDispatch } from '../../../store/cockpit/useCockpitDispatch'
import { DciDetailScreen as DciDetailScreenComponent } from '../components/DciDetailScreen'
import { useRoutedBed } from '../../selectors'
import { useDciData } from '../selectors'
import { fetchHeatmap, fetchTimeline, fetchWaterfall } from '../../../dci/actions'

export const DciDetailScreen = () => {
  const bed = useRoutedBed()
  const dciData = useDciData(bed.id)
  const cockpitDispatch = useCockpitDispatch()

  useEffect(() => {
    if (dciData.timeline.status === 'uninitialized' && !dciData.timeline.isLoading && !dciData.timeline.error) {
      cockpitDispatch(fetchTimeline(bed.id, Date.now() - 1000 * 60 * 60 * 24, Date.now()))
    }
    if (dciData.waterfall.status === 'uninitialized' && !dciData.waterfall.isLoading && !dciData.waterfall.error) {
      cockpitDispatch(fetchWaterfall(bed.id))
    }
    if (dciData.heatmap.status === 'uninitialized' && !dciData.heatmap.isLoading && !dciData.heatmap.error) {
      cockpitDispatch(fetchHeatmap(bed.id, Date.now() - 1000 * 60 * 60 * 24, Date.now()))
    }
  }, [dciData, bed, cockpitDispatch])

  return <DciDetailScreenComponent bed={bed} dciData={dciData} />
}
