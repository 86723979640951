import React from 'react'
import { OptionsSelectionDialog } from '../../shared/components/options/OptionsSelectionDialog'
import { OptionInfo } from '../../shared/components/options/model'
import { SignalId, SignalInfo } from '../../signals/model'

interface Props {
  readonly title: string
  readonly isOpen: boolean
  readonly availableSignals: ReadonlyArray<SignalInfo>
  readonly selectedSignals: ReadonlyArray<SignalId>
  readonly onCancel: () => void
  readonly onSignalSelectionChange: (signalIds: ReadonlyArray<SignalId>) => void
}

export const BedSignalConfigDialog = ({
  title,
  isOpen,
  availableSignals,
  selectedSignals,
  onCancel,
  onSignalSelectionChange,
}: Props) => {
  const availableOptions: ReadonlyArray<OptionInfo> = availableSignals.map((signal) => ({
    id: signal._id,
    label: signal.label,
  }))

  return (
    <OptionsSelectionDialog
      title={title}
      isOpen={isOpen}
      availableOptions={availableOptions}
      selectedOptions={selectedSignals}
      onOptionsSelectionChange={(optionsIds) => {
        onSignalSelectionChange(optionsIds as ReadonlyArray<SignalId>)
      }}
      onCancel={onCancel}
    />
  )
}
