import { CircularProgress, makeStyles, Theme, Typography } from '@material-ui/core'
import React from 'react'

export interface LoaderProps {
  size?: number
  label?: string
  color?: 'primary' | 'secondary' | 'inherit'
}

const useStyles = makeStyles((theme: Theme) => ({
  centered: {
    width: '100%',
    height: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
  withLabel: {
    alignSelf: 'flex-end',
  },
  label: {
    alignSelf: 'flex-start',
    paddingTop: theme.spacing(2),
  },
}))

export const Loader = ({ size = 40, label, color = 'inherit' }: LoaderProps) => {
  const classes = useStyles()

  return (
    <div className={classes.centered}>
      <CircularProgress className={label ? classes.withLabel : ''} size={size} color={color} />
      {label && (
        <Typography className={classes.label} align={'center'}>
          {label}
        </Typography>
      )}
    </div>
  )
}
