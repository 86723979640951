import { ThemeSwitch as ThemeSwitchComponent } from '../components/ThemeSwitch'
import { toggleTheme, ToggleThemeAction } from '../actions'
import { useCockpitDispatch } from '../../store/cockpit/useCockpitDispatch'
import * as React from 'react'

export const ThemeSwitch = () => {
  const dispatch = useCockpitDispatch<ToggleThemeAction>()
  const onToggleTheme = () => dispatch(toggleTheme())
  return <ThemeSwitchComponent onToggleTheme={onToggleTheme} />
}
