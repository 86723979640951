import React from 'react'
import { Grid, Paper, Theme } from '@material-ui/core'
import { DciTimelineChart } from './DciTimelineChart'
import { createStyles, makeStyles } from '@material-ui/styles'
import { DciWaterfallChart } from './DciWaterfallChart'
import { DciHeatmapChart } from './DciHeatmapChart'
import { Bed } from '../../../beds/model'
import { DciStateValue } from '../../../dci/model'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
      height: '100%',
    },
  })
)

interface Props {
  readonly bed: Bed
  readonly dciData: DciStateValue
}

export const DciDetailScreen = ({ dciData }: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="stretch" spacing={1}>
        <Grid item xs={5}>
          <Paper className={classes.paper}>
            <DciWaterfallChart data={dciData.waterfall} />
          </Paper>
        </Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <DciTimelineChart data={dciData.timeline} />
          </Paper>
        </Grid>
        <Grid item xs={5}></Grid>
        <Grid item xs={7}>
          <Paper className={classes.paper}>
            <DciHeatmapChart data={dciData.heatmap} />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}
