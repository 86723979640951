import { CenterCard as CenterCardComponent } from '../components/CenterCard'
import { useBedEditMode, useIsPinned } from '../selectors'
import { useActiveBed } from '../../selectors'
import React from 'react'
import { CenterCardPlaceholder } from '../components/CenterCardPlaceholder'
import { useCockpitDispatch } from '../../../store/cockpit/useCockpitDispatch'
import { useXAxisOffsetCorrection } from '../../../signals/selectors'
import { BedNone } from '../../../beds/model'
import {
  setXAxisOffsetCorrectionEnabled,
  updateContrastLayerOpacity,
  updateSignalsLayerVisibility,
  updateVideoLayerVisibility,
} from '../../../users/actions'
import { useBedSettings } from '../../../users/selectors'
import { delayUninitialized } from '../../../users/model'

export const CenterCard = () => {
  const bed = useActiveBed()
  const isBedEditInProgress = useBedEditMode().type !== 'read-only'
  const isPinned = useIsPinned(bed.id)
  const { showVideoLayer, showSignalsLayer, contrastLayerOpacity } = useBedSettings(bed.id)
  const xAxisOffsetCorrection = useXAxisOffsetCorrection(bed.id)

  const dispatch = useCockpitDispatch()

  const onSelectVideoLayer = (selected: boolean) => dispatch(updateVideoLayerVisibility(bed.id, selected))
  const onSelectSignalLayer = (selected: boolean) => dispatch(updateSignalsLayerVisibility(bed.id, selected))
  const onUpdateContrastLayerOpacity = (opacity: number) => dispatch(updateContrastLayerOpacity(bed.id, opacity))
  const onToggleDelayCorrection = (enabled: boolean) => dispatch(setXAxisOffsetCorrectionEnabled(bed.id, enabled))

  return isBedEditInProgress ? (
    <div />
  ) : bed === BedNone ? (
    <CenterCardPlaceholder />
  ) : (
    <CenterCardComponent
      bed={bed}
      isPinned={isPinned}
      showVideoLayer={showVideoLayer}
      showSignalsLayer={showSignalsLayer}
      contrastLayerOpacity={contrastLayerOpacity}
      delayCorrectionEnabled={xAxisOffsetCorrection?.enabled ?? false}
      delayCorrectionInitializing={
        xAxisOffsetCorrection?.enabled === true && xAxisOffsetCorrection.currentDelay === delayUninitialized
      }
      currentNetworkDelay={xAxisOffsetCorrection.currentDelay}
      onSelectVideoLayer={onSelectVideoLayer}
      onSelectSignalsLayer={onSelectSignalLayer}
      onContrastLayerOpacity={onUpdateContrastLayerOpacity}
      onToggleDelayCorrection={onToggleDelayCorrection}
    />
  )
}
