import React from 'react'
import { CustomLayerProps } from '@nivo/line'
import { ComputedTimelineDatum, TimelineArea } from './TimelineArea'

export interface RangeAreaLayerProps extends CustomLayerProps {}

export const RangeAreaLayer = ({ yScale, innerHeight, pointBorderColor, series, animate }: RangeAreaLayerProps) => {
  return series.map((serie) => (
    <TimelineArea
      key={serie.id}
      yScale={yScale}
      points={serie.data as ComputedTimelineDatum[]}
      innerHeight={innerHeight}
      pointBorderColor={pointBorderColor}
      color={serie.color}
      animate={animate}
    />
  ))
}
