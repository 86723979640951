import { CustomLayerProps } from '@nivo/line'
import { CustomSlicesLayer as CustomSlicesLayerComponent } from '../../../components/layers/custom-slices/CustomSlicesLayer'
import { TimelineScreenId } from '../../../../screens/timelines/model'
import { useTimelineCursorPosition } from '../../../../screens/timelines/selectors'

export interface CustomSlicesLayerProps extends CustomLayerProps {
  timelineScreenId: TimelineScreenId
}

// We are directly accessing the timeline cursor position from
// the store here, so that the Timeline Component itself does not have
// to re-render on cursor-position change (performance).
export const CustomSlicesLayer = ({ timelineScreenId, ...props }: CustomSlicesLayerProps) => {
  const cursorPosition = useTimelineCursorPosition(timelineScreenId)

  return <CustomSlicesLayerComponent {...props} cursorPosition={cursorPosition} />
}
