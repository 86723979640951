import { Beds } from './model'
import { BedsAction } from './actions'
import { UPDATE_BED_ISOLATION, UPDATE_BEDS } from './actionTypes'
import { defaultBeds } from './defaults'

export const bedsReducer = (beds: Beds = defaultBeds, action: BedsAction): Beds => {
  switch (action.type) {
    case UPDATE_BED_ISOLATION:
      if (beds.type === 'uninitialized-beds') {
        return beds
      } else {
        return {
          ...beds,
          allBeds: beds.allBeds.map(bed => ({
            ...bed,
            isolationState: action.bedId === bed.id ? action.isolation : bed.isolationState
          }))
        }
      }
    case UPDATE_BEDS:
      return action.beds
    default:
      return beds
  }
}
