import React from 'react'
import { Paper, Theme, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    padding: theme.spacing(1),
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto auto',
    gap: theme.spacing(1),
    alignItems: 'start',
    width: 'max-content',
  },
}))

export interface TimelinePointTooltipProps {
  xLabel: string
  yLabel: string
  minLabel?: string
  maxLabel?: string
}

export const TimelinePointTooltip = ({ xLabel, yLabel, minLabel, maxLabel }: TimelinePointTooltipProps) => {
  const classes = useStyles()

  return (
    <Paper className={classes.tooltip}>
      <Typography variant="caption">
        <strong>x:</strong> {xLabel}
      </Typography>
      <Typography variant="caption">
        <strong>y:</strong> {yLabel}
      </Typography>
      {minLabel && maxLabel && (
        <>
          <Typography variant="caption">
            <strong>Min:</strong> {minLabel}
          </Typography>
          <Typography variant="caption">
            <strong>Max:</strong> {maxLabel}
          </Typography>
        </>
      )}
    </Paper>
  )
}
