import {
  ERROR_HEATMAP,
  ERROR_TIMELINE,
  ERROR_WATERFALL,
  FETCH_HEATMAP,
  FETCH_TIMELINE,
  FETCH_WATERFALL,
  INIT_BED,
  RECEIVE_HEATMAP,
  RECEIVE_TIMELINE,
  RECEIVE_WATERFALL,
} from './actionTypes'
import { Dispatch } from 'redux'
import { DciHeatmap, DciTimeline, DciWaterfall } from './model'
import { BedId } from '../beds/model'
import { getCredentials } from '../shared/credentials'

export type DciAction =
  | InitDciBedAction
  | FetchTimelineAction
  | ReceiveTimelineAction
  | ErrorTimelineAction
  | FetchWaterfallAction
  | ReceiveWaterfallAction
  | ErrorWaterfallAction
  | FetchHeatmapAction
  | ReceiveHeatmapAction
  | ErrorHeatmapAction

interface InitDciBedAction {
  type: typeof INIT_BED
  payload: Readonly<{ bedId: BedId }>
}

export const initDciBed = (bedId: BedId): InitDciBedAction => ({
  type: INIT_BED,
  payload: {
    bedId,
  },
})

/* ·················································································································· */
/*  TIMELINE
/* ·················································································································· */

interface FetchTimelineAction {
  type: typeof FETCH_TIMELINE
  payload: Readonly<{ bedId: BedId }>
}

export const createFetchTimelineAction = (bedId: BedId): FetchTimelineAction => ({
  type: FETCH_TIMELINE,
  payload: {
    bedId,
  },
})

export interface ReceiveTimelineAction {
  type: typeof RECEIVE_TIMELINE
  payload: Readonly<{ bedId: BedId; timeline: DciTimeline }>
}

export const createReceiveTimelineAction = (bedId: BedId, timeline: DciTimeline): ReceiveTimelineAction => ({
  type: RECEIVE_TIMELINE,
  payload: {
    bedId,
    timeline,
  },
})

interface ErrorTimelineAction {
  type: typeof ERROR_TIMELINE
  payload: Readonly<{ bedId: BedId; error: string }>
}

export const createErrorTimelineAction = (bedId: BedId, error: string): ErrorTimelineAction => ({
  type: ERROR_TIMELINE,
  payload: {
    bedId,
    error,
  },
})

const restPath = process.env.REACT_APP_REST_PATH ?? ''

export const fetchTimeline = (bedId: BedId, from: number, to: number) => {
  return async (dispatch: Dispatch<FetchTimelineAction | ReceiveTimelineAction | ErrorTimelineAction>) => {
    dispatch(createFetchTimelineAction(bedId))

    const requestInit = getCredentials()

    return fetch(`${restPath}/dci/${bedId}/timeline?from=${from}&to=${to}`, requestInit)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Received invalid response')
        }
      })
      .then((fetchedTimeline: DciTimeline) => {
        // TODO: Some basic data checks
        dispatch(createReceiveTimelineAction(bedId, fetchedTimeline))
      })
      .catch((e) => {
        const errorMsg = 'Fetching timeline data failed'
        console.error(errorMsg, e)
        dispatch(createErrorTimelineAction(bedId, errorMsg))
      })
  }
}

/* ·················································································································· */
/*  WATERFALL
/* ·················································································································· */

// TODO: Less code duplication with timeline

interface FetchWaterfallAction {
  type: typeof FETCH_WATERFALL
  payload: Readonly<{ bedId: BedId }>
}

export const createFetchWaterfallAction = (bedId: BedId): FetchWaterfallAction => ({
  type: FETCH_WATERFALL,
  payload: {
    bedId,
  },
})

export interface ReceiveWaterfallAction {
  type: typeof RECEIVE_WATERFALL
  payload: Readonly<{ bedId: BedId; waterfall: DciWaterfall }>
}

export const createReceiveWaterfallAction = (bedId: BedId, waterfall: DciWaterfall): ReceiveWaterfallAction => ({
  type: RECEIVE_WATERFALL,
  payload: {
    bedId,
    waterfall,
  },
})

interface ErrorWaterfallAction {
  type: typeof ERROR_WATERFALL
  payload: Readonly<{ bedId: BedId; error: string }>
}

export const createErrorWaterfallAction = (bedId: BedId, error: string): ErrorWaterfallAction => ({
  type: ERROR_WATERFALL,
  payload: {
    bedId,
    error,
  },
})

export const fetchWaterfall = (bedId: BedId) => {
  return async (dispatch: Dispatch<FetchWaterfallAction | ReceiveWaterfallAction | ErrorWaterfallAction>) => {
    dispatch(createFetchWaterfallAction(bedId))

    const requestInit = getCredentials()

    return fetch(`${restPath}/dci/${bedId}/waterfall`, requestInit)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Received invalid response')
        }
      })
      .then((fetchedWaterfall: DciWaterfall) => {
        // TODO: Some basic data checks
        dispatch(createReceiveWaterfallAction(bedId, fetchedWaterfall))
      })
      .catch((e) => {
        const errorMsg = 'Fetching waterfall data failed'
        console.error(errorMsg, e)
        dispatch(createErrorWaterfallAction(bedId, errorMsg))
      })
  }
}

/* ·················································································································· */
/*  HEATMAP
/* ·················································································································· */

// TODO: Less code duplication with timeline

interface FetchHeatmapAction {
  type: typeof FETCH_HEATMAP
  payload: Readonly<{ bedId: BedId }>
}

export const createFetchHeatmapAction = (bedId: BedId): FetchHeatmapAction => ({
  type: FETCH_HEATMAP,
  payload: {
    bedId,
  },
})

export interface ReceiveHeatmapAction {
  type: typeof RECEIVE_HEATMAP
  payload: Readonly<{
    bedId: BedId
    heatmap: Readonly<{
      signals: ReadonlyArray<DciHeatmap>
    }>
  }>
}

export const createReceiveHeatmapAction = (
  bedId: BedId,
  heatmap: Readonly<{
    signals: ReadonlyArray<DciHeatmap>
  }>
): ReceiveHeatmapAction => ({
  type: RECEIVE_HEATMAP,
  payload: {
    bedId,
    heatmap,
  },
})

interface ErrorHeatmapAction {
  type: typeof ERROR_HEATMAP
  payload: Readonly<{ bedId: BedId; error: string }>
}

export const createErrorHeatmapAction = (bedId: BedId, error: string): ErrorHeatmapAction => ({
  type: ERROR_HEATMAP,
  payload: {
    bedId,
    error,
  },
})

export const fetchHeatmap = (bedId: BedId, from: number, to: number) => {
  return async (dispatch: Dispatch<FetchHeatmapAction | ReceiveHeatmapAction | ErrorHeatmapAction>) => {
    dispatch(createFetchHeatmapAction(bedId))

    const requestInit = getCredentials()

    return fetch(`${restPath}/dci/${bedId}/heatmap?from=${from}&to=${to}`, requestInit)
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Received invalid response')
        }
      })
      .then((fetchedHeatmap: ReadonlyArray<DciHeatmap>) => {
        // TODO: Some basic data checks
        dispatch(createReceiveHeatmapAction(bedId, { signals: fetchedHeatmap }))
      })
      .catch((e) => {
        const errorMsg = 'Fetching heatmap data failed'
        console.error(errorMsg, e)
        dispatch(createErrorHeatmapAction(bedId, errorMsg))
      })
  }
}
