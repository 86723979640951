import { TimelineScreenId } from '../screens/timelines/model'
import { TimelineAction } from './actions'
import { ERROR_TIMELINE, FETCH_TIMELINE, INIT_TIMELINE, RECEIVE_TIMELINE } from './actionTypes'
import { defaultTimelineState, defaultTimelineStatusUninitialized } from './defaults'
import { TimelineId, TimelineState, TimelineStateValue } from './model'

const getTimelineValue = (
  timelineState: Readonly<TimelineState>,
  timelineScreenId: TimelineScreenId,
  timelineId: TimelineId
): TimelineStateValue => {
  if (timelineState.get(timelineScreenId)?.has(timelineId)) {
    return timelineState.get(timelineScreenId)?.get(timelineId)!
  } else {
    throw new Error('Timeline was not initialized!')
  }
}

export const timelineRootReducer = (
  state: TimelineState = defaultTimelineState(),
  action: TimelineAction
): TimelineState => {
  switch (action.type) {
    case INIT_TIMELINE:
      return new Map(state).set(
        action.payload.timelineScreenId,
        new Map<TimelineId, TimelineStateValue>(
          state.has(action.payload.timelineScreenId) ? state.get(action.payload.timelineScreenId)! : []
        ).set(action.payload.timelineId, defaultTimelineStatusUninitialized)
      )
    case FETCH_TIMELINE: {
      const currentTimelineValue = getTimelineValue(state, action.payload.timelineScreenId, action.payload.timelineId)

      return new Map(state).set(
        action.payload.timelineScreenId,
        new Map<TimelineId, TimelineStateValue>(state.get(action.payload.timelineScreenId)!).set(
          action.payload.timelineId,
          {
            ...currentTimelineValue,
            isLoading: true,
            error: null,
          }
        )
      )
    }
    case RECEIVE_TIMELINE: {
      const currentTimelineValue = getTimelineValue(state, action.payload.timelineScreenId, action.payload.timelineId)

      return new Map(state).set(
        action.payload.timelineScreenId,
        new Map<TimelineId, TimelineStateValue>(state.get(action.payload.timelineScreenId)!).set(
          action.payload.timelineId,
          {
            ...currentTimelineValue,
            status: 'initialized',
            isLoading: false,
            error: null,
            ...action.payload.timeline,
          }
        )
      )
    }
    case ERROR_TIMELINE: {
      const currentTimelineValue = getTimelineValue(state, action.payload.timelineScreenId, action.payload.timelineId)

      return new Map(state).set(
        action.payload.timelineScreenId,
        new Map<TimelineId, TimelineStateValue>(state.get(action.payload.timelineScreenId)!).set(
          action.payload.timelineId,
          {
            ...currentTimelineValue,
            isLoading: false,
            error: action.payload.error,
          }
        )
      )
    }
    default:
      return state
  }
}
