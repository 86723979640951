import React from 'react'
import { BrainTile as BrainTileComponent } from '../../components/tiles/BrainTile'
import { Bed } from '../../model'

interface Props {
  readonly bed: Bed
}

export const BrainTile = ({ bed }: Props) => {
  return <BrainTileComponent bedId={bed.id} {...bed.infoTiles?.brain} />
}
