import { combineReducers } from 'redux'
import { bedsReducer } from '../../beds/reducer'
import { dciRootReducer } from '../../dci/reducer'
import { timelineRootReducer } from '../../timeline/reducer'
import { activeUserReducer } from '../../users/reducer'
import { CockpitState } from './model'

export const cockpitRootReducer = combineReducers<CockpitState>({
  entities: combineReducers({
    beds: bedsReducer,
  }),
  ui: combineReducers({
    activeUser: activeUserReducer,
  }),
  dci: dciRootReducer,
  timeline: timelineRootReducer,
})
