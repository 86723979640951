export const INIT_BED = 'dci/INIT_BED'

export const FETCH_TIMELINE = 'dci/FETCH_TIMELINE'
export const RECEIVE_TIMELINE = 'dci/RECEIVE_TIMELINE'
export const ERROR_TIMELINE = 'dci/ERROR_TIMELINE'

export const FETCH_WATERFALL = 'dci/FETCH_WATERFALL'
export const RECEIVE_WATERFALL = 'dci/RECEIVE_WATERFALL'
export const ERROR_WATERFALL = 'dci/ERROR_WATERFALL'

export const FETCH_HEATMAP = 'dci/FETCH_HEATMAP'
export const RECEIVE_HEATMAP = 'dci/RECEIVE_HEATMAP'
export const ERROR_HEATMAP = 'dci/ERROR_HEATMAP'

export const RESET_BED = 'dci/RESET_BED'
export const RESET_BEDS = 'dci/RESET_BEDS'
