import * as React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { BedId } from '../../beds/model'
import { cn } from '../../shared/utils'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
      display: 'grid',
      gridTemplateColumns: 'repeat(6, auto)',
      gridRowGap: '5px',
      gridColumnGap: '5px',
      width: 100,
      height: '50px',
      padding: 5,
      backgroundColor: theme.palette.grey.A100,
      borderRadius: 5,
    },
    bed: {
      pointerEvents: 'none',
      backgroundColor: theme.palette.grey.A200,
    },
    bedFocused: {
      backgroundColor: theme.palette.grey.A700,
    },
  })
)

type Props = Readonly<{
  bedIds: ReadonlyArray<BedId>
  focusBedId: BedId
  onClick: () => void
}>

export const BirdsEyeNavigation = ({ bedIds, focusBedId, onClick }: Props) => {
  const classes = useStyles()
  const classNames = (bedId: BedId) => (bedId === focusBedId ? [classes.bed, classes.bedFocused] : [classes.bed])

  return (
    <div className={classes.root} onClick={onClick}>
      {bedIds.map((bedId, index) => {
        const jumpAcrossCenter = { gridColumn: 6 }
        const jumpToExtraRow = { gridRow: 4 }
        const style = index === 7 ? jumpAcrossCenter : index > 7 ? jumpToExtraRow : {}
        return <div key={bedId} className={cn(...classNames(bedId))} style={style} />
      })}
    </div>
  )
}
