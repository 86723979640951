import { BedId } from '../../model'
import { Link } from 'react-router-dom'
import * as React from 'react'
import { Button, Theme, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { buttonBackgroundColorStyle } from './shared'
import { DetailScreenType } from '../../../screens/model'

export const BED_CARD_INFO_TILE_SIZE = 60
export const TOOLBAR_INFO_TILE_SIZE = 48

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    textDecoration: 'none',
  },
  button: ({ variant }: Props) => ({
    display: 'grid',
    placeItems: 'center',
    placeContent: 'center',
    ...buttonBackgroundColorStyle(theme),
    padding: variant === 'bed-card' ? theme.spacing(1) : theme.spacing(0.5),
    width: variant === 'bed-card' ? BED_CARD_INFO_TILE_SIZE : TOOLBAR_INFO_TILE_SIZE,
    height: variant === 'bed-card' ? BED_CARD_INFO_TILE_SIZE : TOOLBAR_INFO_TILE_SIZE,
    minWidth: 0,
    minHeight: 0,
  }),
  contents: {
    display: 'grid',
    width: '100%',
    height: '100%',
    placeContent: 'center',
  },
  label: {
    height: '100%',
  },
  container: {
    position: 'relative',
  },
  tooltip: {
    fontSize: 'large',
    maxWidth: 1000,
    backgroundColor: theme.palette.background.paper,
  },
}))

export type InfoTileVariant = 'bed-card' | 'toolbar'

export interface Props {
  readonly bedId: BedId
  readonly disabled?: boolean
  // either link to a detail screen...
  readonly detailScreen?: DetailScreenType
  // ... or trigger sth on click
  readonly onClick?: () => void
  readonly tooltip?: React.ReactNode
  readonly variant: InfoTileVariant
  readonly children: React.ReactNode
}

export const InfoTile = (props: Props) => {
  const classes = useStyles(props)
  const { bedId, disabled = false, detailScreen, tooltip, children, onClick } = props

  const contents = (
    <Tooltip title={tooltip ?? ''} placement={'bottom'} classes={{ tooltip: classes.tooltip }}>
      <div className={classes.container}>
        <Button className={classes.button} disabled={disabled} classes={{ label: classes.label }} onClick={onClick}>
          {!disabled && <div className={classes.contents}>{children}</div>}
        </Button>
      </div>
    </Tooltip>
  )
  return detailScreen && !disabled ? (
    <Link className={classes.link} to={`/bed/${bedId}/${detailScreen}`}>
      {contents}
    </Link>
  ) : (
    contents
  )
}
