import React, { useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import { useStyles } from './styles'
import { OptionId, OptionInfo } from './model'
import { AvailableOptionsList } from './AvailableOptionsList'
import { SelectedOptionsList } from './SelectedOptionsList'

export interface OptionsSelectionDialogProps {
  readonly title: string
  readonly isOpen: boolean
  readonly availableOptions: ReadonlyArray<OptionInfo>
  readonly selectedOptions: ReadonlyArray<string>
  readonly onOptionsSelectionChange: (optionIds: ReadonlyArray<string>) => void
  readonly onCancel: () => void
}

export const OptionsSelectionDialog = ({
  title,
  isOpen,
  availableOptions,
  selectedOptions,
  onOptionsSelectionChange,
  onCancel,
}: OptionsSelectionDialogProps) => {
  const classes = useStyles()
  const [selected, setSelected] = useState<ReadonlyArray<OptionId>>(selectedOptions)

  const onAdd = (optionId: OptionId) => () => setSelected((prev) => [...prev, optionId])
  const onRemove = (optionId: OptionId) => () => setSelected((prev) => prev.filter((id) => id !== optionId))
  const onDragAndDrop = (startIndex: number, endIndex: number) => {
    setSelected((prev) => {
      const result = Array.from(prev)
      const [removed] = result.splice(startIndex, 1)
      result.splice(endIndex, 0, removed)
      return result
    })
  }
  const onOkClick = () => onOptionsSelectionChange(selected)
  const onCancelClick = () => {
    setSelected(selectedOptions)
    onCancel()
  }

  const selectedOptionsSet = new Set(selected)
  const availableOptionsInfos = availableOptions.filter((o) => !selectedOptionsSet.has(o.id))
  const selectedOptionsInfos = selected.map((id) => availableOptions.find((o) => o.id === id)!)

  // prevent any clicks from bubbling up to bed cards
  const onClick = (e: React.MouseEvent) => e.stopPropagation()

  return (
    <Dialog open={isOpen} onClick={onClick} onClose={onCancel} fullWidth={true}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={classes.content}>
        <AvailableOptionsList options={availableOptionsInfos} onOptionClick={onAdd} />
        <SelectedOptionsList options={selectedOptionsInfos} onOptionClick={onRemove} onDragAndDrop={onDragAndDrop} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button onClick={onOkClick} color="primary" variant="contained">
          Done
        </Button>
      </DialogActions>
    </Dialog>
  )
}
