import React from 'react'
import { BedSelector as BedSelectorComponent } from '../components/BedSelector'
import { useCockpitState } from '../../store/cockpit/useCockpitState'
import { useNavigate } from 'react-router-dom'
import { BedId } from '../../beds/model'
import { useActiveDetailScreen, useRoutedBed } from '../../screens/selectors'

export const BedSelector = () => {
  const beds = useCockpitState((s) => s.entities.beds)
  const focusBedId = useRoutedBed().id

  const navigate = useNavigate()
  const detailScreen = useActiveDetailScreen()

  const onSelect = (bedId: BedId) => {
    navigate(`/bed/${bedId}/${detailScreen}`)
  }

  if (beds.type === 'uninitialized-beds') {
    return <div />
  } else {
    return <BedSelectorComponent beds={beds.allBeds} focusBedId={focusBedId} onSelect={onSelect} />
  }
}
