import React from 'react'
import { CustomLayerProps } from '@nivo/line'
import { useValueFormatter } from '@nivo/core'
import { scaleLinear } from 'd3-scale'
import { Domain, InteractionMode } from 'react-svg-timeline'
import { InteractionHandling, MouseCursor, zoomScaleWidth, MouseAwareSvg, SvgCoordinates } from 'react-svg-timeline'
import { useZoom } from './useZoom'
import { TimelineOnCursorMoveFn } from '../../Timeline'

export interface InteractionLayerProps extends CustomLayerProps {
  maxDomain?: Domain
  domain: Domain
  onZoom: ZoomFn
  onCursorMove: TimelineOnCursorMoveFn
  onInteractionModeChange: InteractionModeChangeFn
}

export type ZoomFn = (domain: Domain) => void

export type InteractionModeChangeFn = (interactionMode: InteractionMode) => void

export const InteractionLayer = ({
  innerHeight,
  innerWidth,
  onZoom,
  onCursorMove,
  onInteractionModeChange,
  domain,
  maxDomain,
  xFormat,
}: InteractionLayerProps) => {
  const [onZoomIn, onZoomOut, onZoomReset, isZoomInPossible, isZoomOutPossible, smallerZoomScale] = useZoom(
    onZoom,
    domain,
    maxDomain
  )

  const timeScale = scaleLinear().domain(domain).range([0, innerWidth])

  const zoomWidth = zoomScaleWidth(smallerZoomScale)

  const onZoomInCustom = (mouseStartX: number, mouseEndX: number) => {
    onZoom([timeScale.invert(mouseStartX), timeScale.invert(mouseEndX)])
  }

  const onPan = (pixelDelta: number) => {
    const [domainMin, domainMax] = domain
    const [rangeMin, rangeMax] = timeScale.range()
    const domainDelta = (pixelDelta / (rangeMax - rangeMin)) * (domainMax - domainMin)
    const [newDomainMin, newDomainMax] = [domainMin + domainDelta, domainMax + domainDelta]
    if (newDomainMax < Date.now()) {
      onZoom([newDomainMin, newDomainMax])
    }
  }

  const valueFormatter = useValueFormatter(xFormat)

  return (
    <MouseAwareSvg width={innerWidth} height={innerHeight}>
      {(mousePosition: SvgCoordinates) => {
        const timeAtCursor = timeScale.invert(mousePosition.x)

        return (
          <InteractionHandling
            width={innerWidth}
            height={innerHeight}
            mousePosition={mousePosition}
            isAnimationInProgress={false}
            isZoomInPossible={isZoomInPossible}
            isZoomOutPossible={isZoomOutPossible}
            isTrimming={false}
            onHover={onCursorMove}
            onZoomIn={() => {
              onZoomIn(timeAtCursor)
            }}
            onZoomOut={() => {
              onZoomOut(timeAtCursor)
            }}
            onZoomInCustom={onZoomInCustom}
            onZoomInCustomInProgress={() => {}}
            onZoomReset={onZoomReset}
            onPan={onPan}
            onTrimStart={() => {}}
            onTrimEnd={() => {}}
            onInteractionModeChange={onInteractionModeChange}
            onInteractionEnd={() => {}}
          >
            {/* TODO: Refactor react-svg-timeline Interaction Handling to contain the cursor */}
            {(cursor, interactionMode, setTrimHoverMode) => {
              return (
                <MouseCursor
                  mousePosition={mousePosition.x}
                  cursorLabel={valueFormatter(timeAtCursor)}
                  cursor={cursor}
                  interactionMode={interactionMode}
                  zoomRangeStart={timeScale(timeAtCursor - zoomWidth / 2)!}
                  zoomRangeEnd={timeScale(timeAtCursor + zoomWidth / 2)!}
                  zoomScale={smallerZoomScale}
                  isZoomInPossible={isZoomInPossible}
                />
              )
            }}
          </InteractionHandling>
        )
      }}
    </MouseAwareSvg>
  )
}
