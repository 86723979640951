import { Dispatch, Middleware } from 'redux'

import { initTimeline, TimelineAction } from './actions'
import { FETCH_TIMELINE } from './actionTypes'
import { CockpitState } from '../store/cockpit/model'

export const initTimelineMiddleware: Middleware<{}, CockpitState> =
  (store) => (next: Dispatch<TimelineAction>) => (action: TimelineAction) => {
    switch (action.type) {
      case FETCH_TIMELINE:
        const state = store.getState()

        if (
          !state.timeline.has(action.payload.timelineScreenId) ||
          !state.timeline.get(action.payload.timelineScreenId)?.has(action.payload.timelineId)
        ) {
          store.dispatch(initTimeline(action.payload.timelineScreenId, action.payload.timelineId))
        }
    }

    next(action)
  }
