import React, { useCallback, useEffect } from 'react'
import { TimelinesDetailScreen as TimelinesDetailScreenComponent } from '../components/TimelinesDetailScreen'
import { useRoutedBed } from '../../selectors'
import { useCockpitDispatch } from '../../../store/cockpit/useCockpitDispatch'
import {
  fetchTimelines,
  resetCursorPosition,
  resetTimeRange,
  setCursorPosition,
  setSelectedTimelines,
  setTimelinesAnimate,
  setTimeRange,
  TimelinesScreenAction,
} from '../actions'
import { Domain } from 'react-svg-timeline'
import { TimelineId } from '../../../timeline/model'
import { throttle } from 'lodash'
import { Loader } from '../../../shared/components/Loader'
import { Error } from '../../../shared/components/Error'
import { CursorPosition } from '../model'
import { BedIdNone } from '../../../beds/model'
import { useTimelineAnimate, useTimelineTimelines, useTimelineTimeRange } from '../selectors'

export const TimelinesDetailScreen = () => {
  const bed = useRoutedBed()
  const timelinesScreenTimelines = useTimelineTimelines(bed.id)
  const timeRange = useTimelineTimeRange(bed.id)
  const animate = useTimelineAnimate(bed.id)

  const dispatch = useCockpitDispatch<TimelinesScreenAction>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledDispatch = useCallback(
    throttle((arg: any) => dispatch(arg), 25, { trailing: false }),
    [dispatch]
  )

  useEffect(() => {
    if (bed.id && bed.id !== BedIdNone) {
      dispatch(fetchTimelines(bed.id, bed.id))
    }
  }, [bed.id, dispatch])

  const onAnimateChange = useCallback(
    (animate: boolean) => {
      dispatch(setTimelinesAnimate(bed.id, animate))
    },
    [bed.id, dispatch]
  )

  const onResetRange = useCallback(() => {
    dispatch(resetTimeRange(bed.id))
  }, [bed.id, dispatch])

  const onRangeChange = useCallback(
    (range: Domain) => {
      // Use throttled dispatch for when panning
      throttledDispatch(setTimeRange(bed.id, range))
    },
    [bed.id, throttledDispatch]
  )

  const onCursorPositionChange = useCallback(
    (cursorPos: number) => {
      // Use throttled dispatch for when panning
      throttledDispatch(setCursorPosition(bed.id, cursorPos as CursorPosition))
    },
    [bed.id, throttledDispatch]
  )

  const onResetCursorPosition = useCallback(() => {
    dispatch(resetCursorPosition(bed.id))
  }, [bed.id, dispatch])

  const onSelectedTimelinesChange = useCallback(
    (selectedTimelines: ReadonlyArray<TimelineId>) => {
      dispatch(setSelectedTimelines(bed.id, selectedTimelines))
    },
    [bed.id, dispatch]
  )

  if (timelinesScreenTimelines.status !== 'initialized') {
    return timelinesScreenTimelines.error ? (
      <Error size="large" label={timelinesScreenTimelines.error} />
    ) : (
      <Loader label="Loading Timelines" />
    )
  }

  return (
    <TimelinesDetailScreenComponent
      bedId={bed.id}
      timelineScreenId={bed.id}
      availableTimelines={timelinesScreenTimelines.availableTimelines}
      selectedTimelines={timelinesScreenTimelines.selectedTimelines}
      timeRange={timeRange}
      onRangeChange={onRangeChange}
      onResetRange={onResetRange}
      onCursorPositionChange={onCursorPositionChange}
      onResetCursorPosition={onResetCursorPosition}
      animate={animate}
      onAnimateChange={onAnimateChange}
      onSelectedTimelinesChange={onSelectedTimelinesChange}
    />
  )
}
