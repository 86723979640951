import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CenterCard } from '../containers/CenterCard'
import { Logo } from './Logo'
import { Loader } from '../../../shared/components/Loader'
import { Error } from '../../../shared/components/Error'
import { BedCard } from '../../../beds/containers/BedCard'
import { Beds } from '../../../beds/model'

const useStyles = makeStyles((theme) => ({
  full: {
    height: '100%',
    width: '100%',
  },
  bedGrid: {
    display: 'grid',
    height: '100%',
    width: '100%',
    gridTemplateColumns: 'repeat(6, 1fr)',
    gridTemplateRows: '15% 15% 1fr 15%',
    gridColumnGap: theme.spacing(2),
    gridRowGap: theme.spacing(2),
  },
  centerCard: {
    gridColumn: '2 / span 4',
    gridRow: '2 / span 2',
  },
  logo: {
    gridColumn: 6,
    gridRow: 4,
  },
}))

export interface Props {
  beds: Beds
}

export const OverviewScreen = ({ beds }: Props) => {
  const classes = useStyles()
  if (beds.type === 'uninitialized-beds') {
    return (
      <div className={classes.full}>
        {beds.initializationError ? (
          <Error size="large" label={`${beds.initializationError}; Details in Console.`} />
        ) : (
          <Loader label="Loading Beds" />
        )}
      </div>
    )
  } else {
    return (
      <div className={classes.bedGrid}>
        {beds.allBeds.map((bed) => {
          const style = {
            gridColumn: bed.layoutGridColumn,
            gridRow: bed.layoutGridRow,
          }
          return (
            <div key={`${bed.layoutGridColumn}/${bed.layoutGridRow}`} style={style}>
              <BedCard bed={bed} />
            </div>
          )
        })}
        <div className={classes.centerCard}>
          <CenterCard />
        </div>
        <div className={classes.logo}>
          <Logo />
        </div>
      </div>
    )
  }
}
