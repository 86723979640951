import React from 'react'
import { MuiThemeProvider, Theme } from '@material-ui/core'
import { Route, Routes } from 'react-router-dom'

import { ActiveView, CockpitNavigation } from './CockpitNavigation'
import { DciDetailScreen } from '../../screens/dci/containers/DciDetailScreen'
import { OverviewScreen } from '../../screens/overview/containers/OverviewScreen'
import { TimelinesDetailScreen } from '../../screens/timelines/containers/TimelinesDetailScreen'
import { GenericDetailScreen } from '../../screens/detail/containers/GenericDetailScreen'

interface Props {
  readonly theme: Theme
  readonly activeView: ActiveView
  readonly containsSharpVideoUrl: boolean
}

export const App = ({ theme, activeView, containsSharpVideoUrl }: Props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CockpitNavigation activeView={activeView} containsSharpVideoUrl={containsSharpVideoUrl}>
        <Routes>
          <Route path="/bed/:bedNumber/dci" element={<DciDetailScreen />} />
          <Route path="/bed/:bedNumber/timelines" element={<TimelinesDetailScreen />} />
          <Route path="/bed/:bedNumber/detail/:tile" element={<GenericDetailScreen />} />
          <Route path="*" element={<OverviewScreen />} />
        </Routes>
      </CockpitNavigation>
    </MuiThemeProvider>
  )
}
