// Proportion-based RGBA color specification, i.e. 4 values [0,1]
export type RGBA = [number, number, number, number]

// Standard RGBA color specification, i.e. 3 values [0,255] + 1 value [0,1]
export type STD_RGBA = [number, number, number, number]

// Converts the RGB portion of the given color to a web-suitable string with values in range [0,255]
export const toFillColor = (rgba: RGBA) => `rgb(${rgba[0] * 255},${rgba[1] * 255},${rgba[2] * 255})`

// Converts the RGB portion to a web-suitable string with the given alpha (ignoring the original A portion)
export const toBackgroundColor = (rgba: RGBA, alpha: number) =>
  `rgba(${rgba[0] * 255},${rgba[1] * 255},${rgba[2] * 255},${alpha})`

/**
 * Convenience method to join multiple class names.
 *
 * Example Usage:
 *
 * `cn('foo', 'bar', 'baz')`
 * `cn(...['foo', 'bar', 'baz'])`
 *
 */
export const cn = (...classNames: string[]) => classNames.join(' ')

/**
 * Ensure value is within the min/max-Range, return min or max otherwise
 */
export const clamp = (value: number, min: number, max: number) => Math.min(max, Math.max(min, value))

export const diff = (value1: number, value2: number): number => {
  return Math.abs(Math.max(value1, value2) - Math.min(value1, value2))
}
