import { useCockpitState } from '../../store/cockpit/useCockpitState'
import { LiveVideoMissing } from '../components/LiveVideoMissing'
import { LiveVideoStream as LiveVideoStreamComponent } from '../components/LiveVideoStream'
import * as React from 'react'
import { VideoBlurMode } from '../model'
import { Bed } from '../../beds/model'

interface Props {
  readonly bed: Bed
}

export const LiveVideoStream = ({ bed }: Props) => {
  const videoBlurMode = useCockpitState<VideoBlurMode>((s) => s.ui.activeUser.settings.videoBlurMode)
  const videoUrl = videoBlurMode === 'blurred' ? bed.camUrl : bed.camUrlSharp ? bed.camUrlSharp : bed.camUrl
  if (videoUrl && videoUrl.trim().length !== 0) {
    return <LiveVideoStreamComponent source={videoUrl} />
  } else {
    return <LiveVideoMissing />
  }
}
