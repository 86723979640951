import React, { useCallback } from 'react'
import { GenericDetailScreen as GenericDetailScreenComponent } from '../components/GenericDetailScreen'
import { useRoutedBed } from '../../selectors'
import { useCockpitDispatch } from '../../../store/cockpit/useCockpitDispatch'
import {
  fetchTimelines,
  resetCursorPosition,
  resetTimeRange,
  setCursorPosition,
  setSelectedTimelines,
  setTimelinesAnimate,
  setTimeRange,
  TimelinesScreenAction,
} from '../../timelines/actions'
import { Domain } from 'react-svg-timeline'
import { TimelineId, TimelineIdNone } from '../../../timeline/model'
import { useEffect } from 'react'
import { throttle } from 'lodash'
import { Loader } from '../../../shared/components/Loader'
import { Error } from '../../../shared/components/Error'
import { CursorPosition, Timeline } from '../../timelines/model'

import { useParams } from 'react-router-dom'
import { BedIdNone, CurrentProbabilityInfo } from '../../../beds/model'
import { useTimelineAnimate, useTimelineTimelines, useTimelineTimeRange } from '../../timelines/selectors'

export const GenericDetailScreen = () => {
  const bed = useRoutedBed()
  const { tile } = useParams()

  const detailScreenId = `${bed.id}-${tile}`

  const timelinesScreenTimelines = useTimelineTimelines(detailScreenId)
  const timeRange = useTimelineTimeRange(detailScreenId)
  const animate = useTimelineAnimate(detailScreenId)

  const dispatch = useCockpitDispatch<TimelinesScreenAction>()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledDispatch = useCallback(
    throttle((arg: any) => dispatch(arg), 25, { trailing: false }),
    [dispatch]
  )

  useEffect(() => {
    if (bed.id && bed.id !== BedIdNone) {
      dispatch(fetchTimelines(bed.id, detailScreenId))
    }
  }, [bed.id, detailScreenId, dispatch])

  const onAnimateChange = useCallback(
    (animate: boolean) => {
      dispatch(setTimelinesAnimate(detailScreenId, animate))
    },
    [detailScreenId, dispatch]
  )

  const onResetRange = useCallback(() => {
    dispatch(resetTimeRange(detailScreenId))
  }, [detailScreenId, dispatch])

  const onRangeChange = useCallback(
    (range: Domain) => {
      // Use throttled dispatch for when panning
      throttledDispatch(setTimeRange(detailScreenId, range))
    },
    [detailScreenId, throttledDispatch]
  )

  const onCursorPositionChange = useCallback(
    (cursorPos: number) => {
      // Use throttled dispatch for when panning
      throttledDispatch(setCursorPosition(detailScreenId, cursorPos as CursorPosition))
    },
    [detailScreenId, throttledDispatch]
  )

  const onResetCursorPosition = useCallback(() => {
    dispatch(resetCursorPosition(detailScreenId))
  }, [detailScreenId, dispatch])

  const onSelectedTimelinesChange = useCallback(
    (selectedTimelines: ReadonlyArray<TimelineId>) => {
      dispatch(setSelectedTimelines(detailScreenId, selectedTimelines))
    },
    [detailScreenId, dispatch]
  )

  if (timelinesScreenTimelines.status !== 'initialized') {
    return timelinesScreenTimelines.error ? (
      <Error size="large" label={timelinesScreenTimelines.error} />
    ) : (
      <Loader label="Loading Timelines" />
    )
  }

  // Temporary hard coding of the tile detail screen timelines
  let predictionLabel: string = 'Prediction'
  let predictionTimelineId: TimelineId = TimelineIdNone
  let currentProbabilityInfo: CurrentProbabilityInfo | undefined

  console.log(timelinesScreenTimelines.availableTimelines)

  switch (tile) {
    case 'dci':
      predictionLabel = 'DCI Prediction'
      predictionTimelineId = getPredictionTimelineId('DCI Prediction', timelinesScreenTimelines.availableTimelines)
      if (bed.infoTiles?.brain) {
        currentProbabilityInfo = {
          ...bed.infoTiles?.brain,
          type: 'brain',
        }
      }
      break
    case 'covid-19-infection':
      predictionLabel = 'COVID-19 Infection Prediction'
      predictionTimelineId = getPredictionTimelineId(
        'COVID-19 Infection Prediction',
        timelinesScreenTimelines.availableTimelines
      )
      if (bed.infoTiles?.virus) {
        currentProbabilityInfo = {
          ...bed.infoTiles?.virus,
          type: 'virus',
        }
      }
      break
    case 'covid-19-worsening':
      predictionLabel = 'COVID-19 Worsening Prediction'
      predictionTimelineId = getPredictionTimelineId(
        'COVID-19 Worsening Prediction',
        timelinesScreenTimelines.availableTimelines
      )
      if (bed.infoTiles?.lung) {
        currentProbabilityInfo = {
          ...bed.infoTiles?.lung,
          type: 'lung',
        }
      }
      break
  }

  return (
    <GenericDetailScreenComponent
      bedId={bed.id}
      detailScreenId={detailScreenId}
      availableTimelines={timelinesScreenTimelines.availableTimelines}
      selectedTimelines={timelinesScreenTimelines.selectedTimelines}
      predictionLabel={predictionLabel}
      predictionTimeline={predictionTimelineId}
      currentProbabilityInfo={currentProbabilityInfo}
      timeRange={timeRange}
      onRangeChange={onRangeChange}
      onResetRange={onResetRange}
      onCursorPositionChange={onCursorPositionChange}
      onResetCursorPosition={onResetCursorPosition}
      animate={animate}
      onAnimateChange={onAnimateChange}
      onSelectedTimelinesChange={onSelectedTimelinesChange}
    />
  )
}

const getPredictionTimelineId = (label: string, availableTimelines: ReadonlyArray<Timeline>): TimelineId => {
  const timeline = availableTimelines.find((t) => t.label === label)
  return timeline ? timeline.id : TimelineIdNone
}
